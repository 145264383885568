import React, { CSSProperties, useState } from 'react';
import { Popover, ArrowContainer, PositionPopover } from 'react-tiny-popover';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

interface HintPopoverComponentProps {
    onClickOutside?: (e: MouseEvent) => void;
    position?: PositionPopover;
    isOpen?: boolean;
    hintText?: any;
    onClick?: React.MouseEventHandler<HTMLImageElement>;
    maxWidth?: number;
    icon?: string;
    iconStyle?: React.CSSProperties;
    popoverColor?: string;
    popoverArrowColor?: string;
}

const IMAGE_LEFT_MARGIN = 5;
const POPOVER_ZINDEX = 3;
const POPOVER_PADDING = 2;

const popoverContainerStyle: any = {
    filter: Styles.POPOVER_DROP_SHADOW,
    zIndex: POPOVER_ZINDEX,
};

const popoverStyle = (maxWidth, popoverColor): CSSProperties => ({
    ...Styles.Popout.POPOUT_BODY_STYLE,
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    backgroundColor: popoverColor || Color.TEXT_LIGHT_BLUE,
    fontSize: Size.FontSize.Medium,
    color: Color.WHITE,
    maxWidth,
});

const imageStyle = (iconStyle: CSSProperties): CSSProperties => ({
    marginLeft: IMAGE_LEFT_MARGIN,
    cursor: 'pointer',
    ...iconStyle,
});

export const HintPopoverComponent = (
    popoverProps: HintPopoverComponentProps
) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        hintText,
        popoverArrowColor,
        popoverColor,
        maxWidth,
        iconStyle,
        icon,
    } = popoverProps;

    if (!hintText) {
        return null;
    }

    return (
        <Popover
            isOpen={isOpen}
            padding={POPOVER_PADDING}
            containerStyle={popoverContainerStyle}
            content={(props) => (
                <ArrowContainer
                    {...props}
                    arrowSize={8}
                    arrowColor={popoverArrowColor || Color.TEXT_LIGHT_BLUE}
                >
                    <div style={popoverStyle(maxWidth, popoverColor)}>
                        {hintText}
                    </div>
                </ArrowContainer>
            )}
            onClickOutside={(e) => {
                if (isOpen) {
                    setIsOpen(false);
                }
                e.stopPropagation();
            }}
            {...popoverProps}
        >
            <img
                style={imageStyle(iconStyle)}
                src={icon || AssetsConstant.INFO_ICON}
                onClick={(e) => {
                    setIsOpen(!isOpen);
                    e.stopPropagation();
                }}
            />
        </Popover>
    );
};
