﻿import React, {CSSProperties} from "react";
import {Size} from "@vivli/shared/theme";
import {MetricsConstants} from "@vivli/features/dashboard/infrastructure/constants";

const barStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
    fontSize: Size.FontSize.Large - 2,
    fill: MetricsConstants.Colors.DARK_BLUE_TEXT,
}
const statusTickStyle: CSSProperties = {
    fill: MetricsConstants.Colors.DARK_BLUE_TEXT,
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.SemiBold,
}
const numericTickStyle: CSSProperties = {
    fill: MetricsConstants.Colors.LIGHT_GRAY_TEXT,
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.Regular,
}

export const BarComponent = props => {
    return (<g transform={`translate(${props.x},${props.y})`}>
            <rect
                width={props.width}
                height={props.height}
                fill={props.color}
            />
            <text
                x={props.width + 4}
                y={props.height / 2}
                textAnchor='start'
                dominantBaseline='central'
                fill={props.borderColor}
                style={barStyle}
            >
                {props.data.value}
            </text>
        </g>
    );
};

export const StatusTick = tick => (
    <g transform={`translate(${tick.x - 10},${tick.y})`}>
        <text
            textAnchor='end'
            dominantBaseline='middle'
            style={statusTickStyle}
        >
            {tick.value}
        </text>
    </g>
);

export const NumericTick = tick => (
    <g transform={`translate(${tick.x},${tick.y - 10})`}>
        <text
            textAnchor='start'
            dominantBaseline='middle'
            style={numericTickStyle}
        >
            {tick.value}
        </text>
    </g>
);
