import React, {createContext, useContext} from "react";
import {IAzureFiltersSearchService, IAzureSearchService} from "@vivli/features/search/infrastructure/interface";

export const AzureSearchContext = createContext<{
    studiesSearch: IAzureSearchService,
    vocabSearch: IAzureSearchService,
    filtersSearch: IAzureFiltersSearchService
}>(null);

export const useAzureSearchService = () => useContext(AzureSearchContext);


