﻿import {GridComponent} from "@vivli/shared/components";
import React from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {ColDef} from "ag-grid-community";
import {GridCellRendererEnum} from "@vivli/shared/infrastructure/enum";


interface AssociatedDataRequestsGridComponentProps {
    userDataRequests?: IDataRequest[]
    isVivliAdmin?: boolean
}

export const AssociatedDataRequestsGridComponent = (
    {
        userDataRequests,
        isVivliAdmin
    }: AssociatedDataRequestsGridComponentProps
) => {


    const columnDefs: ColDef[] = [
        {
            field: 'requestTitle',
            headerName: 'Request Title',
            sortable: true,
            filter: false,
            tooltipField: 'requestTitle',
        },
        {
            field: 'dataRequestDoi',
            headerName: 'Request Id',
            sortable: false,
            filter: false,
        },
        {
            field: 'stage',
            headerName: 'Stage',
            sortable: false,
            filter: false,
        },
        {
            field: 'envType',
            headerName: 'System',
            sortable: false,
            filter: false,
            hide: !isVivliAdmin,
            showRowGroup: false,
        },
        {
            field: 'userRoles',
            headerName: 'Role',
            sortable: false,
            filter: false,
            cellStyle: {textAlign: "left"},
            cellRenderer: GridCellRendererEnum.GridCellList,
        }
    ]


    const handleRowClick = (data) => {
        let urlToOpen = `/admindatarequests/${data.status}/${data.id}/StatusUpdate`

        if (!isVivliAdmin) {
            urlToOpen = `/admindatarequests/${data.status}/${data.id}/RequestDetailsPrintView`
        }
        window.open(urlToOpen);
    }


    return (
        <GridComponent
            rowData={userDataRequests}
            columns={columnDefs}
            fullWidthColumns={true}
            onRowClick={handleRowClick}
            emptyMessage='No Associated Data Requests'
            autoHeight={true}
            hideFloatingFilter={true}
        />
    )
}
