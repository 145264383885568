import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import axios from 'axios-observable';
import { IRestApiOptions, IRestRequestOptions } from '@vivli/core/infrastructure/interface';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IConfig } from '@vivli/shared/infrastructure/interface';

const startTimeHeader = 'request-startTime';

export class RestApi {
    protected readonly _axiosInstance: axios;

    constructor(protected options: IRestApiOptions, public config?: IConfig) {
        const { baseUrl, retryCalls, retryDelay, headers, trackApiCall } = options;
        this._axiosInstance = axios.create({ baseURL: baseUrl, headers });
        this._axiosInstance.interceptors.request.use((config) => {
            config.headers[startTimeHeader] = new Date().getTime();
            return config;
        });
        this._axiosInstance.interceptors.response.use((response) => {
            const requestTime = Math.abs(new Date().getTime() - response.config.headers[startTimeHeader]);

            // added tsignore in order to check the window object to verify if this is a cypress call or not
            // do not try to call trackApiCall if cypress - JM 7-25-2022
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (trackApiCall && !window.Cypress) {
                trackApiCall(requestTime);
            }
            return response;
        });
    }

    private buildRequest<T>({
        url,
        method,
        data,
        options,
        authToken,
    }: {
        url: string;
        method: Method;
        data?: any;
        options?: IRestRequestOptions;
        authToken: string;
    }): Observable<T> {
        //isJsonInput is true if the request data is suppose to be in json format,
        //false - when the data is FormData or any other type of data - not json.
        const isJsonInput =
            ( options?.additionalHeaders &&
                (!Object.keys(options.additionalHeaders).some((key) => key === 'Content-Type') ||
                    options.additionalHeaders['Content-Type'].toString().toLower().indexOf('json') >= 0))??true;
        let requestConfig: AxiosRequestConfig = {
            url,
            method,
            headers: options?.additionalHeaders,
            data: isJsonInput ? JSON.stringify(data) : data,
        };

        if (options?.baseUrl) {
            requestConfig = {
                ...requestConfig,
                baseURL: options.baseUrl,
            };
        }

        if (options?.responseType) {
            requestConfig = {
                ...requestConfig,
                responseType: options.responseType,
            };
        }

        if (!options?.bypassAuth) {
            requestConfig = {
                ...requestConfig,
                headers: {
                    ...requestConfig.headers,
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json; charset=utf-8',
                },
            };
        }

        if (options?.params) {
            requestConfig = {
                ...requestConfig,
                params: options.params,
            };
        }

        return this._axiosInstance.request<T>(requestConfig).pipe(
            map((resp) => resp.data),
            catchError((err: AxiosError) => {
                if (err?.response?.data) {
                    return throwError(err.response.request?.responseText);
                } else if (err?.message) {
                    return throwError(err.message);
                } else {
                    return throwError('Error occurred making call ' + err?.response?.request?.currentRequest?.toString());
                }
            })
        );
    }

    private handleAxiosCall<T>({
        url,
        method,
        data,
        options,
    }: {
        url: string;
        method: Method;
        data?: any;
        options?: IRestRequestOptions;
    }): Observable<T> {
        // if (options.bypassAuth)
        let authTokenCall: () => Promise<string>;

        if (!options?.token) {
            if (options?.bypassAuth || !this.options.refreshAuthToken) {
                authTokenCall = () => Promise.resolve<string>(null);
            } else {
                authTokenCall = this.options.refreshAuthToken;
            }
        } else {
            authTokenCall = () => Promise.resolve<string>(options?.token);
        }

        const authObs = from(authTokenCall());

        return authObs.pipe(
            mergeMap((authToken) =>
                this.buildRequest<T>({
                    url,
                    data,
                    authToken,
                    method,
                    options,
                })
            )
        );
    }

    protected handleGet<T>(url: string, options?: IRestRequestOptions): Observable<T> {
        return this.handleAxiosCall<T>({
            url,
            method: 'get',
            options,
        });
    }

    protected handlePost<T>(url: string, data?: any, options?: IRestRequestOptions): Observable<T> {
        return this.handleAxiosCall<T>({
            url,
            method: 'post',
            data,
            options,
        });
    }

    protected handlePut<T>(url: string, data?: any, options?: IRestRequestOptions): Observable<T> {
        return this.handleAxiosCall<T>({
            url,
            method: 'put',
            data,
            options,
        });
    }

    protected handlePatch<T>(url: string, data?: any, options?: IRestRequestOptions): Observable<T> {
        return this.handleAxiosCall<T>({
            url,
            method: 'patch',
            data,
            options,
        });
    }

    protected handleDelete<T>(url: string, options?: IRestRequestOptions): Observable<T> {
        return this.handleAxiosCall<T>({
            url,
            method: 'delete',
            options,
        });
    }
}
