import { IIntercepts } from './intercepts.interface';
import { vivliConfigIntercept } from './data/vivli-config/vivli-config.intercept';
import { IVivliConfiguration } from '@vivli/shared/infrastructure/interface';

function parseObj<T>(objToParse: unknown) {
    return JSON.parse(JSON.stringify(objToParse)) as T;
}

export const intercepts: IIntercepts = {
    vivliConfig: parseObj<IVivliConfiguration>(vivliConfigIntercept),
};
