import React, {CSSProperties} from 'react';
import {Size} from "@vivli/shared/theme";
import {ReportSafetyConcernListComponent} from "./report-safety-concern-list.component";
import {ReportSafetyConcernFormComponent} from "./report-safety-concern-form.component";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {SafetyConcernsContextWrapper} from "@vivli/features/data-requests/infrastructure/wrappers";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: Size.INSET_SIZE,
    height: '100%',
    width: '100%',
}

export const ReportSafetyConcernComponent = () => {
    const {dataRequest} = useDataRequestContext();

    return (
        <div style={containerStyle}>
            <SafetyConcernsContextWrapper dataRequestId={dataRequest.id}>
                <ReportSafetyConcernFormComponent />
                <ReportSafetyConcernListComponent />
            </SafetyConcernsContextWrapper>
        </div>
    );
}
