import React, {CSSProperties} from 'react';

export const SearchFiltersContainerComponent = ({children, fullHeight = true}) => {
    const containerStyle: CSSProperties = {
        display: 'flex',
        borderRadius: '10px',
        overflow: 'hidden',
        flexDirection: 'column',
        width: '100%',
        height: fullHeight ? '100%' : 'initial',
        alignSelf: 'start'
    }

    return <div style={containerStyle}>{children}</div>
}
