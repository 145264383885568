import Tippy from "@tippyjs/react";
import {Placement} from 'tippy.js';
import React, {CSSProperties, ReactNode, useState} from "react";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";

import './dropdown-menu.styles.scss';
import 'tippy.js/animations/shift-away-extreme.css';
import {DropdownMenuContentComponent} from "./dropdown-menu-content.component";

const clickAreaStyle: CSSProperties = {
    width: '100%',
    height: '200%',
    top: '-100%',
    marginTop: '25px',
    cursor: 'pointer',
    position: 'absolute'
}

const clickAreaContainerStyle: CSSProperties = {
    position: 'relative',
    minHeight: '23px',
    width: '100%'
}

interface DropdownMenuComponentProps {
    items: IDropdownMenuItem[];
    children?: ReactNode;
    offset?: [number, number];
    onChange?: (item: IDropdownMenuItem) => void;
    placement?: Placement;
    readonly?: boolean;
    onBlur?: (e) => void;
    interactive?: boolean;
    sort?: 'asc' | 'desc'
    dataId?: string;
    name?: string;
}

export const    DropdownMenuComponent = (
    {
        items,
        offset,
        children,
        onChange,
        placement,
        readonly,
        onBlur,
        interactive = true,
        sort,
        dataId,
        name
    }: DropdownMenuComponentProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleItemClick = (item: IDropdownMenuItem) => {
        onChange && onChange(item);
        setIsOpen(false);
    }

    const handleMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);

        return false;
    }

    return (
        <Tippy
            content={<DropdownMenuContentComponent items={items} onItemClick={handleItemClick} sort={sort} />}
            placement={placement || 'bottom-end'}
            visible={isOpen}
            arrow={false}
            className='tippy-dropdown-menu'
            offset={offset || [10, 0]}
            onClickOutside={(i, e) => {
                setIsOpen(false);
                onBlur && onBlur(e);
            }}
            animation='shift-away-extreme'
            popperOptions={{
                modifiers: [
                    {
                        name: "sameWidth",
                        enabled: true,
                        fn: ({state}) => {
                            state.styles.popper.width = `${state.rects.reference.width}px`;
                        },
                        phase: "beforeWrite",
                        requires: ["computeStyles"],
                        effect: ({state}) => {
                            state.elements.popper.style.width = `${state.elements.reference.getBoundingClientRect().width}px`;
                        }
                    }
                ]
            }}
            disabled={readonly}
            interactive={interactive}
        >
            <div style={clickAreaContainerStyle}>
                <div style={clickAreaStyle} onClick={handleMenuClick} data-test-id={dataId || name}>
                    <div className='scrolly'>
                        {children}
                    </div>
                </div>
            </div>
        </Tippy>
    )
}
