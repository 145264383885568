import React, { useEffect, useState } from 'react';
import { TabButtonComponent, TabComponentProps } from './tab-button.component';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

interface TabButtonContainerComponentProps extends TabComponentProps {
    countSubject?: BehaviorSubject<number>;
    disabled?: boolean;
}

export const TabButtonContainerComponent = ({ count, countSubject, ...props }: TabButtonContainerComponentProps) => {
    const [currentCount, setCurrentCount] = useState(count);

    useEffect(() => {
        const countObs = countSubject?.pipe(filter((value) => value !== undefined)).subscribe((nextCount) => {
            setCurrentCount(nextCount);
        });

        return () => {
            countObs?.unsubscribe();
        };
    }, [countSubject]);

    useEffect(() => {
        setCurrentCount(count);
    }, [count]);

    return <TabButtonComponent {...props} count={currentCount} />;
};
