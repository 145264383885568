export const allowedFileUploadExtensionsIntercept = [
    '.doc',
    '.xls',
    '.csv',
    '.txt',
    '.pdf',
    '.rar',
    '.zip',
    '.7z',
    '.cab',
    '.tar',
    '.gz',
    '.bz2',
    '.zipx',
    '.docx',
    '.docm',
    '.dotx',
    '.dotm',
    '.xlsx',
    '.xlsm',
    '.xltx',
    '.xltm',
    '.xlsb',
    '.xlam',
    '.pptx',
    '.pptm',
    '.potx',
    '.potm',
    '.ppam',
    '.ppsx',
    '.ppsm',
    '.sldx',
    '.sldm',
    '.thmx',
    '.vsdx',
    '.vsdm',
    '.vssx',
    '.vssm',
    '.vstx',
    '.vstm',
    '.jpg',
    '.jpeg',
    '.gif',
    '.png',
    '.tif',
    '.tiff',
    '.sasbat',
    '.sas7bdat',
    '.r',
    '.py',
    '.pyw',
    '.sas',
    '.xpt',
    '.dta',
    '.sav',
    '.rtf',
    '.xml',
    '.eps',
    '.rmd',
    '.rds',
    '.rdata',
    '.pkl',
];
