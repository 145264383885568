﻿import React, { CSSProperties } from 'react';
import { DocumentsDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { useTranslation } from 'react-i18next';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { Size } from '@vivli/shared/theme';
import { StudyExternalLinksComponent } from '@vivli/shared/components';
import { useExternalLinkPermissionsHook } from '@vivli/features/studies/infrastructure/hook';

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: '1fr 50%',
    height: '100%',
    rowGap: '15px',
};

export const AdminStudyDatasetAttachedDocumentsComponent = () => {
    const topInformationStyle: CSSProperties = {
        marginBottom: '40px',
        marginTop: Size.PADDING,
        border: 'solid',
        padding: Size.PADDING,
    };

    const { study, user, setActiveStudy } = useStudyContext();
    const { canEditLinks, isLoadingPermissions } = useExternalLinkPermissionsHook(study);
    const { t } = useTranslation();

    return (
        <div>
            <div style={topInformationStyle}>
                {`Any supporting documentation files loaded on this page will be visible on the search screen to researchers searching for ${t(
                    'fileUploadInstructions'
                )}. Please provide only files that you want to make available to search screen viewers.`}
                ;
            </div>
            <div style={containerStyle}>
                {user && (
                    <>
                        <DocumentsDataPackageFeature showFileWarning={true} scrollEnabled={false}
                                                     dataPackageId={study.metaDataDataPackageId}
                                                     secondaryDataPackageId={study.ipdDataPackageId}/>
                        {study && !isLoadingPermissions && (
                            <StudyExternalLinksComponent
                                externalLinks={study.externalLinks}
                                documentId={study.id}
                                editable={canEditLinks}
                                onLinksUpdated={setActiveStudy}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
