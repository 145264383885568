import React, {CSSProperties} from 'react';
import {Color, Size} from "@vivli/shared/theme";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {ButtonComponent} from "@vivli/shared/components";
import {SubHeaderComponent} from "./sub-header.component";
import {DTIInternalLinkMenu} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    maxHeight: Size.HEADER_HEIGHT,
    minHeight: Size.HEADER_HEIGHT,
    backgroundColor: Color.VIVLI_DARK_BLUE,
    display: 'flex',
    flex: `1 0 ${Size.HEADER_HEIGHT}px`,
    justifyContent: 'space-between',
    boxShadow: Size.MENU_BOX_SHADOW,
    paddingRight: Size.PADDING,
    zIndex: 3,
}

const betaButtonStyle: CSSProperties = {
    backgroundColor: '#005475',
    height: '31px',
    margin: '10px 14px 10px 10px',
    background: '#0097c6',
}

export const InternalLinkMenuComponent = () => {
    const {viewRequestResultsOnly, myDataRequestsEnabled} = useConfigService();

    const handleBetaFeedbackClick = () => {
        const win = window.open('https://www.surveymonkey.com/r/6FCS2XK', '_blank');
        win.focus();
    }

    return (
        <div style={containerStyle}>
            <div style={{ display: 'flex' }}>
                {!myDataRequestsEnabled && (
                    <div>
                        <ButtonComponent
                            onClick={handleBetaFeedbackClick}
                            style={betaButtonStyle}
                            className='header_feedbackLink'
                            data-test-id={DTIInternalLinkMenu.HeaderFeedbackLink}
                        >Give Us Beta Feedback!
                        </ButtonComponent>
                    </div>
                )}
            </div>

            {!viewRequestResultsOnly && <SubHeaderComponent />}
        </div>
    )
}
