import {
    ApprovalStatusEnum,
    DataRequestStatusEnum,
    StudyRequestStatusEnum
} from '@vivli/features/data-requests/infrastructure/enum';
import {IDataRequestSummary} from '@vivli/features/data-requests/infrastructure/interface';


interface IApprovalStatuses {
    approved: number,
    pending: number,
    notApproved: number,
    available: number
}

export abstract class DataRequestFormatService {
    public static mapStatusToMessage(status: DataRequestStatusEnum, isAmr?: boolean, translatedDuaStatus?: string): string {

        //This method handles the ratio in which there are several statuses in Clinical Trials to the one status change in AMR.
        const getStatusMessage = (defaultStatusMessage) => {
            if (isAmr) {
                return 'Awaiting administrative processing.';
            }
            return defaultStatusMessage;
        }

        switch (status) {
            case DataRequestStatusEnum.SubmittedToVivli:
                return 'Submitted and Awaiting Vivli Request Form Check';
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return 'Data Contributor Review';
            case DataRequestStatusEnum.AwaitingIRPApproval:
                //this is 'awaiting administrative processing.'
                return getStatusMessage('Awaiting IRP/Reviewer Approval');
            case DataRequestStatusEnum.Approved:
                //this is 'awaiting administrative processing.'
                return getStatusMessage('Approved and Ready for DUA Validation');
            case DataRequestStatusEnum.AwaitingDUAValidation:
                //this is 'awaiting administrative processing.'
                return getStatusMessage('Approved and Awaiting DUA Validation');
            case DataRequestStatusEnum.DUAReturnedToRequester:
                //this is 'awaiting administrative processing'
                return getStatusMessage('DUA returned to Requestor for Edits (see history for details)');
            case DataRequestStatusEnum.DUAValidated:
                //this is 'awaiting data package upload.' Does not use the method above. Just a translation passed in for either Clinical Trials or AMR
                return translatedDuaStatus;
            case DataRequestStatusEnum.PartiallyFulfilled:
                return 'At least one Data Package Provided and Available';
            case DataRequestStatusEnum.Fulfilled:
                return 'All Data Packages Provided and Available';
            case DataRequestStatusEnum.Archived:
                return 'Archived';
            case DataRequestStatusEnum.RejectedByDataProvider:
                return 'Rejected by Data Contributor (see history for details)';
            case DataRequestStatusEnum.RejectedByIRP:
                return 'Rejected by IRP (see history for details)';
            case DataRequestStatusEnum.RejectedByVivli:
                return 'Not Approved During Vivli Request Form Check';
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
                return 'Revisions Requested by Data Contributor (see history for details)';
            case DataRequestStatusEnum.RevisionsRequestedByIRP:
                return 'Revisions Requested by IRP (see history for details)';
            case DataRequestStatusEnum.Cancelled:
                return 'Withdrawn';
        }

        return status;
    }

    public static getApprovalStatuses(dataRequest: IDataRequestSummary): IApprovalStatuses {
        let approved = 0;
        let pending = 0;
        let notApproved = 0;
        let available = 0;

        if (dataRequest.requiredIRPApprovals && dataRequest.requiredDataProviderApprovals && dataRequest.requestedStudies) {
            dataRequest.requestedStudies.forEach(study => {
                const irpApproval = dataRequest.requiredIRPApprovals.find(approval => approval.orgId === study.approvalBodyOrgId);
                const dpApproval = dataRequest.requiredDataProviderApprovals.find(approval => approval.orgId === study.organizationId);

                if (study.studyRequestStatus === StudyRequestStatusEnum.IPDProvided) {
                    available++;
                }

                if (dpApproval && (dpApproval.status === ApprovalStatusEnum.Rejected ||
                    dpApproval.status === ApprovalStatusEnum.RevisionsRequested) ||
                    irpApproval && (irpApproval.status === ApprovalStatusEnum.Rejected ||
                        irpApproval.status === ApprovalStatusEnum.RevisionsRequested)) {
                    // if a data provider or irp approver rejects or requests revisions
                    // that study is "not approved"
                    notApproved++;
                } else if (irpApproval && irpApproval.status === ApprovalStatusEnum.Approved) {
                    // if the IRP has approved the study, that means it already made it past the data provider
                    // so it is approved
                    approved++;
                } else {
                    // if a study is not rejected or approved by IRP, it is pending
                    // approval by data provider also counts as pending
                    pending++;
                }
            });
        }

        return {
            approved,
            notApproved,
            pending,
            available
        }
    }

    public static getLeadResearcher(dataRequest: IDataRequestSummary): string {
        const leadResearcherDetail = dataRequest.leadResearcherTeamMemberDetail;
        return leadResearcherDetail ? `${leadResearcherDetail.firstName} ${leadResearcherDetail.lastName}` : 'Unknown';
    }
}
