import {RestDefaultHeaders} from '@vivli/core/infrastructure/rest';
import {IRestApiOptions} from '@vivli/core/infrastructure/interface';
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {useAuthService} from "@vivli/core/authentication";

export const useDefaultApiOptions = (useSecureApi = false): IRestApiOptions  => {
    const config = useConfigService();
    const appInsights = useAppInsightsContext();
    const {getTokenPopup} = useAuthService();
    const baseUrl = `${useSecureApi ? config.secureApiUri : config.apiUri}api`;

    return {
        baseUrl: baseUrl,
        headers: RestDefaultHeaders,
        retryCalls: '1',
        refreshAuthToken: getTokenPopup,
        trackApiCall: (requestTime) => {
            appInsights?.trackEvent({name: 'React Api Call'}, {baseUrl, requestTime})
        }
    }
}
