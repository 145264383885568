﻿import {TextFieldReadOnlyFormComponent} from "@vivli/shared/components";
import React, {CSSProperties} from "react";
import {Size} from "@vivli/shared/theme";
import {IDataPackageFileDescriptor} from "@vivli/shared/features/data-package/infrastructure/interface";
import {DTICTFormFields} from "@vivli/shared/infrastructure/constants";

const textFieldHalfWidthStyle: CSSProperties = {
    flex: '0 1 50%',
    maxWidth: '45%',
    marginBottom: Size.FIELD_SEPARATION,
    cursor: 'default',
}

interface ViewDataPackageRowComponentProps {
    descriptor: IDataPackageFileDescriptor
}
export const ViewDataPackageRowComponent = (
    {
        descriptor
    }:ViewDataPackageRowComponentProps
) => {

    return (
        <>
            <TextFieldReadOnlyFormComponent
                label={'File Type'}
                defaultValue={descriptor.type}
                style={textFieldHalfWidthStyle}
                dataId={DTICTFormFields.FileType}
            />
            <TextFieldReadOnlyFormComponent
                label={'DOI Reference Number'}
                defaultValue={descriptor.datapackageDoiFileNumber}
                style={textFieldHalfWidthStyle}
                dataId={DTICTFormFields.DoiNumber}
            />
        </>
    )
}
