

import {DataRequestPermissionsService} from "@vivli/features/data-requests/infrastructure/service";
import React from "react";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {OrgDetailsBasicInformation} from "./org-details-basic-information.component";
import {OrgDetailsSectionComponent} from "./org-details-section.component";
import {OrganizationPolicies} from "./organization-policies.component";
import {OrgResourcesLinkComponent} from "./modals/org-resources-link.component";

interface OrgDetailsTabComponentProps {
    organization: IOrganization
}

export const OrgDetailsTabComponent = (
    {
        organization
    }: OrgDetailsTabComponentProps
) => {
    const user = useActiveUser();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const showOrgPoliciesContent = !isAmr && (user?.isVivliAdmin || DataRequestPermissionsService.isOrgMember
        (user, organization?.id, om => om.isOrgAdmin));

    return (
        <div>
            <OrgResourcesLinkComponent/>
            <OrgDetailsBasicInformation/>
            <OrgDetailsSectionComponent/>
            <OrganizationPolicies showOrgPoliciesContent={showOrgPoliciesContent} organization={organization}/>
        </div>
    )
}
