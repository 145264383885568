export const workflowStepNormsIntercept = [
    {
        normDescription: 'PreparationDays',
        workflowStepType: 'Preparation',
        code: 'F552D278-578D-4A5F-8B23-1EF70239098C',
        active: true,
        days: -1,
        startingStatus: 'Draft',
    },
    {
        normDescription: 'FormCheckDays',
        workflowStepType: 'FormCheck',
        code: '6E6AEAD6-4B4F-4F56-8E5B-E7374B2667F4',
        active: true,
        days: -1,
        startingStatus: 'SubmittedToVivli',
    },
    {
        normDescription: 'DcReviewDays',
        workflowStepType: 'DataContributorReview',
        code: '487D90C1-5B25-4176-8307-D31D80336862',
        active: true,
        days: 21,
        startingStatus: 'AwaitingDataProviderApproval',
    },
    {
        normDescription: 'DcRequestRevisionDays',
        workflowStepType: 'DcRequestRevision',
        code: 'A53FD17D-A24C-4608-9DA4-D81069F171B2',
        active: true,
        days: -1,
        startingStatus: 'RevisionsRequestedByDataProvider',
    },
    {
        normDescription: 'DcRejectedDays',
        workflowStepType: 'DcRejected',
        code: '89A61527-35E4-4934-B5C4-CA73FFAF419A',
        active: true,
        days: -1,
        startingStatus: 'RejectedByDataProvider',
    },
    {
        normDescription: 'IrpReviewDays',
        workflowStepType: 'IrpReview',
        code: 'C1DE15DE-701A-4731-8E1C-1C363E3BB874',
        active: true,
        days: 30,
        startingStatus: 'AwaitingIRPApproval',
    },
    {
        normDescription: 'IrpRequestRevisionDays',
        workflowStepType: 'IrpRequestRevision',
        code: '94528F66-6D34-4C6A-B1C8-59049D1E73A5',
        active: true,
        days: -1,
        startingStatus: 'RevisionsRequestedByIRP',
    },
    {
        normDescription: 'IrpRejectedDays',
        workflowStepType: 'IrpRejected',
        code: 'DE29C80A-B0B0-41C0-8924-EB5352DAF526',
        active: true,
        days: -1,
        startingStatus: 'RejectedByIRP',
    },
    {
        normDescription: 'DuaApprovalDays',
        workflowStepType: 'DuaApproval',
        code: '70937F5E-0A23-4347-9B8F-1CB0C96F75C0',
        active: true,
        days: -1,
        startingStatus: 'AwaitingDUAValidation',
    },
    {
        normDescription: 'DataUploadDays',
        workflowStepType: 'DataUpload',
        code: '091E4EC1-3B35-4D82-AF28-081615807E3C',
        active: true,
        days: 30,
        startingStatus: 'DUAValidated',
    },
    {
        normDescription: 'AnalysisDays',
        workflowStepType: 'Analysis',
        code: '84469284-D9E4-4E9A-BFBC-BD2BA7779EC3',
        active: true,
        days: -1,
        startingStatus: 'Fulfilled',
    },
    {
        normDescription: 'InactiveDays',
        workflowStepType: 'Inactive',
        code: '6409070C-8427-407F-84ED-35BF30E5EDF0',
        active: true,
        days: -1,
        startingStatus: 'Archived',
    },
];
