﻿import React, { CSSProperties } from 'react';
import {
    ButtonComponent,
    CheckboxFormFieldComponent,
    FieldHeaderComponent,
    HintPopoverComponent,
} from '@vivli/shared/components';
import {
    adminDivStyle,
    outerTabStyle,
    tabContentContainerStyle,
} from '../shared/styles';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import {
    useActiveUser,
    useConfigService,
} from '@vivli/core/infrastructure/context';
import { useWatch } from 'react-hook-form';
import { signAgreementHint } from '@vivli/features/listing-request/infrastructure/constants';
import { Color, Size } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const checkboxFieldStyle: CSSProperties = {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
};

export const AgreementsComponent = () => {
    const user = useActiveUser();
    const config = useConfigService();
    const { listingRequest, isReadOnly } = useListingRequestContext();

    const alreadySignedWatch = useWatch({ name: 'hasBeenSigned' });

    const status = listingRequest?.status
        ? ListingRequestStatusEnum[listingRequest?.status]
        : null;
    const isApproved = status
        ? status === ListingRequestStatusEnum.Approved
        : false;
    const isWithdrawn = status
        ? status === ListingRequestStatusEnum.Withdrawn
        : false;
    const isButtonEnabled = !isApproved && !isWithdrawn && !alreadySignedWatch;
    const buttonText = isButtonEnabled
        ? 'Begin Data Contribution Agreement'
        : 'Signed Agreement Complete';
    const isCheckboxEnabled =
        user?.isVivliAdmin && !isReadOnly && !isApproved && !isWithdrawn;
    const whatsNextText = "WHAT'S NEXT";

    const handleDocusignClick = () => {
        window.open(config?.docusignUrl, '_blank');
    };

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'AGREEMENTS'} />
                <div style={{ width: '70%' }}>
                    <p>
                        The Principal Investigator and an Institutional Official will need to read and acknowledge, and
                        an Institutional Official will need to sign this Data Contribution Agreement (DCA). If your
                        institution already has a Master DCA in place, we do not require an institutional signature for
                        future submissions. If you are unsure whether your institution has a Master agreement in place,
                        please reach out to{' '}
                        <a href={'mailto:support@vivli.org'} target={'_blank'}>
                            support@vivli.org
                        </a>
                        .
                    </p>
                    <p>
                        Click below to learn more about the Data Contribution Agreement and start the execution process:
                    </p>
                    <ButtonComponent
                        onClick={handleDocusignClick}
                        disabled={!isButtonEnabled}
                    >
                        {buttonText}
                    </ButtonComponent>
                    <p>
                        At a minimum, Vivli will make the data available for 10
                        years. On an ongoing basis, Vivli evaluates its data
                        holdings with regard to maintaining access and reserves
                        the right to discontinue the distribution of data
                        collections when deemed appropriate.
                    </p>

                    {user.isVivliAdmin && (
                        <div style={adminDivStyle}>
                            <p>
                                For the Vivli Admin - click Edit to indicate if
                                the agreement has been signed.
                            </p>
                            <CheckboxFormFieldComponent
                                name={'hasBeenSigned'}
                                label={'Agreement has been signed and approved'}
                                truncateLabel={false}
                                style={checkboxFieldStyle}
                                disabled={!isCheckboxEnabled}
                            />
                        </div>
                    )}
                </div>
                <FieldHeaderComponent title={whatsNextText} />
                <div style={{width: '70%'}}>
                    <p>
                        Once you have initiated the Data Contribution Agreement signing process, please click the Submit
                        button to notify Vivli to begin processing the study.
                    </p>
                    <p>
                        *If the submit button is not available, required fields may be incomplete. Check that all
                        required fields on all tabs are complete and click Submit.
                    </p>
                    <p>
                        You will receive an email from Vivli with next steps for Data Upload once the Data Contribution
                        Agreement is complete.
                    </p>
                </div>
            </div>
        </div>
    );
};
