import joi from 'joi';

export const requiredTemplateString = '{#label} is required.';
export const JoiValidationOptionsConstant: joi.ValidationOptions = {
    messages: {
        'string.required': requiredTemplateString,
        'string.empty': requiredTemplateString,
        'array.min': requiredTemplateString,
        'string.base': requiredTemplateString,
    },
    allowUnknown: true,
    errors: {
        wrap: {
            label: false
        }
    }
};
export const OrcidRegex = /^\d{4}-\d{4}-\d{4}-\d{3}[\dX]$/;


