import { IConfig } from '@vivli/shared/infrastructure/interface';
import { useQueryParamsHook } from './use-query-params.hook';
import { StorageService } from '@vivli/shared/infrastructure/service';
import { useMatch } from 'react-router-dom';

const portalConfigSessionKey = 'VivliPortalConfig';
const portalNameSessionKey = 'VivliPortalName';

//reusable function for configuring custom portals
export function usePortalConfigHook() {
    const { search } = useQueryParamsHook();
    const matchPortal = useMatch('/portal/:customPortal');
    const matchDefault = useMatch('/');

    const getPortalName = (): string => {
        if (matchPortal) {
            return matchPortal.params.customPortal;
        } else if (matchDefault) {
            return 'default';
        }

        return null;
    };

    const getCurrentPortalName = (): string => {
        const storedPortalName = StorageService.getItem<string>(portalNameSessionKey, 'session');

        return storedPortalName || 'default';
    };

    const getPortalConfig = (config: IConfig): IConfig => {
        const portalConfig = { ...config };
        const portalName = getPortalName();
        const storedPortalName = StorageService.getItem<string>(portalNameSessionKey, 'session');

        // update the portal config only if the user is is on the search page
        if (portalName && portalConfig?.customPortals.length > 0) {
            const customPortal = portalConfig.customPortals.find((portal) => portal.portalName == portalName);
            if (customPortal) {
                portalConfig.bannerConfig = customPortal.bannerConfig;
                portalConfig.initialSearch = customPortal.initialSearch || search;
                portalConfig.portalTitle = customPortal.portalTitle;

                StorageService.setItem(portalNameSessionKey, portalName, 'session');
                StorageService.setItem(portalConfigSessionKey, portalConfig, 'session');
            }
        } else if (storedPortalName) {
            // if we are not on the search page and we do have a stored portal, return the config for it
            const storedConfig = StorageService.getItem<IConfig>(portalConfigSessionKey, 'session');

            // return the stored config if found, otherwise return the default config
            return storedConfig || portalConfig;
        }

        return portalConfig;
    };

    const getSearchLink = (): string => {
        const portalName = getCurrentPortalName();
        return portalName === 'default' ? '/' : `/portal/${portalName}`;
    };

    return {
        getPortalConfig,
        getSearchLink,
        getCurrentPortalName,
    };
}
