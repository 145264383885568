import {
    IMsal2AuthService,
    IMsal2ProviderOptions,
    IMsalLoginOptions,
    LoginEvent,
    LogoutEvent,
    Msal2EventType,
    RefreshAuthExpirationEvent
} from "./interface";
import {AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import {Subject} from "rxjs";

const fakeAccount = {
    "homeAccountId": "d9394b16-7958-4c05-8353-b3d58d10cab0-b2c_1a_signin.8169f436-4306-4551-aeb6-3952cbbf9fec",
    "environment": "vivlidev.b2clogin.com",
    "tenantId": "8169f436-4306-4551-aeb6-3952cbbf9fec",
    "username": "",
    "localAccountId": "d9394b16-7958-4c05-8353-b3d58d10cab0",
    "name": "Test User",
    "idTokenClaims": {
        "exp": 1675874972,
        "nbf": 1675871372,
        "ver": "1.0",
        "iss": "https://vivlidev.b2clogin.com/8169f436-4306-4551-aeb6-3952cbbf9fec/v2.0/",
        "sub": "d9394b16-7958-4c05-8353-b3d58d10cab0",
        "aud": "0ab5fd88-35e3-465d-881f-d4ddcf0817f2",
        "nonce": "fb32e1ba-6bfb-4b81-aee9-fcd214b7eee6",
        "iat": 1675871372,
        "auth_time": 1675871345,
        "oid": "d9394b16-7958-4c05-8353-b3d58d10cab0",
        "name": "Test User",
        "email": "test.user@vivli.org",
        "idp": "localAuthority",
        "tid": "8169f436-4306-4551-aeb6-3952cbbf9fec",
        "tfp": "B2C_1A_SignIn"
    }
}
const fakeToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NzU4NzY0ODMsIm5iZiI6MTY3NTg3Mjg4MywidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly92aXZsaWRldi5iMmNsb2dpbi5jb20vODE2OWY0MzYtNDMwNi00NTUxLWFlYjYtMzk1MmNiYmY5ZmVjL3YyLjAvIiwic3ViIjoiZDkzOTRiMTYtNzk1OC00YzA1LTgzNTMtYjNkNThkMTBjYWIwIiwiYXVkIjoiMGFiNWZkODgtMzVlMy00NjVkLTg4MWYtZDRkZGNmMDgxN2YyIiwibm9uY2UiOiJlMmIyN2Y5MS0xZDk0LTRhMjItODc4NS00NGViNTU2NDY0Y2QiLCJpYXQiOjE2NzU4NzI4ODMsImF1dGhfdGltZSI6MTY3NTg3Mjg4Miwib2lkIjoiZDkzOTRiMTYtNzk1OC00YzA1LTgzNTMtYjNkNThkMTBjYWIwIiwibmFtZSI6IlRlc3QgVXNlciIsImVtYWlsIjoidGVzdC11c2VyQHZpdmxpLm9yZyIsImlkcCI6ImxvY2FsQXV0aG9yaXR5IiwidGlkIjoiODE2OWY0MzYtNDMwNi00NTUxLWFlYjYtMzk1MmNiYmY5ZmVjIiwidGZwIjoiQjJDXzFBX1NpZ25JbiJ9.CuSyVOpySt2MtSFf4EBmqskollJTa-TU4N8P99Fml-k";

export class Msal2FakeService implements IMsal2AuthService {
    public authEvents: Subject<Msal2EventType>;

    public options: IMsal2ProviderOptions;

    private account: AccountInfo;

    private apiToken: string;

    constructor(options: IMsal2ProviderOptions) {
        this.authEvents = new Subject<Msal2EventType>();
        this.options = options;
    }

    public getAccount = (): AccountInfo | null => {
        return this.account;
    }

    public getApiToken = (): string => {
        return fakeToken;
    }

    public getTokenPopup = (): Promise<string | null> => {
        return new Promise((resolve) => {
            const token = this.getApiToken();
            this.triggerEvent(new RefreshAuthExpirationEvent(token))
            resolve(token);
        })
    }

    public getTokenRedirect = (): Promise<string | null> => {
        const token = this.getApiToken();
        return new Promise((resolve) => {
            this.triggerEvent(new RefreshAuthExpirationEvent(token))
            resolve(token);
        })
    }

    public isSsoUser = (): boolean => {
        return false;
    }

    public loadAuthModule = (): Promise<any> => {
        return new Promise<any>((res) => res(''));
    }

    public login = (options?: IMsalLoginOptions): Promise<AuthenticationResult | void> => {
        this.account = fakeAccount;
        this.apiToken = fakeToken;

        return new Promise((resolve) => {
            const loginEvent = new LoginEvent(fakeAccount, fakeToken);
            this.triggerEvent(loginEvent);
            resolve();
        });
    }

    public logout = (): void => {
        this.account = null;
        this.apiToken = null;

        this.triggerEvent(new LogoutEvent());
    }

    public signUp = (): Promise<AuthenticationResult> => {
        return Promise.resolve(undefined);
    }

    public silentLogin = (): void => {
    }

    public triggerEvent = (event: Msal2EventType): void => {
        this.authEvents.next(event);
    }

    public updatePassword = (): void => {
    }

    public updateProfile = (): void => {
    }

    loadNonLocalAccount(onLoginSuccess?: (userAccount: AccountInfo) => void) {
    }

}
