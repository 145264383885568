﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { AzureFiltersSearchService, AzureSearchService } from '@vivli/features/search/infrastructure/service';
import React from 'react';
import { AzureSearchContext } from '@vivli/features/search/infrastructure/context';

export const AzureSearchServiceContextWrapper = ({ children }: IContextWrapper) => {
    const config = useConfigService();
    const studiesSearch = new AzureSearchService(config.queryKey, config.queryUri, 'studies');
    const vocabSearch = new AzureSearchService(config.queryKey, config.queryUri, 'vocab', false);
    const filtersSearch = new AzureFiltersSearchService(config.queryKey, config.queryUri, 'studies');

    const provider = {
        studiesSearch,
        vocabSearch,
        filtersSearch,
    };

    return <AzureSearchContext.Provider value={provider}>{children}</AzureSearchContext.Provider>;
};
