import {useEffect, useState} from "react";
import {IExternalMenuItem} from "@vivli/shared/infrastructure/interface";
import {useVivliConfigurationService} from "@vivli/core/infrastructure/context";
import {first} from "rxjs/operators";

export function useExternalMenuItemsHook() {
    const [externalMenuItems, setExternalMenuItems] = useState<IExternalMenuItem[]>();

    const vivliConfigService = useVivliConfigurationService();

    useEffect(() => {
        const sub = vivliConfigService.getExternalMenuItems()
            .pipe(first())
            .subscribe(setExternalMenuItems);

        return () => {
            sub.unsubscribe();
        }
    }, [])

    return externalMenuItems;
}
