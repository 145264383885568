﻿import {useWatch} from "react-hook-form";
import {useEffect} from "react";
export function useMapListToFormHook({formFieldName, formFieldToUpdate, listOfObjects, objectListId = 'value', objectValueToReturn = 'title',  formApi}) {

    //hook is for the case when an object accessed by form
    //(e.g. study) contains multiple properties copied from
    //another object, and the form contains a choice component for updating those properties by
    //choosing a different related object. For example, study contains an annotatorOrgId
    //and an annotatorOrgName, both of which are properties of
    //the related object (organization). However, with this hook choosing a new annotator org
    //from the form dropdown only makes the form change the key (annotatorOrgId) on study
    //The hook forces ADDITIONAL changes to the form-accessed object's properties when a related object
    //is changed via some other component

    //formFieldName = id of object used in form (e.g. annotatorOrgId in study object)
    //formFieldToUpdate - the name of the form field being updated
    //listofObjects - the related objects (e.g. the annotator orgs)
    //objectListId - the key to the related object list in the form component - defaults to 'value'
    //objectValueToReturn - the value for the objectListId in the form component - default to 'title'

    const formFieldValue = useWatch({name: formFieldName})

    useEffect(() => {
        if (!listOfObjects || !objectListId || !objectValueToReturn || !formFieldValue) {
            return;
        }

        const value = listOfObjects.find(o => o[objectListId] === formFieldValue)[objectValueToReturn];

        if (value) {
            formApi.setValue(formFieldToUpdate, value, {shouldDirty: true, shouldValidate: true});
        }
    }, [formFieldValue])
}
