import React, {CSSProperties} from 'react';
import {LinkMenuComponent} from "./link-menu.component";
import Tippy, {useSingleton} from "@tippyjs/react";
import {useExternalMenuItemsHook} from "@vivli/core/infrastructure/hook";
import {IMenuItem} from "@vivli/shared/infrastructure/interface";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 50,
    marginTop: 45,
    color: '#03395a',
}

const linkMenuStyle: CSSProperties = {display: 'flex'}

const findStudiesStyle: CSSProperties = {
    marginLeft: 6,
    marginRight: 6,
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#03395a',
}

const findStudiesArrowStyle: CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #053d58',
    position: 'absolute',
    top: 20,
    marginLeft: 42,
}

interface ExternalLinkMenuComponentProps {
    onFindStudiesClick: () => void;
}

export const ExternalLinkMenuComponent = (
    {
        onFindStudiesClick
    }: ExternalLinkMenuComponentProps) => {
    const [source, target] = useSingleton();
    const externalMenuItems = useExternalMenuItemsHook();

    const getLinks = (menuItems: IMenuItem[]) => {
        return menuItems?.map(({name, url}) => ({title: name, href: url}));
    }

    return (
        <div style={containerStyle}>
            <Tippy
                singleton={source}
                placement={'bottom'}
                arrow={false}
                className='tippy-link-menu'
                offset={[10, 0]}
                animation='shift-away-extreme'
                interactive={true}
            />

            {externalMenuItems?.map(({name, url, menuItems}, index) => (
                <div style={linkMenuStyle} key={index}>
                    <LinkMenuComponent
                        title={name}
                        titleHref={url}
                        links={getLinks(menuItems)}
                        singleton={target} />
                </div>
            ))}

            <div style={{...linkMenuStyle, position: 'relative'}}>
                <a style={findStudiesStyle} onClick={onFindStudiesClick}>Find Studies</a>
                <div style={findStudiesArrowStyle} />
            </div>
        </div>
    )
}
