﻿import React, {CSSProperties} from "react";
import {ITimeIntervalMetrics} from "@vivli/features/dashboard/infrastructure/interface";
import {Color, Size} from "@vivli/shared/theme";
import {MetricsConstants} from "@vivli/features/dashboard/infrastructure/constants";
import {TimeIntervalMetricsEnum} from "@vivli/features/dashboard/infrastructure/enum";

const darkBlueTextColor = Color.VIVLI_DARK_BLUE;
const flexStyle: CSSProperties = {
    display: 'flex'
}
const containerStyle: CSSProperties = {
    color: darkBlueTextColor,
    fontSize: Size.FontSize.Large * 1.2,
    backgroundColor: 'white',
    padding: MetricsConstants.Sizing.SPACING,
}
const innerContainerStyle: CSSProperties = {
    ...flexStyle,
    justifyContent: 'space-between',
    marginTop: '4px',
}
const flexBoldBetweenStyle: CSSProperties = {
    ...flexStyle,
    fontWeight: Size.FontWeight.Bold,
    justifyContent: 'space-between',
}
const halfWidthStyle: CSSProperties = {
    width: '50%'
}
const quarterCenterStyle: CSSProperties = {
    width: '25%',
    textAlign: 'center',
}
const quarterEndStyle: CSSProperties = {
    width: '25%',
    textAlign: 'end'
}
const averageStyle: CSSProperties = {
    ...quarterCenterStyle,
    fontWeight: Size.FontWeight.Bold
}

interface AverageTimeListComponentProps {
    data: ITimeIntervalMetrics;
    isAggregateReport?: boolean;
}
export const AverageTimeListComponent = (
    {
        data,
        isAggregateReport
    }:AverageTimeListComponentProps
) => {
    const getAverage = (key, dataPoint) => {
        return key !== MetricsConstants.Text.OVERALL_TIME_TO_PUBLICATION_KEY &&
            (isAggregateReport
                ? dataPoint.allDataContributorsAverage
                : dataPoint.orgAverage);
    }

    return (
        <div style={containerStyle}>
            <div>
                <div style={flexBoldBetweenStyle}>
                    <div style={halfWidthStyle}>{MetricsConstants.Text.TIME_LIST_COL_HEADER_1}</div>
                    <div style={quarterCenterStyle}>{MetricsConstants.Text.TIME_LIST_COL_HEADER_2}</div>
                    <div style={quarterEndStyle}>{MetricsConstants.Text.TIME_LIST_COL_HEADER_3}</div>
                </div>
                {Object.keys(data).map((key: string, index: number) => {
                    const dataPoint = data[key];
                    return (
                        <div key={index} style={innerContainerStyle}>
                            <div style={halfWidthStyle}>{TimeIntervalMetricsEnum[key]}</div>
                            <div style={averageStyle}>
                                {getAverage(key, dataPoint)}
                            </div>
                            <div style={quarterEndStyle}>
                                {dataPoint.minimum} - {dataPoint.maximum}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>)
}
