﻿import { CtSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ICtSearchOptionsContext, ISampleSize, ISelectOption } from '@vivli/features/search/infrastructure/interface';
import { IDateRange } from '@vivli/shared/infrastructure/interface';
import { useDateRangeFilterHook, useDropdownFilterHook, useSampleSizeFilterHook } from '@vivli/features/search/infrastructure/hook';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { usePortalConfigHook, useQueryParamsHook } from '@vivli/shared/infrastructure/hook';

interface CtSearchOptionsContextWrapperProps {
    children?: ReactNode;
}
export const CtSearchOptionsContextWrapper = ({ children }: CtSearchOptionsContextWrapperProps) => {
    const { handleSampleSizeFilter, handleDateRangeFilter, handleDropdownFilter, updateFilterString } = useSearchContext();
    const [studyPhaseSelectedOptions, setStudyPhaseSelectedOptions] = useState<ISelectOption[]>([]);
    const [locationSelectedOptions, setLocationSelectedOptions] = useState<ISelectOption[]>([]);
    const [interventionalStudiesSelectedOptions, setInterventionalStudiesSelectedOptions] = useState<ISelectOption[]>([]);
    const [observationalStudiesSelectedOptions, setObservationalStudiesSelectedOptions] = useState<ISelectOption[]>([]);
    const [studyDesignSelectedOptions, setStudyDesignSelectedOptions] = useState<ISelectOption[]>([]);
    const [sponsorTypeSelectedOptions, setSponsorTypeSelectedOptions] = useState<ISelectOption[]>([]);
    const [funderSelectedOptions, setFunderSelectedOptions] = useState<ISelectOption[]>([]);
    const [contributorSelectedOptions, setContributorSelectedOptions] = useState<ISelectOption[]>([]);
    const [sponsorSelectedOptions, setSponsorSelectedOptions] = useState<ISelectOption[]>([]);
    const [fromDateRange, setFromDateRange] = useState<IDateRange>();
    const [toDateRange, setToDateRange] = useState<IDateRange>();
    const [sampleSize, setSampleSize] = useState<ISampleSize>();
    const [sampleSizeEnabled, setSampleSizeEnabled] = useState(false);
    const { search } = useQueryParamsHook();
    const [searchString, setSearchString] = useState<string>(null);

    const { initialSearch } = useConfigService();
    const { getCurrentPortalName } = usePortalConfigHook();
    const initialRef = useRef(false);

    useDropdownFilterHook({
        key: 'phase',
        options: studyPhaseSelectedOptions,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'locationsOfStudySites',
        options: locationSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'studyDesign',
        options: studyDesignSelectedOptions,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'sponsorType',
        options: sponsorTypeSelectedOptions,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'funder',
        options: funderSelectedOptions,
        onChange: handleDropdownFilter,
        useAnySyntax:true
    });

    useDropdownFilterHook({
        key: 'orgName',
        options: contributorSelectedOptions,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'sponsorName',
        options: sponsorSelectedOptions,
        onChange: handleDropdownFilter,
    });

    useDateRangeFilterHook({
        key: 'studyStartDate',
        dateRange: fromDateRange,
        onChange: handleDateRangeFilter,
    });

    useDateRangeFilterHook({
        key: 'studyEndDate',
        dateRange: toDateRange,
        onChange: handleDateRangeFilter,
    });

    useSampleSizeFilterHook({
        key: 'actualEnrollment',
        sampleRange: sampleSize,
        onChange: handleSampleSizeFilter,
    });

    const resetOptions = () => {
        setStudyPhaseSelectedOptions([]);
        setLocationSelectedOptions([]);
        setFromDateRange(undefined);
        setToDateRange(undefined);
        setSampleSize(undefined);
        setSampleSizeEnabled(false);
        setInterventionalStudiesSelectedOptions([]);
        setObservationalStudiesSelectedOptions([]);
        setSponsorTypeSelectedOptions([]);
        setFunderSelectedOptions([]);
        setContributorSelectedOptions([]);
        setSponsorSelectedOptions([]);
        setSearchString('');
    };

    useEffect(() => {
        if (search) {
            updateFilterString(search);
        } else if (initialSearch && !initialRef.current) {
            updateFilterString(initialSearch);
        } else {
            updateFilterString('*');
        }

        initialRef.current = true;
    }, [search]);

    useEffect(() => {
        setStudyDesignSelectedOptions([...interventionalStudiesSelectedOptions, ...observationalStudiesSelectedOptions]);
    }, [interventionalStudiesSelectedOptions, observationalStudiesSelectedOptions]);

    useEffect(() => {
        const currentPortal = getCurrentPortalName();

        if (currentPortal !== 'default' && !initialRef.current && searchString === null && initialSearch !== null) {
            setSearchString(initialSearch);
            updateFilterString(initialSearch);
        } else if (currentPortal === 'default' && !initialRef.current && searchString === null) {
            setSearchString('*');
        }
    }, [initialSearch]);

    const provider: ICtSearchOptionsContext = {
        studyPhaseSelectedOptions,
        setStudyPhaseSelectedOptions,
        locationSelectedOptions,
        setLocationSelectedOptions,
        fromDateRange,
        setFromDateRange,
        toDateRange,
        setToDateRange,
        sampleSize,
        setSampleSize,
        sampleSizeEnabled,
        setSampleSizeEnabled,
        interventionalStudiesSelectedOptions,
        setInterventionalStudiesSelectedOptions,
        observationalStudiesSelectedOptions,
        setObservationalStudiesSelectedOptions,
        sponsorTypeSelectedOptions,
        setSponsorTypeSelectedOptions,
        sponsorSelectedOptions,
        setSponsorSelectedOptions,
        resetOptions,
        setSearchString,
        searchString,
        funderSelectedOptions,
        setFunderSelectedOptions,
        contributorSelectedOptions,
        setContributorSelectedOptions
    };

    return <CtSearchOptionsContext.Provider value={provider}>{children}</CtSearchOptionsContext.Provider>;
};
