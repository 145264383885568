import React, {CSSProperties, useState} from 'react';
import {useResearchEnvironmentContext} from "@vivli/features/data-requests/infrastructure/context";
import {ButtonComponent} from "@vivli/shared/components";
import {Color} from "@vivli/shared/theme";

interface VmProvisionedButtonComponent {
    color: string;
    onClick: (onCancel?: () => void) => void;
    title: string;
}

export const VmProvisionedButtonComponent = ({color, onClick, title}: VmProvisionedButtonComponent) => {
    const {isWorking, setIsWorking} = useResearchEnvironmentContext();
    const [isLoading, setIsLoading] = useState(false);

    const buttonStyle: CSSProperties = {
        backgroundColor: Color.TRANSPARENT,
        color: color,
        borderBottom: `1px solid ${color}`,
        borderLeft: `1px solid ${color}`,
        borderRight: `1px solid ${color}`,
        borderTop: `1px solid ${color}`,
        minWidth: 230,
        height: 55,
    }

    const handleOnClick = () => {
        setIsLoading(true);
        setIsWorking(true);

        onClick(() => {
            setIsLoading(false)
        });
    }

    return (
        <ButtonComponent
            disabled={isWorking}
            isLoading={isLoading}
            onClick={handleOnClick}
            style={buttonStyle}
        >
            {title}
        </ButtonComponent>
    )
}
