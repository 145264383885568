import joi, {ObjectSchema} from 'joi';
import {useForm} from "react-hook-form";
import {joiResolver} from '@hookform/resolvers/joi';
import {IStatisticalAnalysisPlan} from "@vivli/features/data-requests/infrastructure/interface";
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";

export const statisticalAnalysisFormSchema: ObjectSchema<IStatisticalAnalysisPlan> = joi.object({
    description: joi.string().required().label('Description'),
    analysisLocation: joi.optional().allow('').label('Analysis Location')
}).options(JoiValidationOptionsConstant)

export function useStatisticalAnalysisFormHook(statisticalAnalysisPlan: IStatisticalAnalysisPlan, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(statisticalAnalysisFormSchema.options(JoiValidationOptionsConstant)),
        defaultValues: statisticalAnalysisPlan,
        reValidateMode: 'onChange'
    });
}
