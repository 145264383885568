import React from 'react';
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {BaseControlledFormFieldComponent} from "./base-controlled-form-field.component";
import {TextAreaComponent} from "../input/text-area.component";

interface TextAreaFormFieldComponentProps extends IFormField {
    hint?: string;
    rows?: number;
    name: string;
    showCount?: boolean;
    maxCount?: number;
}

export const TextAreaFormFieldComponent = (props: TextAreaFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <TextAreaComponent {...props} />
        </BaseControlledFormFieldComponent>
    )
}
