import React, { CSSProperties, useEffect, useState } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { Color } from '@vivli/shared/theme';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { IUserDetails } from '@vivli/features/users/infrastructure/interface';
import { IOrganizationRoles } from '@vivli/shared/infrastructure/interface';
import { ICellRendererParams } from 'ag-grid-community';
import { LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';

const deactivateButtonStyle: CSSProperties = {
    margin: 'auto',
    minWidth: 87,
    height: 34,
    fontSize: 13,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottom: `1px solid ${Color.ERROR_RED}`,
    borderLeft: `1px solid ${Color.ERROR_RED}`,
    borderRight: `1px solid ${Color.ERROR_RED}`,
    borderTop: `1px solid ${Color.ERROR_RED}`,
    backgroundColor: Color.ERROR_RED,
    color: Color.WHITE,
};

const activateButtonStyle: CSSProperties = {
    margin: 'auto',
    height: 34,
    fontSize: 13,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 87,
    borderBottom: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    borderLeft: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    borderRight: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    borderTop: `1px solid ${Color.VIVLI_LIGHT_BLUE}`,
    backgroundColor: Color.VIVLI_LIGHT_BLUE,
    color: Color.WHITE,
};

export interface MemberRowRendererComponentProps extends ICellRendererParams {
    data: IOrganizationRoles;
    user: IUserDetails;
    getOrganization: (orgId) => IOrganization;
    getDisabled?: (orgId) => boolean;
    onLoading?: (isLoading: boolean, orgId: string) => void;
    onMemberUpdated?: (memberRights: IOrganizationRoles) => void;
}

export const MemberRowRendererComponent = ({
    data,
    column,
    getDisabled,
    onLoading,
    user,
    onMemberUpdated,
    getOrganization,
}: MemberRowRendererComponentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { setOrganizationMemberRights } = useOrganizationsService();
    const disabled = () => getDisabled(data.orgId);
    const organization = getOrganization(data.orgId);
    const fieldName = column.getColDef().field;

    const currentRoleValue = data[fieldName];
    const orgRoleValue = organization[fieldName];

    const handleRoleUpdate = () => {
        setIsLoading(true);

        const updatedMemberRights = { ...data };
        updatedMemberRights[fieldName] = !currentRoleValue;

        const payload: IOrganizationRoles = {};
        payload[fieldName] = !currentRoleValue;
        setOrganizationMemberRights(data.orgId, user.id, user.email, payload).subscribe((result) => {
            if (result) {
                onMemberUpdated && onMemberUpdated(updatedMemberRights);
            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        onLoading && onLoading(isLoading, data.orgId);
    }, [isLoading]);

    if (orgRoleValue || fieldName === 'isOrgAdmin') {
        const buttonName = (currentRoleValue ? 'orgDetails_cancel_' : 'orgDetails_activate_') + fieldName.replace(/[^\w-]/gi, '');
        return (
            <div style={{ marginTop: '0.6em' }}>
                <ButtonComponent
                    isLoading={isLoading}
                    style={currentRoleValue ? deactivateButtonStyle : activateButtonStyle}
                    title=""
                    disabled={isLoading || disabled()}
                    dataId={buttonName}
                    className={buttonName}
                    onClick={handleRoleUpdate}
                    loaderColor={LoadIndicatorColorEnum.White}
                >
                    {currentRoleValue ? 'Deactivate' : 'Activate'}
                </ButtonComponent>
            </div>
        );
    }

    return <div style={{ textAlign: 'center', fontWeight: 'bold' }}>N/A</div>;
};
