import { useEffect, useState } from 'react';
import {useLocation} from "react-router";
import ReactGA from 'react-ga4';
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useAssignedAppType} from "./use-assigned-app-type.hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";

export function useGAPageTracking() {

    const {pathname, search} = useLocation();
    const [initialized, setInitialized] = useState(false);
    const config = useConfigService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const gaMeasurementId: string =  isAmr? config.gaMeasurementIdAmr : config.gaMeasurementId;

    useEffect(() => {
        if (gaMeasurementId) {
            ReactGA.initialize(gaMeasurementId,{testMode: false,
            gaOptions: {
                nonIteraction: false
            }});
            setInitialized(true);
        }
    }, []);

}


