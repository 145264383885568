import React from 'react';
import {Color} from '@vivli/shared/theme';
import {ButtonComponent} from './buttons/button.component';
import {AssetsConstant, DTICommonConst} from '@vivli/shared/infrastructure/constants';
import {LoadIndicatorComponent} from "./load-indicator/load-indicator.component";

interface ExportCsvButtonProps {
    onClick: (e) => void;
    style?: React.CSSProperties;
    title?: string;
    isLoading?: boolean;
}

const buttonContainerStyle: React.CSSProperties = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '130px',
};
const buttonStyle: React.CSSProperties = {
    background: 'transparent',
    color: Color.VIVLI_BLUE,
    boxShadow: 'none',
    minWidth: '60px',
    paddingLeft: '0px',
};

export const ExportCsvButtonComponent = (props: ExportCsvButtonProps) => {
    const {onClick, style, title, isLoading} = props;

    const handleOnClick = (e) => {
        if (!isLoading) {
            onClick(e);
        }
    }

    return (
        <div data-test-id={DTICommonConst.ExportCsvButton} style={{...buttonContainerStyle, ...style}}>
            <ButtonComponent onClick={handleOnClick} style={buttonStyle} title={title || 'Export CSV'} dataId={DTICommonConst.HeaderApprovalButton('ExportCSV')}
                             disabled={isLoading}>
                {isLoading && <LoadIndicatorComponent />}
                {!isLoading && <img src={AssetsConstant.DOWNLOAD_ARROW} style={{height: '25px'}}/>}
            </ButtonComponent>
        </div>
    );
};
