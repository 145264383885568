﻿import React, {CSSProperties, useEffect} from "react";
import {FormProvider} from "react-hook-form";
import {useListingRequestFormHook} from "@vivli/features/listing-request/infrastructure/hook";
import {useListingRequestContext} from "@vivli/features/listing-request/infrastructure/context";
import {ListingRequestHeaderComponent} from './header/listing-request-header.component';
import {ListingRequestRoutesComponent} from './nav/listing-request-routes.component';
import {ListingRequestTabsComponent} from './nav/listing-request-tabs.component';
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {NotificationContextWrapper} from "@vivli/shared/features/notification/infrastructure/wrappers";

const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    height: 'calc(100% - 70px)',
    overflow: 'auto'
}

export const ListingRequestFormComponent = () => {
    const {listingRequest} = useListingRequestContext();
    const formApi = useListingRequestFormHook(listingRequest);

    useEffect(() => {
        // force initial validation
        formApi.trigger();
    }, [])
    if (!listingRequest) {
        return <LoadIndicatorCenteredComponent/>;
    }

    return (
        <FormProvider {...formApi} >
            <ListingRequestHeaderComponent/>
            <div style={bodyContainerStyle} className='do-not-print'>
                <ListingRequestTabsComponent/>
                <NotificationContextWrapper>
                    <ListingRequestRoutesComponent/>
                </NotificationContextWrapper>
            </div>
        </FormProvider>


    )
}
