import React, {CSSProperties, useEffect, useState} from "react";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {Color, Size} from "@vivli/shared/theme";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {
    AdminHeaderButtonComponent,
    AdminHeaderTitleComponent,
    ButtonComponent,
    GoBackLinkComponent
} from "@vivli/shared/components";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useOrganizationDetailsContext} from "@vivli/features/organizations/infrastructure/context";
import {useFormContext} from "react-hook-form";
import {useNavigate} from "react-router-dom";

const buttonStyle: CSSProperties = {
    marginLeft: Size.PADDING,
    borderBottom: `1px solid ${Color.VIVLI_BLUE}`,
    borderLeft: `1px solid ${Color.VIVLI_BLUE}`,
    borderRight: `1px solid ${Color.VIVLI_BLUE}`,
    borderTop: `1px solid ${Color.VIVLI_BLUE}`,
    backgroundColor: Color.TRANSPARENT,
    color: Color.VIVLI_BLUE,
}

const headerDisplayStyle: CSSProperties = {
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: Size.HALF_PADDING
}

const buttonsStyle: CSSProperties = {
    display: 'flex', flexDirection: 'row', justifyContent: 'end'
}


export const OrgAdminHeaderComponent = () => {
    const user = useActiveUser()
    const modalService = useModalService();
    const navigate = useNavigate();
    const formApi = useFormContext<IOrganization>();
    const {organization, handleSave, isSaving} = useOrganizationDetailsContext();

    const [adminHeaderTitle, setAdminHeaderTitle] = useState<string>(organization?.name);

    const handleClick = () => {
        modalService.confirm('Do you want to discard your changes?', {
            title: 'Cancel',
            onConfirm: () => navigate('/admin/orgs/')
        });
    }

    useEffect(() => {
        if (organization?.id){
            setAdminHeaderTitle(organization?.name || organization?.emailDomain);
        }
        else {
            setAdminHeaderTitle('Add New Organization');
        }
    }, [organization])

    return (
        <div>
            <div style={headerDisplayStyle}>
                <div>
                    {(user?.isVivliAdmin &&
                        <GoBackLinkComponent destination={'/admin/orgs'}/>
                    )}
                </div>

                <div style={buttonsStyle}>
                    <AdminHeaderButtonComponent
                        title='Save'
                        onClick={formApi.handleSubmit(handleSave)}
                        isLoading={isSaving}
                        isDisabled={!formApi.formState.isValid}
                    />

                    <ButtonComponent
                        style={buttonStyle}
                        title='Cancel'
                        disabled={isSaving}
                        onClick={handleClick}
                        className='orgDetails_cancel'
                    >
                        Cancel
                    </ButtonComponent>
                </div>

            </div>
            <div style={{marginBottom: 10}}>
                <AdminHeaderTitleComponent
                    title={adminHeaderTitle}/>
            </div>
        </div>
    );

}
