import React, {CSSProperties, useEffect} from 'react';
import {
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    LoadIndicatorCenteredComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent
} from "@vivli/shared/components";
import {IDataProvider} from "@vivli/features/data-requests/infrastructure/interface";
import {useFormContext} from "react-hook-form";
import {DTIAddUnlistedStudyForm, DTICTFormFields, DTIDropdownMenu} from "@vivli/shared/infrastructure/constants";

const formContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15
}

interface AddUnlistedStudyFormComponentProps {
    providers: IDataProvider[];
}

export const AddUnlistedStudyFormComponent = (
    {
        providers
    }: AddUnlistedStudyFormComponentProps
) => {
    const formApi = useFormContext();

    useEffect(() => {
        if (!providers) { return; }

        formApi.trigger();
    }, [providers])

    if (!providers) {
        return <LoadIndicatorCenteredComponent/>;
    }

    return (
        <div style={formContainerStyle}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <div style={{width: '30%', display: 'flex', flexDirection: 'column'}}>
                    <FieldHeaderComponent title={'Select provider of the data'}/>
                    <div data-test-id={DTIAddUnlistedStudyForm.RequestStudies('dropdown')} >
                        <DropdownFormFieldComponent
                            name={'dataProviderOrgId'}
                            items={providers.map(({name, id}) => ({title: name, value: id}))}
                            selectText={'Select Provider'}
                            dataId={DTIDropdownMenu.DropdownMenuItem('Item')}
                        />
                    </div>
                </div>

                <div style={{width: '60%', display: 'flex', flexDirection: 'column'}}>
                    <FieldHeaderComponent
                        title={'Provide NCT or Sponsor ID of the study or the name of the tools or data'}/>
                    <TextFormFieldComponent
                        name={'nctOrSponsorProtocolId'}
                        dataId={DTICTFormFields.SponsorProtocolId}
                    />
                </div>
            </div>

            <div data-test-id={DTIAddUnlistedStudyForm.RequestStudies('textarea')}>
                <TextAreaFormFieldComponent
                    name={'studyTitle'}
                    label={'Provide the study title, or the description of the study, data, or tools'}
                    rows={6}
                    dataId={DTICTFormFields.Title}
                />
            </div>
        </div>
    )
}
