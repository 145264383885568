﻿import React, {CSSProperties, useEffect} from "react";
import {AmrDataRequestRoutesComponent} from './amr-data-request-routes.component';
import {RequestHeaderContainerComponent} from '../shared/request-header/request-header-container.component';
import {FormProvider} from 'react-hook-form';
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useAmrDataRequestForm} from "@vivli/features/data-requests/infrastructure/hook";
import {AmrDataRequestTabsContainerComponent} from "./amr-data-request-tabs/amr-data-request-tabs-container.component";

const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    height: 'calc(100% - 70px)',
    overflow: 'auto'
}

export const AmrDataRequestComponent = () => {
    const {
        dataRequest,
        handleEditTitleClick,
        handleFormSave,
        handleDataPackageCreation
    } = useDataRequestContext();
    const formApi =  useAmrDataRequestForm(dataRequest);

    useEffect(() => {
        // force initial validation
        formApi.trigger();

        handleDataPackageCreation(formApi);
    }, [])

    return (
        <FormProvider {...formApi}>
            <RequestHeaderContainerComponent
                onEditTitleClick={() => handleEditTitleClick(formApi)}
                onSaveClick={() => handleFormSave(formApi, false)}
                onSubmitClick={() => handleFormSave(formApi, true)}
                formHasErrors={!formApi.formState.isValid}
                formIsDirty={formApi.formState.isDirty}
                title={formApi.getValues('requestTitle')}
            />

            <div style={bodyContainerStyle}>
                <AmrDataRequestTabsContainerComponent />
                <AmrDataRequestRoutesComponent/>
            </div>
        </FormProvider>
    )
}
