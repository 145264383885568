import React, {CSSProperties} from 'react';
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {GetOrgIdResult} from "@vivli/features/data-requests/infrastructure/interface";
import {ApproveButtonComponent} from "../data-request/data-request-header/components/approve-button.component";
import {RejectButtonComponent} from "../data-request/data-request-header/components/reject-button.component";
import {SoftRejectButtonComponent} from "../data-request/data-request-header/components/soft-reject-button.component";
import {
    DuaValidationButtonComponent
} from "../data-request/data-request-header/components/dua-validation-button.component";
import {
    ResetToDraftWithTitleComponent
} from "../data-request/data-request-header/components/reset-to-draft-with-title.component";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 40,
}

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
}

const ApprovalStates: DataRequestStatusEnum[] = [
    DataRequestStatusEnum.AwaitingDataProviderApproval,
    DataRequestStatusEnum.AwaitingIRPApproval,
    DataRequestStatusEnum.Approved];

const RejectionStates: DataRequestStatusEnum[] = [
    DataRequestStatusEnum.RejectedByVivli,
    DataRequestStatusEnum.RejectedByIRP,
    DataRequestStatusEnum.RejectedByDataProvider];

const SoftRejectionStates: DataRequestStatusEnum[] = [
    DataRequestStatusEnum.RevisionsRequestedByIRP,
    DataRequestStatusEnum.RevisionsRequestedByDataProvider];

interface ApproveRejectButtonsComponentProps {
    hideApprove?: boolean;
}

export const ApproveRejectButtonsComponent = ({hideApprove}: ApproveRejectButtonsComponentProps) => {
    const {dataRequest, organizations} = useDataRequestContext();
    const {userCanApprove, userCanReject, canSoftReject} = useDataRequestPermissions();
    const modalService = useModalService();

    const isApproved = dataRequest.status === DataRequestStatusEnum.Approved;
    const isRejected = RejectionStates.some(rs => dataRequest.status === rs);
    const isSoftRejected = SoftRejectionStates.some(srs => dataRequest.status === srs);
    const isApprovedOrRejected = isApproved || isRejected || isSoftRejected;

    const getOrgId = (orgIds: string[]): Promise<GetOrgIdResult> => {
        return new Promise<GetOrgIdResult>((resolve, reject) => {
            let orgs: IOrganization[] = [];

            if (orgIds.length >= 1) {
                orgs = organizations.filter(o => orgIds.find(i => i === o.id) !== undefined);
            }

            if (orgs.length > 1) {
                modalService.confirm(
                    'You can approve this request on behalf of more than one organization. Select the organization for whom you want to approve.',
                    {
                        title: 'Organization',
                        dropdownListLabel: 'Select Organization',
                        dropdownListOptions: orgs.map(o => ({
                            value: o.id,
                            title: o.name
                        })),
                        showDropdownList: true,
                        onConfirm: result => {
                            if (!result.dropdownListSelection) {
                                reject();
                            }
                            resolve({ comment: result.comment, orgId: result.dropdownListSelection });
                        }
                    })
            } else if (orgs.length === 1) {
                resolve({ comment: '', orgId: orgs[0].id });
            }
            else{
                resolve(null);
            }
        });
    }

    if (!isApprovedOrRejected && userCanApprove(dataRequest)) {
        return (
            <div style={buttonContainerStyle}>
                {userCanReject(dataRequest) && (
                    <RejectButtonComponent getOrgId={getOrgId} />
                )}

                {canSoftReject(dataRequest) && (
                    <SoftRejectButtonComponent getOrgId={getOrgId} />
                )}

                {userCanApprove(dataRequest) && !hideApprove && (
                    <ApproveButtonComponent getOrgId={getOrgId} />
                )}
            </div>
        )
    } else if (isApprovedOrRejected) {
        return (
            <span style={containerStyle}>
                {isApproved && (
                    <DuaValidationButtonComponent/>
                )}

                {isSoftRejected && !isApproved && (
                    <ResetToDraftWithTitleComponent title={'Revision Requested'}/>
                )}

                {!isSoftRejected && !isApproved && (
                    <ResetToDraftWithTitleComponent title={'Not Approved'}/>
                )}
            </span>
        )
    }

    return null;
}
