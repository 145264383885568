﻿import {RestApi} from '@vivli/core/infrastructure/rest';
import {Observable} from 'rxjs';
import {IUtilityApiService} from './utility-api-service.interface';
import {IListingRequest} from '@vivli/features/listing-request/infrastructure/interface';

///this class is for cases in which the FrontEnd needs to make an
///api call of some sort directly to a BackEnd API - the calls in here
///can come from any Backend API
export class UtilityApiService extends RestApi implements IUtilityApiService {
  //method to be used with emails except for those about listing requests
  sendEmail = (objectToEmail: any, emailType: string): Observable<any> => {
    return this.handlePut<any>(`/email/${emailType}`, { objectToEmail });
  };

  //because ListingRequests need to be broken up currently, method for listing requests only
  sendEmailForListingRequest = (
    listingRequest: IListingRequest,
    emailType: string
  ): Observable<IListingRequest> => {
    const dataset = listingRequest.datasets[0];
    return this.handlePut<any>(`/email/listingRequest/${emailType}`, {
      listingRequest,
      dataset,
    });
  };
}
