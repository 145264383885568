﻿import {useForm} from 'react-hook-form';
import {joiResolver} from '@hookform/resolvers/joi';
import {JoiValidationOptionsConstant} from '@vivli/shared/infrastructure/constants';
import {IListingRequest, IListingRequester,} from '@vivli/features/listing-request/infrastructure/interface';
import joi, {ObjectSchema} from 'joi';
import {IStudy, IStudyResearchTeamMember,} from '@vivli/features/studies/infrastructure/interface';
import {orcidInputSchema} from '@vivli/shared/infrastructure/schema';

const requesterSchema: ObjectSchema<IListingRequester> = joi
    .object({
        institutionName: joi.string().required().label('Institution Name'),
    })
    .options(JoiValidationOptionsConstant);

const studyResearchTeamMembersSchema: ObjectSchema<IStudyResearchTeamMember> =
    joi
        .object({
            emailAddress: joi
                .string()
                .email({tlds: {allow: false}})
                .required()
                .label('Email Address'),
            givenName: joi.string().required().label('Given Name'),
            familyName: joi.string().required().label('Family Name'),
            orcidId: orcidInputSchema('ORCID iD'),
            creditRoles: joi.array().min(1).required().label('CRediT Role(s)'),
        })
        .options(JoiValidationOptionsConstant);

const datasetsSchema: ObjectSchema<IStudy> = joi
    .object({
        embargoDate: joi.when(joi.ref('/isDataEmbargoed'), {
            is: true,
            then: joi
                .date()
                .required()
                .max(new Date('12/31/2099'))
                .label('Embargo Date'),
        }),

        nctId: joi
            .string()
            .regex(/^NCT\d{8}$/)
            .required()
            .when('sponsorProtocolId',{
                is:joi.string().required().min(1),
                then:joi.string().allow('')
            })
            .label('NCT ID')
            .messages({
                'string.pattern.base':
                    '{{#label}} must be entered in the format NCT12345678.',
            }),
        sponsorProtocolId: joi
            .string()
            .allow('')
            .label('Sponsor Protocol Id'),

        funder: joi.string().required().label('Funder'),
        funderEmail: joi.when('funder', {
            is: joi.string().valid('N/A'),
            then: joi.optional(),
            otherwise: joi
                .string()
                .email({tlds: {allow: false}})
                .required()
                .label('Contact for Invoicing'),
        }),
        researchTeam: joi.array().items(studyResearchTeamMembersSchema),
    })
    .options(JoiValidationOptionsConstant);

const formSchema: ObjectSchema<IListingRequest> = joi
    .object({
        requester: requesterSchema,
        numberOfIntendedStudies: joi
            .number()
            .min(1)
            .required()
            .label('Intended Studies'),
        datasets: joi.array().items(datasetsSchema),
    })
    .options(JoiValidationOptionsConstant);

const defaultValues = (listingRequest) => ({
    ...listingRequest,
    datasetIds: listingRequest?.datasetIds || [],
    requester: listingRequest?.requester || {
        email: '',
        institutionName: '',
        userId: '',
    },
    numberOfIntendedStudies: listingRequest?.numberOfIntendedStudies || 1,
    hasBeenSigned: listingRequest?.hasBeenSigned || false,
    history: listingRequest?.history || [],
    status: listingRequest?.status || null,
    vivliOwner: listingRequest?.vivliOwner || '',
    datasets:
        listingRequest?.datasets?.length > 0
            ? listingRequest?.datasets
            : [defaultStudyValues(listingRequest?.datasets[0])],
    isDataEmbargoed: listingRequest?.isDataEmbargoed || false,
    canBeContacted: listingRequest?.canBeContacted || false,
    willNeedHelpAnonymizing: listingRequest?.willNeedHelpAnonymizing || false,
});

const defaultStudyValues = (study): IStudy => ({
    ...study,
    id: study?.id || null,
    embargoDate: study?.embargoDate || null,
    nctId: study?.nctId || '',
    sponsorProtocolId: study?.sponsorProtocolId || '',
    extractedInterventions: study?.extractedInterventions || [],
    extractedConditions: study?.extractedConditions || [],
    funder: study?.funder || 'N/A',
    funderEmail: study?.funderEmail || '',
    grant: study?.grant || '',
    additionalInformation: study?.additionalInformation || '',
    orgName: study?.orgName || '',
    orgId: study?.orgId || '',
    orgCode: study?.orgCode || '',
    researchTeam: study?.researchTeam || undefined,
});

export function useListingRequestFormHook(
    listingRequest?: IListingRequest,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all'
) {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: defaultValues(listingRequest),
        reValidateMode: 'onChange',
    });
}
