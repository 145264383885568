import {Observable} from 'rxjs';
import {RestApi} from '@vivli/core/infrastructure/rest';
import {IResultRequest, IResultRequestApiService} from "@vivli/features/results-request/infrastructure/interface";
import {ResultsRequestStatusEnum} from "@vivli/features/results-request/infrastructure/enum";

export class ResultRequestApiService extends RestApi implements IResultRequestApiService {
    getResultRequests = (dataRequestId: string): Observable<IResultRequest[]> => {
        return this.handleGet<IResultRequest[]>(`/dataRequests/${dataRequestId}/resultsRequests`);
    }

    createResultRequest = (dataRequestId: string): Observable<IResultRequest> => {
        return this.handlePost<IResultRequest>(`/dataRequests/${dataRequestId}/researchEnvironment/CreateNewResultRequest`);
    }

    updateResultRequestStatus = (dataRequestId: string, resultsRequestId: string, status: ResultsRequestStatusEnum): Observable<IResultRequest> => {
        return this.handlePost<IResultRequest>(`/dataRequests/${dataRequestId}/researchEnvironment/${resultsRequestId}/UpdateResultsRequest/${status}`)
    }

    approveResultRequest = (dataRequestId: string, resultsRequestId: string): Observable<IResultRequest> => {
        return this.handlePost<IResultRequest>(`/dataRequests/${dataRequestId}/resultsRequests/${resultsRequestId}/approve`);
    }

    disapproveResultRequest = (dataRequestId: string, resultsRequestId: string, comment?: string): Observable<IResultRequest> => {
        return this.handlePost<IResultRequest>(`/dataRequests/${dataRequestId}/resultsRequests/${resultsRequestId}/disapprove`, comment);
    }
}
