import React, {ReactNode, useState} from "react";
import {AdminConsoleContext, useAdminConsoleService} from "@vivli/features/admin-console/infrastructure/context";
import {first} from "rxjs/operators";
import {useModalService, useToastService} from "@vivli/shared/infrastructure/context";
import {ILoggingAreas, IProvisionAdminOptions} from "@vivli/features/admin-console/infrastructure/interface";

interface AdminConsoleContextWrapperProps {
    children: ReactNode;
}

export const AdminConsoleContextWrapper = ({children}: AdminConsoleContextWrapperProps) => {

    const modalService = useModalService();
    const toastService = useToastService();
    const adminConsoleService = useAdminConsoleService();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isUpdating, setIsUpdating] = useState<boolean>();
    const [loggingData, setLoggingData] = useState<ILoggingAreas[]>([]);

    const handleAction = (action: string, id: string, data: string, isAsync: boolean = false) => {
        modalService.confirm(`Please comment and confirm selected action - ${action}.`, {
            title: "Action Confirmation.",
            showTextAreaPrompt: true,
            requireComment: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            style: {minWidth: '45em', textAlign: 'left'},
            messageStyle: {textAlign: 'left'},
            onConfirm: ({comment}) => {
                setIsLoading(true);
                adminConsoleService.performAction(action, id, data, comment)
                    .pipe(first())
                    .subscribe(() => {
                            setIsLoading(false);
                            modalService.message(isAsync ? `Action ${action} has started. It may take some time to complete.` : `Action ${action} succeded.`,
                                {
                                    title: "Action Success",
                                    style: {
                                        minWidth: '45em', textAlign: 'left',
                                        backgroundColor: 'rgb(5, 61, 88)',
                                        borderRadius: '15px', color: "white"
                                    },
                                    showContinueButton: true,
                                    messageStyle: {textAlign: 'left', color: "white"},

                                });
                        },
                        handleError
                    );
            }
        });

    }
    const provisionVm = (id: string, options: IProvisionAdminOptions, callback: () => void) => {
        modalService.confirm(`Please comment and confirm VM Provisioning.`, {
            title: "Action Confirmation.",
            showTextAreaPrompt: true,
            requireComment: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            style: {minWidth: '45em', textAlign: 'left'},
            messageStyle: {textAlign: 'left'},
            onConfirm: ({comment}) => {
                setIsLoading(true);
                options.comment = comment;
                adminConsoleService.provisionVm(id, options)
                    .pipe(first())
                    .subscribe(() => {
                            setIsLoading(false);
                            callback();//start polling
                            modalService.message(`VM Provisioning has started. It will take some time to complete.`,
                                {
                                    title: "Action Success",
                                    style: {
                                        minWidth: '45em', textAlign: 'left',
                                        backgroundColor: 'rgb(5, 61, 88)',
                                        borderRadius: '15px', color: "white"
                                    },
                                    showContinueButton: true,
                                    messageStyle: {textAlign: 'left', color: "white"},

                                });
                        },
                        handleError
                    );
            }
        });
    }

    const getLoggingDetails = () => {
        setIsLoading(true)
        adminConsoleService.getLoggingDetails().pipe(first())
            .subscribe((value) => {
                    setLoggingData(value)
                    setIsLoading(false)
                },
                handleError)
    }

    const handleLoggingDropdownChange = (index: number, value: string) => {
        const updatedData = [...loggingData];
        updatedData[index].valueField = value;
        setLoggingData(updatedData);
    };

    const handleLoggingUpdate = () => {
        setIsUpdating(true);
        adminConsoleService.updateLoggingDetails(loggingData).pipe(first())
            .subscribe(() => {
                    toastService.success(`Update Successful.`);
                    setIsUpdating(false)
                },
                handleError)
    }


    const handleError = (err: string) => {
        setIsLoading(false);
        setIsUpdating(false)
        modalService.error(`An error occurred with your operation. Please try again or contact Vivli support: ${err}`);
    }
    const provider = {
        handleAction,
        isLoading,
        provisionVm,
        loggingData,
        getLoggingDetails,
        handleLoggingDropdownChange,
        handleLoggingUpdate,
        isUpdating
    }
    return <AdminConsoleContext.Provider value={provider}>
        {children}
    </AdminConsoleContext.Provider>

}
