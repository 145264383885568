﻿import React, {CSSProperties, useEffect, useState} from "react";
import {
    AdminHeaderComponent,
    AdminHeaderTitleComponent,
    ButtonComponent,
    FieldHeaderComponent,
    GoBackLinkComponent,
    LoadIndicatorCenteredComponent
} from "@vivli/shared/components";
import {Size, Styles} from "@vivli/shared/theme";
import {BulkUpdateContentTypeEnum} from "@vivli/features/studies/infrastructure/enum";
import {DocumentsDataPackageFeature} from "@vivli/shared/features/data-package/feature";
import {useOrganizationsService} from "@vivli/features/organizations/infrastructure/context";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {first} from "rxjs/operators";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {IStudyBulkUpdateSpecifier} from "@vivli/features/studies/infrastructure/interface";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {BulkUpdateStudiesSelectorsComponent} from "@vivli/features/studies/components";
import {useNavigate} from "react-router-dom";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: '100%'
}
const innerContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    flex: 'auto',
}
const goBackStyle: CSSProperties = {
    justifyContent: 'flex-start',
    marginBottom: 10
}

export const BulkUpdateStudiesFeature = () => {
    const user = useActiveUser();
    const {organizations} = useOrganizations();
    const organizationService = useOrganizationsService();
    const studiesService = useStudiesService();
    const modalService = useModalService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [bulkUploadId, setBulkUploadId] = useState<string>(null);
    const [bulkUpdateContentType, setBulkUpdateContentType] = useState(null);
    const [currentOrg, setCurrentOrg] = useState<IOrganization>(null);

    const modalMessage = 'Studies from the specified sheet are being updated in the Vivli system. You will receive an email when the process is complete. You will now be taken to the Draft Studies section.';

    // GET ORG WHERE I AM DATA CONTRIBUTOR
    let myOrganizations = user?.orgMemberships.filter(om => om.isDataProvider) || [];
    if (myOrganizations.length === 0) {
        myOrganizations = user?.orgMemberships.filter(om => om.isOrgAdmin);
    }

    const handleError = (error) => {
        modalService.error(error);
        setIsLoading(false);
    }

    const getDraftUpdateId = () => {
        organizationService.getDraftBulkUploadId(currentOrg?.id, isAmr)
            .pipe(first())
            .subscribe((id) => {
                setBulkUploadId(id);
            }, handleError)
    }

    const handleDropdownChange = (e) => {
        setBulkUpdateContentType(e);
    }

    const processUpdateSheet = () => {
        setIsLoading(true);
        const uploadSpecifier: IStudyBulkUpdateSpecifier = {
            dataPackageId: bulkUploadId,
            bulkUpdateContentType: BulkUpdateContentTypeEnum[bulkUpdateContentType],
        };
        modalService.message(modalMessage);
        studiesService.bulkUpdateStudies(uploadSpecifier)
            .pipe(first())
            .subscribe(() => {
                setIsLoading(false);
                navigate(`/admin/studies`);
            }, handleError);
    }

    useEffect(() => {
        if (!organizations){
            return;
        }

        const [myOrgRole] = myOrganizations;
        setCurrentOrg(organizations?.find(o => o.id === myOrgRole?.orgId));
    }, [organizations])

    useEffect(() => {
        if(!currentOrg){
            return;
        }

        getDraftUpdateId();
    }, [currentOrg])

    if (!organizations){
        return <LoadIndicatorCenteredComponent />
    }

    return (
        <div style={containerStyle} className={'scrolly'}>
            <AdminHeaderComponent>
                <div style={{flexDirection: 'column'}}>
                    <GoBackLinkComponent style={goBackStyle}/>
                    <AdminHeaderTitleComponent title={'Bulk Update Studies'}/>
                </div>
            </AdminHeaderComponent>
            <div style={innerContainerStyle}>
                <BulkUpdateStudiesSelectorsComponent onDropdownChange={handleDropdownChange} />
            </div>
            <div style={innerContainerStyle}>
                <FieldHeaderComponent title='UPDATE CSV FILE TO UPLOAD'/>
                {bulkUploadId ?
                    <DocumentsDataPackageFeature
                        dataPackageId={bulkUploadId}
                        useSecureStorage={false}
                        availableTypes={[]}
                    />:
                    <LoadIndicatorCenteredComponent />
                }
            </div>
            {user.isVivliAdmin &&
                <div style={{paddingBottom: 100}}>
                    <ButtonComponent
                        isLoading={isLoading}
                        style={Styles.Button.BUTTON_VIVLI_BLUE}
                        onClick={processUpdateSheet}
                        className='bulkUpload_processSheet'
                    >
                        Process Update Sheet
                    </ButtonComponent>
                </div>
            }
        </div>
    )
}
