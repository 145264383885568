﻿import React, {CSSProperties} from "react";

interface AdminStudyDatasetStatusDisplayComponentProps {
    usedInList?: string;
}

export const AdminStudyDatasetStatusDisplayComponent = ({
    usedInList,
}: AdminStudyDatasetStatusDisplayComponentProps) => {

    const usedInStyle: CSSProperties = {
            fontWeight: 'bold',
            paddingTop: 4
    }

    const requestListContainerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '10px'
    }

    const requestListStyle: CSSProperties = {
        marginLeft: 10,
        fontSize: 12,
        overflow: 'auto',
        marginTop: '10px'
    }
    const requestListDisplayStyle :CSSProperties= {
        marginTop: '12px',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '12px 5px 12px 20px',
    };

    const listToDisplay : string[] = usedInList.split(',');
    const hasRequests = (usedInList && usedInList !== '');

    return (
        <div>
            <div style={usedInStyle}>
                {hasRequests &&
                    <div style={requestListContainerStyle}>
                            <div>Requests List:</div>
                            <div style={requestListStyle}>
                                {listToDisplay?.map((s, index) => (
                                    <div style={requestListDisplayStyle} key={index}>
                                        {s}
                                    </div>))}
                            </div>
                    </div>
                }
            </div>
        </div>
    );

}
