import React from 'react';
import { LoadIndicatorComponent } from '@vivli/shared/components';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

export const DataPackageLoaderComponent = () => (
    <LoadIndicatorComponent
        style={{ width: '100%', minHeight: '200px' }}
        size={LoadIndicatorSizeEnum.Medium}
    />
);
