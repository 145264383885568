﻿import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import React, {useEffect, useState} from "react";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {BulkUploadStudiesFormComponent} from "@vivli/features/studies/components";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useOrganizationsService} from "@vivli/features/organizations/infrastructure/context";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {first} from "rxjs/operators";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {useModalService} from "@vivli/shared/infrastructure/context";

export const BulkUploadStudiesFeature = () => {
    const user = useActiveUser();
    const {organizations} = useOrganizations();
    const organizationService = useOrganizationsService();
    const modalService = useModalService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const [currentOrg, setCurrentOrg] = useState<IOrganization>(null);
    const [bulkUploadId, setBulkUploadId] = useState<string>(null);
    let myOrganizations = user?.orgMemberships.filter(om => om.isDataProvider) || [];
    if (myOrganizations.length === 0) {
        myOrganizations = user?.orgMemberships.filter(om => om.isOrgAdmin);
    }

    const getDraftUpdateId = () => {
        organizationService.getDraftBulkUploadId(currentOrg?.id, isAmr)
            .pipe(first())
            .subscribe((id) => {
                setBulkUploadId(id);
            }, (error) => modalService.error(error))
    }

    useEffect(() => {
        if (!organizations) {
            return;
        }

        const [myOrgRole] = myOrganizations;
        setCurrentOrg(organizations?.find(o => o.id === myOrgRole?.orgId));
    }, [organizations])

    useEffect(() => {
        getDraftUpdateId();
    }, [currentOrg])

    if (!organizations) {
        return <LoadIndicatorCenteredComponent/>
    }

    return (
        <BulkUploadStudiesFormComponent bulkUploadId={bulkUploadId}/>
    )
}
