import React, { useEffect, useState } from 'react';
import { IDataRequestsByType, IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { DataRequestsPageComponent } from '@vivli/features/data-requests/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ITab, IUser } from '@vivli/shared/infrastructure/interface';
import { DataRequestFilterService } from '@vivli/features/data-requests/infrastructure/service';
import { useDataRequestDefaultTabHook, useDataRequestSummaries } from '@vivli/features/data-requests/infrastructure/hook';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';

enum TabTitleEnum {
    awaitingMyAction = 'Awaiting My Action',
    draft = 'Draft',
    active = 'Active',
    notApproved = 'Not Approved',
    withdrawn = 'Withdrawn',
    archived = 'Archived',
}

enum TabRouteEnum {
    archived = 'archived',
    withdrawn = 'withdrawn',
    notApproved = 'notapproved',
    active = 'active',
    draft = 'draft',
    awaitingMyAction = 'awaitingmyaction',
}

const getTabs = (user: IUser, requestsByType?: IDataRequestsByType) => {
    const defaultTabs: ITab<IDataRequestSummary[]>[] = [
        {
            id: TabRouteEnum.awaitingMyAction,
            sortOrder: 0,
            title: TabTitleEnum.awaitingMyAction,
            data: requestsByType?.awaitingUserAction,
            count: requestsByType?.awaitingUserAction?.length,
        },
        {
            id: TabRouteEnum.draft,
            sortOrder: 1,
            title: TabTitleEnum.draft,
            hint: user.isVivliAdmin && 'Requests that have not been submitted yet',
            data: requestsByType?.draftRequests,
            count: requestsByType?.draftRequests?.length,
        },
        {
            id: TabRouteEnum.active,
            sortOrder: 2,
            title: TabTitleEnum.active,
            // hint: user.isVivliAdmin ? 'Requests awaiting approvals or IPD upload' :
            //     'Requests awaiting approvals or Data Package upload',
            data: requestsByType?.active,
            count: requestsByType?.active?.length,
        },
        {
            id: TabRouteEnum.notApproved,
            sortOrder: 4,
            title: TabTitleEnum.notApproved,
            data: requestsByType?.notApproved,
            count: requestsByType?.notApproved?.length,
        },
        {
            id: TabRouteEnum.withdrawn,
            sortOrder: 5,
            title: TabTitleEnum.withdrawn,
            data: requestsByType?.withdrawn,
            count: requestsByType?.withdrawn?.length,
        },
        {
            id: TabRouteEnum.archived,
            sortOrder: 6,
            title: TabTitleEnum.archived,
            data: requestsByType?.archived,
            count: requestsByType?.archived?.length,
        },
    ];

    return defaultTabs.sort((a, b) => a.sortOrder - b.sortOrder);
};

export const DataRequestsFeature = () => {
    const [subHeader, setSubHeader] = useState<string>();
    const [tabs, setTabs] = useState<ITab<IDataRequestSummary[]>[]>();
    const [defaultTab, setDefaultTab] = useState<string>();
    const user = useActiveUser();
    const { dataRequestSummaries, isLoadingDataRequestSummaries } = useDataRequestSummaries();
    const { getDataRequest } = useDataRequestsService();
    const navigate = useNavigate();
    const { organizations } = useOrganizations();
    const { getDefaultTabRoute } = useDataRequestDefaultTabHook();

    const initDefaultTab = () => {
        const path = window.location.pathname;
        const tabRoute = path.replace('/admin/requests/', '').toLowerCase();
        setDefaultTab(tabRoute);
    };

    const handleRowClick = (dataRequestSummary: IDataRequestSummary) => {
        getDataRequest(dataRequestSummary.id).subscribe({
            next: (dataRequest) => {
                const defaultRoute = getDefaultTabRoute(dataRequest, organizations);
                navigate(defaultRoute);
            },
        });
    };

    const handleTabClick = (tab: ITab<IDataRequestSummary[]>) => {
        navigate(`/admin/requests/${tab.id}`);
    };

    useEffect(() => {
        initDefaultTab();
        setTabs(getTabs(user));
    }, []);

    useEffect(() => {
        if (!user || !user.orgMemberships) {
            return;
        }

        const subHeaderStr = user.orgMemberships.some((om) => om.isOrgAdmin)
            ? 'For data provided by my organizations'
            : 'For data provided by my organization';

        setSubHeader(subHeaderStr);
    }, [user]);

    useEffect(() => {
        if (!dataRequestSummaries) {
            return;
        }

        const requestsByType = DataRequestFilterService.filterAllRequestsByType(dataRequestSummaries, user);
        const updatedTabs = getTabs(user, requestsByType);

        setTabs(updatedTabs);
    }, [dataRequestSummaries]);

    return (
        <DataRequestsPageComponent
            title="Research Data Requests"
            subHeader={subHeader}
            tabs={tabs}
            defaultTab={defaultTab}
            showExport={true}
            onRowClick={handleRowClick}
            onTabClick={handleTabClick}
            hideFloatingFilter={true}
            dataRefreshing={isLoadingDataRequestSummaries}
        />
    );
};
