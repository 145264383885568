import React from "react";

export const badgeStyle: React.CSSProperties = {
    boxShadow: 'none',
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
}
