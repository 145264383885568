﻿import React from "react";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {
    innerFormContainerStyle,
    outerFormContainerStyle,
    rightHeaderContainerStyle
} from "@vivli/shared/components";
import {ListingRequestAdminHeaderButtonsComponent} from "./listing-request-admin-header-buttons.component";
import {ListingRequestHeaderButtonsComponent} from "./listing-request-header-buttons.component";
import {ListingRequestLeftHeaderComponent} from "./listing-request-left-header.component";

export const ListingRequestHeaderComponent = () => {
    const user = useActiveUser();
    const headerButtons = user.isVivliAdmin
        ? <ListingRequestAdminHeaderButtonsComponent />
        : <ListingRequestHeaderButtonsComponent />;

    return (
        <div style={outerFormContainerStyle} className='do-not-print'>
            <div style={innerFormContainerStyle}>
                <ListingRequestLeftHeaderComponent />

                <div style={rightHeaderContainerStyle}>
                    {headerButtons}
                </div>
            </div>
        </div>
    )
}
