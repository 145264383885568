import React, { CSSProperties } from 'react';
import { FooterComponent } from './footer.component';
import { HeaderComponent } from './header.component';
import { usePortalConfigHook } from '@vivli/shared/infrastructure/hook';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useLocation, useNavigate } from 'react-router-dom';

const bodyStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    zIndex: 1,
};

export const LayoutWrapper = ({ children }: IContextWrapper) => {
    const navigate = useNavigate();
    const { getSearchLink } = usePortalConfigHook();
    const location = useLocation();

    // only show header/footer if we are not using the virtual machine route
    const showHeaderFooter = !location.pathname.includes('virtual-machine');

    const handleFindStudiesClick = () => {
        const link = getSearchLink();
        navigate(link);
    };

    const containerStyle: CSSProperties = {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: showHeaderFooter ? 'auto 1fr auto' : '1fr',
    };

    return (
        <div style={containerStyle}>
            {showHeaderFooter && <HeaderComponent onFindStudiesClick={handleFindStudiesClick} />}
            <div style={bodyStyle}>{children}</div>
            {showHeaderFooter && <FooterComponent />}
        </div>
    );
};
