import {
    ButtonComponent,
    DatePickerComponent,
    DropdownFieldComponent,
    TextFieldComponent
} from '@vivli/shared/components';
import React, {BaseSyntheticEvent, CSSProperties, useEffect, useState} from 'react';
import {AdminConsoleConstant} from "@vivli/shared/infrastructure/constants";
import {useAdminConsoleService} from "@vivli/features/admin-console/infrastructure/context";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {first} from "rxjs/operators";
import {downloadFile} from '@vivli/shared/infrastructure/hook'

/* eslint-disable-next-line */

const marginLeftStyle = {
    marginLeft: 10,
}
const templateSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '74%',
}
const statusSectionStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '75%'
}
const buttonStyle: CSSProperties = {
    marginLeft: 15,
}
const width20Style: CSSProperties = {
    width: '20%',
}

export function AdminAuditTrailComponent() {
    const dropDownTypeOfValue = AdminConsoleConstant.typeOfLables.typeOfLable;
    const [fromDateRange, setFromDateRange] = useState<Date>(null);
    const [toDateRange, setToDateRange] = useState<Date>(null);
    const [auditType, setAuditType] = useState<string>(null);
    const [auditId, setAuditId] = useState<string>(null);
    const [comment, setComment] = useState<string>(null);
    const [maxDate, setMaxDate] = useState<Date>(new Date());
    const [minDate, setMinDate] = useState<Date>(null);
    const adminConsoleService = useAdminConsoleService();
    const modalService = useModalService();

    const handleSetValidMaxForStart = () => {
        if (toDateRange) {
            setMaxDate(new Date(toDateRange));
        } else {
            setMaxDate(new Date());
        }
    }

    const handleSetValidMinForEnd = () => {
        if (fromDateRange) {
            setMinDate(new Date(fromDateRange));
        } else {
            setMinDate(null);
        }
    }
    const handleStartDate = (e) => {
        setFromDateRange(new Date(e));
    }

    const handleEndDate = (e) => {
        setToDateRange(new Date(e));
    }

    useEffect(() => {
        if (fromDateRange !== minDate) {
            handleSetValidMinForEnd();
        }
    }, [fromDateRange])

    useEffect(() => {
        if (toDateRange !== maxDate) {
            handleSetValidMaxForStart();
        }
    }, [toDateRange])
    const handleError = (error) => {
        modalService.error(error);
    };

    const handleId = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setAuditId(e.target.value);
    }

    const handleComment = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setComment(e.target.value);
    }

    const onDownload = () => {
        let id = auditId;
        if ((auditType === 'VM' || auditType == 'DataRequest') && (auditId) && auditId.length < 8) {
            setAuditId(auditId.padStart(8, '0'));
            id = id.padStart(8, '0');
        }
        adminConsoleService.downloadAudit(fromDateRange, toDateRange, auditType, id, comment)
            .pipe(first())
            .subscribe((data) => {
                downloadFile('data:application/json;charset=utf-8,' + encodeURIComponent(data.substr(1, data.length - 2))
                    , `auditTrail${Date.now().toString()}.json`);
            }, handleError);

    }

    return (
        <div>
            <h5>Audit Trail</h5>
            <div style={templateSectionStyle}>
                <span>Download audit entries from:</span>
                <DatePickerComponent
                    label={'From'} style={marginLeftStyle}
                    onChange={setFromDateRange}
                    onBlur={(e) => handleStartDate(e)}
                    maxDate={maxDate}
                    value={fromDateRange}
                    isClearable={true}
                />
                <span style={marginLeftStyle}>Through:</span>
                <DatePickerComponent
                    label={'To'} style={marginLeftStyle}
                    onChange={setToDateRange}
                    onBlur={(e) => handleEndDate(e)}
                    minDate={minDate}
                    value={toDateRange}
                    isClearable={true}
                />
                <ButtonComponent style={buttonStyle} onClick={onDownload}>
                    Download
                </ButtonComponent>
            </div>
            <div>
                <p style={statusSectionStyle}>
                    <span style={width20Style}> Of type: </span>
                    <DropdownFieldComponent items={dropDownTypeOfValue} label={'Type'} style={buttonStyle}
                                            onChange={setAuditType}/>
                </p>
                <p style={statusSectionStyle}>
                    <span style={width20Style}>With ID: </span>
                    <TextFieldComponent onChange={handleId} label={'Study or Data Request Id'} style={marginLeftStyle}/>
                    <span style={marginLeftStyle}>Study ID or Data Request ID</span>
                </p>
                <p style={statusSectionStyle}>
                    <span style={width20Style}>Comment Contains: </span>
                    <TextFieldComponent onChange={handleComment} label={'Comment Part'} style={marginLeftStyle}/>
                </p>
            </div>
        </div>
    );
}
