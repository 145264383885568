﻿import React, {useEffect} from "react";
import {AdminHeaderButtonComponent} from "@vivli/shared/components";
import {useListingRequestContext} from "@vivli/features/listing-request/infrastructure/context";
import {ListingRequestStatusEnum} from "@vivli/features/listing-request/infrastructure/enum";
import {useFormContext} from "react-hook-form";
import {IListingRequest} from "@vivli/features/listing-request/infrastructure/interface";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

export const ListingRequestAdminHeaderButtonsComponent = () => {
    const {
        listingRequest,
        isLoading,
        isReadOnly,
        setIsReadOnly,
        handleFormSave,
        handleStatusUpdate,
    } = useListingRequestContext();
    const formApi = useFormContext<IListingRequest>();
    const modalService = useModalService();
    const status = listingRequest?.status ? ListingRequestStatusEnum[listingRequest?.status] : null;
    const isDraft = status ? status === ListingRequestStatusEnum.Draft : false;
    const isSubmitted = status ? status === ListingRequestStatusEnum.Submitted : false;
    const isAccepted = status ? status === ListingRequestStatusEnum.StudyInCuration : false;
    const isWithdrawn = status ? status === ListingRequestStatusEnum.Withdrawn : false;
    const showWithdraw = !isWithdrawn && listingRequest?.id;
    const showResetToDraft = !isDraft && !isWithdrawn;
    const urlPathName = location.pathname.split('/')[3];
    const showEdit = !isWithdrawn && isReadOnly;

    const handleWithdraw = () => {
        //If the request has been approved (even if reset to draft) => modal will say that the withdraw is happening but will not delete the study
        //otherwise modal will warn that it is physically removing the study
        const modalMessage = listingRequest.approvedDate != null
            ? "Continuing with the Withdraw will mark the submission withdrawn, but the study will remain posted - un-post the study separately if appropriate."
            : "Continuing with the Withdraw will mark the submission withdrawn, and will delete the temporary study that was created.";
        modalService.confirm(modalMessage, {
            title: 'Confirm Withdraw?',
            cancelText: "Cancel",
            confirmText: "OK",
            onConfirm: () => {
                handleStatusUpdate(listingRequest, ListingRequestStatusEnum.Withdrawn)
            }
        });
    }

    const handleStatusSave = () => {
        setIsReadOnly(urlPathName !== 'StatusUpdate');
        handleFormSave(formApi, false);
    }
    const handleSave = () => {
        handleStatusSave();
    }

    useEffect(() => {
        handleStatusSave();
    },[urlPathName])

    return (
        <>
            {showResetToDraft &&
                <AdminHeaderButtonComponent
                    dataId={DTICommonConst.ResetButton}
                    style={{marginRight: 10}}
                    title={'Reset to Draft'}
                    isLoading={isLoading}
                    isDisabled={isLoading || !isReadOnly}
                    onClick={() => handleStatusUpdate(listingRequest, ListingRequestStatusEnum.Draft)}
                />
            }
            {showWithdraw &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Withdraw'}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={handleWithdraw}
                />
            }
            {showEdit &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Edit'}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={() => setIsReadOnly(false)}
                />
            }
            {!isReadOnly &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Save'}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={handleSave}
                />
            }
            {isDraft &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Submit'}
                    isLoading={isLoading}
                    isDisabled={isLoading || !formApi.formState.isValid}
                    onClick={() => handleFormSave(formApi, true, ListingRequestStatusEnum.Submitted)}
                />
            }
            {isSubmitted &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Accept'}
                    isLoading={isLoading}
                    isDisabled={isLoading || !isReadOnly || !formApi.formState.isValid}
                    onClick={() => handleStatusUpdate(listingRequest, ListingRequestStatusEnum.StudyInCuration)}
                />
            }
            {isAccepted &&
                <AdminHeaderButtonComponent
                    style={{marginRight: 10}}
                    title={'Approve/Post'}
                    isLoading={isLoading}
                    isDisabled={isLoading || !isReadOnly || !formApi.formState.isValid}
                    onClick={() => handleStatusUpdate(listingRequest, ListingRequestStatusEnum.Approved)}
                />
            }
        </>
    )
}
