import React from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext
} from "@vivli/shared/features/data-package/components";

interface OtherInformationDataPackageFeatureProps {
    dataPackageId: string;
    onValidation?: (valid: boolean) => void;
}

export const OtherInformationDataPackageFeature = (
    {
        dataPackageId,
        onValidation
    }: OtherInformationDataPackageFeatureProps
) => (
    <DataPackageBaseComponent dataPackageId={dataPackageId}>
        <DataPackageContext.Consumer>
            {dataPackage => <DataPackageComponent
                dataPackage={dataPackage}
                readOnly={false}
                allowDownload={false}
                allowUpload={true}
                allowDelete={true}
                allowSubmit={false}
                availableTypes={[
                    'Research Proposal Supplement',
                    'Funding Information',
                    'Statistical Analysis Plan',
                    'Other',
                    'Unknown'
                ]}
                onValidation={onValidation}
            />}
        </DataPackageContext.Consumer>
    </DataPackageBaseComponent>
)

