import React from 'react';
import {Styles} from "@vivli/shared/theme";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useAuthService} from "@vivli/core/authentication";
import {DTISignupLoginButton} from "@vivli/shared/infrastructure/constants";

export const SignupLoginButtonComponent = () => {
    const {signupEnabled} = useConfigService();
    const {signUp, login} = useAuthService();

    return (
        <div>
            {signupEnabled && (
                <div
                    onClick={() => signUp()}
                    style={{ ...Styles.HEADER_LINK }}
                    className='header_signup'
                    data-test-id={DTISignupLoginButton.HeaderSignup}
                >
                    Sign up
                </div>
            )}

            <div
                onClick={() => login()}
                style={{ ...Styles.HEADER_LINK}}
                className='header_login'
                data-test-id={DTISignupLoginButton.HeaderLogin}
            >
                Log In
            </div>
        </div>
    )
}
