import React from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { buttonIconStyle, buttonStyle } from './button.styles';
import {AssetsConstant, DTISearchButton} from '@vivli/shared/infrastructure/constants';

export const SearchButtonComponent = ({ onClick }) => {

    return(
        <ButtonComponent
            // disabled={!canExecuteSearch}
            style={buttonStyle}
            onClick={onClick}
            className="search_go"
            dataId={DTISearchButton.SearchGo}
        >
            <img
                src={AssetsConstant.WHITE_MAGNIFYING_GLASS}
                style={buttonIconStyle}
            />
        </ButtonComponent>
    );
}
