import {ResearchTeamMemberRoleEnum, VmAdminApprovalStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {v4 as uuid} from 'uuid';

export function useResearchTeamCreate() {
    return (projectRole: ResearchTeamMemberRoleEnum) => ({
        id: uuid(),
        userId: '',
        invitiationId: '',
        firstName: '',
        lastName: '',
        position: '',
        educationOrQualifications: '',
        employerOrAffiliation: '',
        orcidId: '',
        governmentOfficialIdentificationDisclosure: '',
        vivliId: '',
        projectRole,
        conflictsOfInterest: '',
        email: '',
        hasBeenInvitedToVMAccess: false,
        hasAccessToVirtualMachineResource: false,
        inviteToVirtualMachine: false,
        isStatistician: projectRole === ResearchTeamMemberRoleEnum.Statistician,
        isLeadResearcher: projectRole === ResearchTeamMemberRoleEnum.Lead,
        isAdmin: false,
        adminApprovalStatus: VmAdminApprovalStatusEnum.NotApplicable, //default
    })
}
