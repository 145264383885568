import {useEffect, useState} from "react";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {IDatasetUsageData} from "@vivli/features/datasets/infrastructure/interface";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";


 export function useDataSetUsage(studyMetadataDoi: string, studyId: string, studyPostedDate: Date) {
        const [usageData, setUsageData] = useState<IDatasetUsageData>();
        const config = useConfigService();
        const studiesService = useStudiesService();


        useEffect(() => {

            if (config.irusCountOn && studyMetadataDoi  && (!usageData || usageData.studyId != studyId)) {
                //we go for a new data only if the data for this study were not returned already
                studiesService.getDataCountUsage(studyId, studyPostedDate)
                    .subscribe((data)=> {
                            data.studyId=studyId;
                            setUsageData(data);

                        }
                    );
            }
        }, [studyMetadataDoi, studyId, studyPostedDate])

        return {
            usageData,
            setUsageData
        }
    }

