import React, {CSSProperties} from "react";
import {CitationsContextWrapper,} from "@vivli/shared/features/citations/infrastructure/wrappers";
import {CitationsContainerComponent} from "@vivli/shared/features/citations/components";
import {Styles} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
};

/*object id  is the document id of the data request or study that "owns" the citation*/
export const CitationsFeature = ({objectId, isStudy, doUpdate, doiStem}: { objectId: string, isStudy: boolean, doUpdate: boolean, doiStem?: string}) => {
    return (
        <div style={containerStyle}>
            <CitationsContextWrapper objectId={objectId} isStudy={isStudy} doUpdate={doUpdate}>
                    <div>
                        {!isStudy &&
                            <div style={Styles.FORM_ROW}>
                                Current Citations
                            </div>
                        }
                        <div>
                            <CitationsContainerComponent objectId={objectId} isStudy={isStudy} doUpdate={false} dataRequestDoiStem={doiStem}/>
                        </div>
                    </div>
            </CitationsContextWrapper>
        </div>
    )
}


