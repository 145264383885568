﻿import React from "react";
import {AmrDataRequestTabsComponent} from "./amr-data-request-tabs.component";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useDataRequestAdminPath, useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {useActiveUser} from "@vivli/core/infrastructure/context";

export const AmrDataRequestDcTabsComponent = () => {
    const {dataRequest, organizations} = useDataRequestContext();
    const {shouldHideReviewers} = useDataRequestPermissions();
    const isDataRequestsView = useDataRequestAdminPath();
    const activeUser = useActiveUser();
    const isOrgAdmin = !activeUser.isVivliAdmin && activeUser?.orgMemberships?.find(om => om.isOrgAdmin) !== undefined;
    const isDraft = dataRequest.status === DataRequestStatusEnum.Draft
    const hideReviewers = shouldHideReviewers(dataRequest, organizations);
    const showRequestTab = isDraft && !isDataRequestsView;
    const showPrintTab = !hideReviewers && (isDataRequestsView || (!isDraft && !isDataRequestsView));

    return (
        <AmrDataRequestTabsComponent
            showRequestTab={showRequestTab}
            showPrintTab={showPrintTab}
            showRequestPrintTab={false} //admin only
            showStatusUpdateTab={isOrgAdmin}
        />
    )
}
