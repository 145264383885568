﻿import React from "react";
import {Styles} from "@vivli/shared/theme";
import {FieldHeaderComponent, fieldHeaderStyle} from "@vivli/shared/components";
import {PicoPopulationComponent} from "./pico-population.component";
import {PicoArmsComponent} from "./pico-arms.component";
import {PicoOutcomesComponent} from "./pico-outcomes.component";
import {PicoSearchTermsComponent} from "./pico-search-terms.component";

export const PicoComponent = ({study}) => {
    return (
        <>
            <FieldHeaderComponent title={'PICO'} style={fieldHeaderStyle} />
            <div style={Styles.CONTENT_BACKGROUND_DARK_GREY}>
                {study?.participantEligibility &&
                    <PicoPopulationComponent study={study}/>
                }
                {study?.arms &&
                    <PicoArmsComponent arms={study.arms}/>
                }
                {study?.outcomes &&
                    <PicoOutcomesComponent outcomes={study.outcomes} />
                }
                <PicoSearchTermsComponent study={study} />
            </div>
        </>
    )
}
