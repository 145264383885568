import { Outlet } from 'react-router-dom';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useAuthService } from '@vivli/core/authentication';
import { LoginPromptComponent } from '@vivli/features/authentication/components';

export const ProtectedRoute = ({ element }: { element?: JSX.Element }) => {
    const user = useActiveUser();
    const { login } = useAuthService();

    if (!user) {
        return element || <LoginPromptComponent login={login} />;
    }

    return <Outlet />;
};
