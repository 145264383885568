// Add new translation key values to amr-translations.constant,
// default-translations.constant and translation-key.enum.
// This allows you to use enum vals to look up constants easily
// To retrieve values, get a function from the useTranslation hook,
// as follows:
// const {t} = useTranslation();
// then pass the key to the function, e.g.
// const translatedString = t(TranslationKeyEnum.welcomeMessage)
export const AmrTranslationsConstant: any = {
    isDefault: 'no',
    picoButton: 'BROWSE',
    studies: 'Datasets',
    study: 'dataset',
    Vivli: 'AMR',
    welcomeMessage: 'This is your view of AMR Register at a glance.' +
        '<br><br>' +
        'To start a request for AMR surveillance datasets, navigate to the drop-down menu at\n' +
        'the top right of this page under your name, and select ‘Search.’' +
        '<br><br>' +
        'If you are a data contributor, to view the data requests for your organization’s datasets\n' +
        'navigate to the ‘Data Requests’ tab on the left-hand side of this page.' +
        '<br><br>' +
        'If you have any questions, please contact Vivli Support at '
        + '<0>amr@vivli.org</0>' +
        '<br><br>' +
        'Thanks!',
    studyID: 'Dataset ID',
    studyType: 'Data',
    fileUploadInstructions: 'data sets',
    update: 'Update dataset Data Package Below',
    title: 'Dataset Title',
    popUpWelcomeMessage:
        'Welcome to the AMR Register, which offers an easy way to request antimicrobial resistance ' +
        'surveillance datasets from a range of biotech and pharmaceutical companies.' +
        '<br><br>' +
        'To search for datasets and then request them on the platform, you can <strong>BROWSE</strong> the details of all ' +
        'the datasets, or use the <strong>SEARCH</strong> to filter the datasets to identify those that contain the ' +
        'information you require. Filtered search allows you to select values from drop-down lists to identify the ' +
        'datasets relevant for your research.  All the drop-down filters allow you to select one or more values. The ' +
        'number of matches will display on the bottom of the screen – click this number or the (magnifying glass symbol) ' +
        'to display the list of datasets matching your criteria.' +
        '<br><br>' +
        'More detailed guidance on all aspects of using the AMR Register is on the Resources Menu. You can also contact us at ' +
        //Translates to: '<a href="mailto:amr@vivli.org">amr@vivli.org</a>.'
        '<2>amr@vivli.org</2>.',
    chatHelperText: 'Requesters Chat will be seen by the requester of the data and Vivli',
    duaValidatedStatusString: 'Awaiting Data Package Upload',
    howToGuideLink: "https://amr.vivli.org/resources/resources/",
    privacyLink: "https://amr.vivli.org/privacy/",
    cookiePolicyLink: "https://amr.vivli.org/vivli-cookie-policy/",
    eeaDisclosurePolicyLink: "https://amr.vivli.org/eea-disclosure/",
    contactUsLink: "https://amr.vivli.org/contact/",
    vmResearchEnvironmentConfig: "Alternative pricing applies for industry users of SAS, email support@vivli.org for details",
    pathway2SubmitConfirmText: "You are about to submit a data request containing datasets that must be requested from a partner platform.  Once you submit this request it can be changed only by contacting Vivli at AMR@vivli.org. Press OK if you are sure you are ready to submit the request now.",
    accessDataPackagesIPD: "AMR surveillance data",
    accessDPResearchEnvironment:"",
};
