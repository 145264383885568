export const disallowedPublicEmailDomainsIntercept = [
    'gmail.com',
    'hotmail.com',
    'aol.com',
    'yahoo.com',
    'msn.com',
    'hotmail.it',
    'btinternet.com',
    'mail.mil',
    'Verizon.net',
    'comcast.net',
    'live.com',
    '163.com',
    '126.com',
];
