import React, { CSSProperties } from 'react';
import { LoadIndicatorComponent } from './load-indicator.component';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
};

export const LoadIndicatorCenteredComponent = ({ message }: { message?: string }) => {
    return (
        <div style={containerStyle}>
            <LoadIndicatorComponent size={LoadIndicatorSizeEnum.Medium} message={message} />
        </div>
    );
};
