import React, {useEffect, useLayoutEffect, useState} from 'react';
import {FormProvider} from 'react-hook-form';
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {
    useDataRequestForm,
    useDataRequestPermissions,
    useDataRequestVirtualMachine, useInitializeResearchTeamHook
} from "@vivli/features/data-requests/infrastructure/hook";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {useActiveUser, useConfigService} from "@vivli/core/infrastructure/context";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {containerStyle, subContainerStyle} from "./data-request-form.styles";
import {DataRequestFormRoutesComponent} from "./data-request-form-routes.component";
import {RequestHeaderContainerComponent} from "../../shared/request-header/request-header-container.component";
import {useNavigate} from "react-router-dom";

export const DataRequestFormComponent = () => {
    const {
        dataRequest,
        isSavingDataRequest,
        isSubmittingDataRequest,
        handleEditTitleClick,
        handleFormSave,
        updateIsSaving,
        handleDataPackageCreation
    } = useDataRequestContext();
    const [routes, setRoutes] = useState<{ route: string, title: string }[]>();
    const [hideReviewers, setHideReviewers] = useState(null);
    const formApi = useDataRequestForm(dataRequest);
    const {transformDataRequest} = useInitializeResearchTeamHook();

    const navigate = useNavigate();
    const config = useConfigService();
    const user = useActiveUser();
    const {organizations} = useOrganizations();
    const dataRequestVirtualMachine = useDataRequestVirtualMachine(dataRequest.id);
    const {userIsOwner, userIsAdmin, shouldHideReviewers} = useDataRequestPermissions();

    const getRoutes = () => {
        let routes: { route: string, title: string }[] = [
            {
                route: 'researchProposal',
                title: 'Research Proposal'
            },
            {
                route: 'requestedStudies',
                title: 'Studies'
            },
            {
                route: 'statisticalAnalysisPlan',
                title: 'Statistical Analysis Plan'
            },
            {
                route: 'funding',
                title: 'Funding'
            },
            {
                route: 'otherInformation',
                title: 'Other Information / File Attachments'
            },
            {
                route: 'attestations',
                title: 'Attestations'
            }
        ];

        if (dataRequest.dataRequestHistoryEntries && dataRequest.dataRequestHistoryEntries.length > 0) {
            routes.push({route: 'requestHistory', title: 'Request History'});
        }

        const leadTeamMember = dataRequest.researchTeam.researchTeamDetails.find(x => x.userId === user.userId && x.isLeadResearcher);

        // allow owner or admin or lead researcher to modify team members
        if ((userIsOwner(dataRequest) || user.isVivliAdmin || leadTeamMember) && !hideReviewers) {
            routes = [
                {route: 'researchTeam', title: 'Research Team'},
                ...routes,
            ];
        }

        if (dataRequest.status === DataRequestStatusEnum.Fulfilled && dataRequestVirtualMachine) {
            routes.push({route: 'researchResults', title: 'Research Results'});
        }

        const shouldShowChat = config.chatEnabled && config.chatUri && (config.chatUri.length > 0) && dataRequest.chatChannelId;
        if (shouldShowChat) {
            routes.push({route: 'chat', title: 'Chat'});
        }

        if (dataRequest.status === DataRequestStatusEnum.Draft && user.isVivliAdmin) {
            routes.push({route: 'Notifications', title: 'Notifications'});
        }

        return routes;
    }

    const handleDataRequestFormUpdate = (updatedDataRequest: IDataRequest) => {
        const initializeDataRequest = transformDataRequest(updatedDataRequest)
        updateIsSaving(false);
        formApi.reset(initializeDataRequest, {keepErrors: true, keepIsValid: true});
    }

    useEffect(() => {
        if (!organizations) {
            return;
        }

        const permission = shouldHideReviewers(dataRequest, organizations);
        setHideReviewers(permission);
    }, [organizations])

    useEffect(() => {
        if (hideReviewers === null) {
            return;
        }

        const activeRoutes = getRoutes();

        setRoutes(activeRoutes);
    }, [hideReviewers])

    useEffect(() => {
        if (!formApi.formState.isValid){
            // force initial validation
            formApi.trigger()
        }

        const isOwnerOrAdmin = userIsOwner(dataRequest) || userIsAdmin(dataRequest) || user.isVivliAdmin;
        if (!isOwnerOrAdmin) {
            navigate('chat');
        }

        handleDataPackageCreation(formApi);
    }, [])
    if (!organizations || !routes) {
        return <LoadIndicatorCenteredComponent/>
    }
    return (
        <FormProvider {...formApi}>
            <div style={containerStyle}>
                <div style={subContainerStyle}>
                    <RequestHeaderContainerComponent
                        onEditTitleClick={() => handleEditTitleClick(formApi)}
                        onSaveClick={() => handleFormSave(formApi, false)}
                        onSubmitClick={() => handleFormSave(formApi, true)}
                        formHasErrors={!formApi.formState.isValid}
                        formIsDirty={formApi.formState.isDirty}
                        title={formApi.getValues('requestTitle')}
                    />

                    <DataRequestFormRoutesComponent
                        isSubmittingDataRequest={isSubmittingDataRequest}
                        isSavingDataRequest={isSavingDataRequest}
                        routes={routes}
                        handleDataRequestFormUpdate={handleDataRequestFormUpdate}
                        handleFormSave={() => handleFormSave(formApi, false)}
                        hideReviewers={hideReviewers}
                    />
                </div>
            </div>
        </FormProvider>
    )
}
