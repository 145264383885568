import {IResultRequest} from "@vivli/features/results-request/infrastructure/interface";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {IUser} from "@vivli/shared/infrastructure/interface";
import {ResultsRequestStatusEnum} from "@vivli/features/results-request/infrastructure/enum";

export abstract class ResultRequestPermissionService {
    public static canDownloadFiles(resultRequest: IResultRequest, dataRequest: IDataRequest, user: IUser): boolean {

        if (dataRequest.requirementsAndAttestations.userIDsHaveAgreedToDataUse.find(u => u === user.userId)) {
            return resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.Approved ||
                resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.HeuristicsPassed;
        }

        return this.canApproveDeny(resultRequest, user);
    }

    public static canApproveDeny(resultRequest: IResultRequest, user: IUser): boolean {
        if (user.isVivliAdmin) {
            return resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.HeuristicsRequireReview ||
                resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.Disapproved;
        }

        return false;
    }
}
