import React, {CSSProperties} from 'react';
import {ICellRendererParams} from 'ag-grid-community';
import {IDataRequestSummary} from '@vivli/features/data-requests/infrastructure/interface';
import moment from 'moment';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '30px',
    paddingRight: '75px',
    transition: 'scale 0.35s ease 0s, opacity 0.3s ease 0s',
    alignItems: 'center',
    height: '100%',
    lineHeight: 1
}

const titleStyle: CSSProperties = {
    color: 'rgb(96, 104, 111)',
    fontSize: '15px',
    fontWeight: 400,
    marginBottom: '5px',
    minWidth: '105px'
}

const dateStyle: CSSProperties = {
    color: 'rgb(96, 104, 111)',
    fontSize: '12px',
    fontWeight: 600,
    minWidth: '105px'
}

interface DataRequestDateRendererComponent extends ICellRendererParams {
    data: IDataRequestSummary
}

export const DataRequestDateRendererComponent = ({data}: DataRequestDateRendererComponent) => {
    const requested = data.submittedTime && moment(data.submittedTime).format('M/D/YY h:mm a');
    const created = data.createdTime && moment(data.createdTime).format('M/D/YY h:mm a');

    return (
        <div style={containerStyle}>
            <div style={titleStyle}>{requested ? 'REQUESTED' : 'CREATED'}</div>
            <div style={dateStyle}>{requested || created}</div>
        </div>
    )
}
