import React, {CSSProperties, useEffect, useState} from 'react';
import {ButtonComponent, DropdownFieldComponent, TextFieldComponent} from "@vivli/shared/components";
import {RequestDataStudiesGridComponent} from "./request-data-studies-grid.component";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {
    useAdminConsoleContext,
    useAdminConsoleLookupIdContext,
    useAdminConsoleService
} from "@vivli/features/admin-console/infrastructure/context";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {useDrPubSubHook} from "@vivli/shared/infrastructure/hook";

/* eslint-disable-next-line */
const marginLeftStyle = {
    marginLeft: 10,
}
const fontBoldStyle = {
    fontWeight: '700',
}
const templateSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '55%',
}
const statusSectionStyle = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    width: '80%',
    marginBottom: '20px'
}
const buttonStyle: CSSProperties = {
    marginLeft: 15,
}
const marginLeft50Style = {
    marginLeft: 50,
}
const templateBodyStyle: CSSProperties = {
    paddingBottom: '75px',
}

export function AdminDataRequestComponent() {

    const dropDownResetValue = Object.values(DataRequestStatusEnum).map(value => ({title: value, value: value}));

    const modalService = useModalService();
    const adminConsoleService = useAdminConsoleService();
    const [templateBody, setTemplateBody] = useState(false);
    const [lookUpData, setLookUpData] = useState<IDataRequest>();
    const [selectedStatus, setSelectedStatus] = useState<string>(null);
    const [operationStatus, setOperationStatus] = useState<string>(null);
    const context = useAdminConsoleContext();
    const {dataRequestLookupId, setDataRequestLookupId} = useAdminConsoleLookupIdContext();
    const handleError = (error) => {
        modalService.error(error);
    };

    const handleInputChangeDataRequest = (e) => {
        const value = e.currentTarget.value;
        setDataRequestLookupId(value);
    }
    const handleLookUpClick = (e) => {
        e.stopPropagation();
        const requestId = dataRequestLookupId.padStart(8, '0');
        if (lookUpData && lookUpData.dataRequestDoi != requestId) {
            drPubSub.closeChannel();
            setOperationStatus(null);
        }
        adminConsoleService.getRequestData(requestId)
            .pipe(first())
            .subscribe(data => {
                //feel in requestId in the requested studies for buttons
                data.requestedStudies.forEach((study) => {
                    study.requestId = data.id
                });
                setLookUpData(data);
                setTemplateBody(true);
                drPubSub.subscribeToDrMessages(updateOperationStatus);
            }, handleError);
    }
    const requesterName = () => {
        return `${lookUpData.userName}, ${lookUpData.userEmail}`
    }

    const leadResearcherName = () => {
        return <span> {lookUpData.researchTeam.leadResearcherTeamMemberDetail.firstName}
            {lookUpData.researchTeam.leadResearcherTeamMemberDetail.lastName},{' '}
            {lookUpData.researchTeam.leadResearcherTeamMemberDetail.email}
                </span>
    }

    const handleStatusChange = (e) => {
        setSelectedStatus(e);
    }
    const handleStatusReset = (e) => {
        e.stopPropagation();
        context.handleAction("ResetDataRequestStatus", lookUpData.id, selectedStatus, false);
    }

    const updateOperationStatus = (message: any) => {
        if (message?.data) {
            setOperationStatus(message.data);
        } else {
            setOperationStatus(null);
        }
    }
    const drPubSub = useDrPubSubHook(lookUpData, updateOperationStatus);

    return (
        <div>
            <div style={templateSectionStyle}>
                <span style={fontBoldStyle}>Data Request ID:</span>
                <TextFieldComponent label={'Data Request Id'}
                  value={dataRequestLookupId}
                  onChange={(e) => handleInputChangeDataRequest(e)}
                  onKeyDown={e => {
                    e.key === "Enter" && handleLookUpClick(e)
                  }} style={marginLeftStyle}/>
                <ButtonComponent
                    style={buttonStyle}
                    onClick={handleLookUpClick}
                >
                    Lookup Request
                </ButtonComponent>
            </div>
            {templateBody &&
                <div style={templateBodyStyle}>
                    <p>
                        Title: {lookUpData.requestTitle}
                    </p>
                    <p>
                        Requester: {requesterName()}
                    </p>
                    <p>
                        Lead Researcher:{leadResearcherName()}
                    </p>
                    <p>
                        <span>Current Status: {lookUpData.status} </span>
                    </p>
                    <div>
                        <span>Operation Status: {operationStatus} </span>
                    </div>
                    <div style={statusSectionStyle}>
                        <span style={statusSectionStyle}>Status to set
                            <DropdownFieldComponent items={dropDownResetValue} label={'Status to set'}
                                                    style={buttonStyle} onChange={handleStatusChange}/>
                        </span>
                        <ButtonComponent onClick={handleStatusReset} style={marginLeft50Style}>
                            Reset Status
                        </ButtonComponent>
                    </div>
                    <div>
                        <RequestDataStudiesGridComponent
                            studies={lookUpData.requestedStudies}
                            hideFloatingFilter={true}
                            hideHeader={true}
                        />
                    </div>
                </div>
            }
        </div>
    );
}
