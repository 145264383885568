﻿import {ButtonComponent} from '@vivli/shared/components';
import {
    blueButtonStyle,
    buttonContainerStyle,
    cellStyle,
    flexRowStyle,
    fullFlexColStyle,
    yellowButtonStyle
} from './manage-vm.styles';
import {IVirtualMachine} from '@vivli/features/virtual-machine/infrastructure/interface';
import {useModalService, useToastService} from '@vivli/shared/infrastructure/context';
import {first} from 'rxjs/operators';
import {useVirtualMachineService} from '@vivli/features/virtual-machine/infrastructure/context';
import {useActiveUser} from '@vivli/core/infrastructure/context';
import {AuditTrailContextualComponent} from '@vivli/features/admin-console/components';

interface ManageVmModalButtonsComponentProps {
    vm: IVirtualMachine;
    isLoading: boolean;
    setIsLoading: (isLoading) => void;
}

export const ManageVmModalButtonsComponent = ({vm, isLoading, setIsLoading}: ManageVmModalButtonsComponentProps) => {
    const modalService = useModalService();
    const toastService = useToastService();
    const virtualMachineService = useVirtualMachineService();
    const user = useActiveUser();
    const isOpsAdmin = user.isOperationsAdmin;
    const resourceGroupName = vm?.resourceGroupName ? vm?.resourceGroupName : 'N/A';
    const adminAndNotDeProvisioned = user.isOperationsAdmin && vm?.status !== 'DeProvisioned';


    const handleError = (e) => {
        modalService.error(e);
        setIsLoading(false);
    };

    const handleConfirmStop = () => {
        modalService.confirm('Are you sure you want to stop this Research Environment?', {
            title: 'Stop Research Environment',
            style: {zIndex: 11000},
            onConfirm: () => vmStopConfirmResult(),
        });
    };

    const handleConfirmDeprovision = () => {
        modalService.confirm('Are you sure you want to deprovision? This action cannot be undone.', {
            title: 'Deprovision Research Environment',
            style: {zIndex: 11000},
            onConfirm: () => vmDeprovisionConfirmResult(),
        });
    };

    const startResearchEnvironment = () => {
        setIsLoading(true);
        virtualMachineService
            .startResearchEnvironment(vm.dataRequestId)
            .pipe(first())
            .subscribe(() => {
                setIsLoading(false);
                toastService.info(`Start request issued, please close the Dialog and check back.`);
            }, handleError);
    };

    const connectToVm = () => {
        window.open(`/virtual-machine/${vm.id}/${vm.dataRequestDoi}`);
    };

    const vmStopConfirmResult = () => {
        setIsLoading(true);
        virtualMachineService
            .stopResearchEnvironment(vm.id)
            .pipe(first())
            .subscribe((vm) => {
                toastService.info(`Stop request issued, please close the Dialog and check back.`);
            }, handleError);
    };

    const vmDeprovisionConfirmResult = () => {
        setIsLoading(true);
        virtualMachineService
            .deprovisionResearchEnvironment(vm.id)
            .pipe(first())
            .subscribe((vm) => {
                setIsLoading(false);
                toastService.info(`Deprovision request issued, please close the Dialog and check back.`);
            }, handleError);
    };

    const getPassword = () => {
        virtualMachineService
            .getAdminPassword(vm.id)
            .subscribe((token) => {
                navigator.clipboard.writeText(token);
                toastService.info(`Password copied to the clipboard.`);
            }, handleError)
    }

    return (
        <>
            <div style={flexRowStyle}>
                <div style={cellStyle}>Resource Group:</div>
                <div style={cellStyle}>{resourceGroupName}</div>
            </div>
            <div style={fullFlexColStyle}>
                <div style={buttonContainerStyle}>
                    <ButtonComponent style={yellowButtonStyle} onClick={handleConfirmStop} disabled={isLoading}>
                        Stop Research Environment
                    </ButtonComponent>
                    <br/>
                    {adminAndNotDeProvisioned && (
                        <>
                            <ButtonComponent style={yellowButtonStyle} onClick={handleConfirmDeprovision}
                                             disabled={isLoading}>
                                Deprovision Research Environment
                            </ButtonComponent>
                            <br/>
                        </>
                    )}
                    <ButtonComponent style={blueButtonStyle} onClick={startResearchEnvironment} disabled={isLoading}>
                        Start Research Environment
                    </ButtonComponent>
                    <br/>
                    {adminAndNotDeProvisioned && (
                        <>
                            <ButtonComponent style={blueButtonStyle} onClick={connectToVm}>
                                Connect to VM
                            </ButtonComponent>
                            <br/>
                            <div>
                                <AuditTrailContextualComponent
                                    type="VM"
                                    id={vm.dataRequestDoi}
                                    internalId={vm.id}
                                ></AuditTrailContextualComponent>
                            </div>
                        </>
                    )}
                    {isOpsAdmin &&
                        <ButtonComponent style={blueButtonStyle} onClick={getPassword}>
                            Get Password
                        </ButtonComponent>
                    }
                </div>
            </div>
        </>
    );
};
