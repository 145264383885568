import React from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext
} from "@vivli/shared/features/data-package/components";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {first} from "rxjs/operators";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";

interface SignedDocumentsDataPackageFeatureProps {
    dataPackageId: string;
    dataRequest: IDataRequest;
}

export const  SignedDocumentsDataPackageFeature = (
    {
        dataPackageId,
        dataRequest
    }: SignedDocumentsDataPackageFeatureProps
) => {
    const user = useActiveUser();
    const dataRequestsService = useDataRequestsService();

    const handleOnFileAdded = () => {
        dataRequestsService.notifyOfDuaUpload(dataRequest.id)
            .pipe(first()).subscribe();
    }

    const isResearchTeamMember = dataRequest.researchTeam?.researchTeamDetails?.some(a=> a.email === user.email);

    return (
        <DataPackageBaseComponent dataPackageId={dataPackageId}>
            <DataPackageContext.Consumer>
                {dataPackage => <DataPackageComponent
                    dataPackage={dataPackage}
                    readOnly={true}
                    allowDownload={true}
                    allowUpload={isResearchTeamMember || user.isVivliAdmin}
                    allowSubmit={false}
                    hideFileType={true}
                    showDateTime={true}
                    onFileAdded={handleOnFileAdded}
                />}
            </DataPackageContext.Consumer>
        </DataPackageBaseComponent>
    )
}

