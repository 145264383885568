import joi from "joi";
import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";

export const unlistedStudyFormSchema = joi.object({
    dataProviderOrgId: joi.string().required().label('Data Provider'),
    nctOrSponsorProtocolId: joi.string().required().label('NCT or Sponsor Protocol ID'),
    studyTitle: joi.string().required().label('Study Title')
}).options(JoiValidationOptionsConstant);

const defaultValues = {
    dataProviderOrgId: '',
    nctOrSponsorProtocolId: '',
    studyTitle: ''
}

export function useUnlistedStudyForm(mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(unlistedStudyFormSchema),
        reValidateMode: 'onChange',
        defaultValues
    });
}
