import React, { CSSProperties, forwardRef } from 'react';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const iconStyle: CSSProperties = {
    width: '20px',
};

interface QuestionMarkComponentProps {
    style?: CSSProperties;
    size?: number;
    onClick?: () => void;
}

export const QuestionMarkComponent = forwardRef<
    any,
    QuestionMarkComponentProps
>(({ style, size, onClick }: QuestionMarkComponentProps, ref) => {
    let imgStyle = {
        ...iconStyle,
    };

    if (size) {
        imgStyle = {
            ...style,
            width: `${size}px`,
        };
    }

    if (onClick) {
        imgStyle = {
            ...imgStyle,
            cursor: 'pointer',
        };
    }

    return (
        <div ref={ref}>
            <img
                src={AssetsConstant.questionMarkIcon}
                style={imgStyle}
                onClick={onClick}
            />
        </div>
    );
});
