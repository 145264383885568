import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";
import {useDataRequestPermissions} from "./use-data-request-permissions.hook";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useEffect, useState} from "react";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";

export function useResearchTeamPermissions(dataRequest: IDataRequest, isAdminEditing: boolean = false) {
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [userCanManage, setUserCanManage] = useState(false);
    const {userIsOwner, userIsAdmin} = useDataRequestPermissions();
    const user = useActiveUser();

    const handlePermissions = () => {
        const inDraft = dataRequest.status === DataRequestStatusEnum.Draft;
        const isDrOwnerOrAdmin = userIsOwner(dataRequest) || userIsAdmin(dataRequest);

        setUserCanEdit((user?.isVivliAdmin && isAdminEditing) || (inDraft && isDrOwnerOrAdmin));
        setUserCanManage(user?.isVivliAdmin || isDrOwnerOrAdmin)
    }

    useEffect(() => {
        handlePermissions();
    }, [dataRequest, user, isAdminEditing])

    return {
        userCanEdit,
        userCanManage
    }
}
