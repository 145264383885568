﻿import React, { CSSProperties } from 'react';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useUserRouteMapperHook } from '@vivli/features/portal/infrastructure/hook';
import {
    NavTabsComponent,
    PortalRoutesComponent,
} from '@vivli/features/portal/components';
import { Outlet } from 'react-router-dom';
import { Color } from '@vivli/shared/theme';

const containerStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
};

const outletStyle: CSSProperties = {
    height: '100%',
    flex: '1 1 auto',
    backgroundColor: Color.LIGHT_GRAY,
    overflowY: 'hidden',
    overflowX: 'hidden',
};

export const PortalFeature = () => {
    const user = useActiveUser();
    const { getUserRoutes } = useUserRouteMapperHook();
    const panelItems = user && getUserRoutes(user);

    return (
        <div style={containerStyle}>
            <NavTabsComponent panelItems={panelItems} />
            <div style={outletStyle}>
                <PortalRoutesComponent />
                <Outlet />
            </div>
        </div>
    );
};
