import {Observable} from 'rxjs';
import {RestApi} from '@vivli/core/infrastructure/rest';
import {IDataPackage, IDataPackageFileApiService} from "@vivli/shared/features/data-package/infrastructure/interface";

export class DataPackageFileApiService extends RestApi implements IDataPackageFileApiService {
    downloadFileUri = (dataPackageId: string, fileName: string, recordHistory: boolean, useSecureStorage = true): Observable<string> => {
        return this.handlePost<string>(`/files/${dataPackageId}/${recordHistory}/${useSecureStorage}`, fileName);
    }

    deleteFile = (dataPackageId: string, fileName: string, useSecureStorage = true): Observable<IDataPackage> => {
        return this.handlePut<IDataPackage>(`/files/${dataPackageId}/deleteFile/${useSecureStorage}`, fileName);
    }

    downloadDataRequestStudyFiles = (dataRequestId: string): Observable<string> => {
        return this.handleGet<string>(`/files/${dataRequestId}/downloadzipfile`);
    }
}
