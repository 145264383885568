import React from 'react';
import {GridComponent} from '@vivli/shared/components';
import {ColDef, GridApi} from 'ag-grid-community';
import {DataRequestDateRendererComponent} from './data-request-date-renderer.component';
import {DataRequestStatusRenderer} from './data-request-status-renderer.component';
import {DataRequestTitleRendererComponent} from './data-request-title-renderer.component';
import {AssignedAppTypeEnum, GridCellRendererEnum, TranslationKeyEnum} from '@vivli/shared/infrastructure/enum';
import {
    IDataRequest,
    IDataRequestSummary,
    IRequestedStudy
} from '@vivli/features/data-requests/infrastructure/interface';
import {DataRequestFormatService} from '@vivli/features/data-requests/infrastructure/service';
import moment from 'moment';
import {MyDataRequestTitleRendererComponent} from "./my-data-request-title-renderer.component";
import {useTranslation} from "react-i18next";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";

interface DataRequestsGridComponentProps {
    dataRequests: IDataRequestSummary[];
    onRowClick?: (dataRequest: IDataRequestSummary) => void;
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    autoHeight?: boolean;
    displayType?: 'default' | 'myrequests';
    asyncUpdateKey?: string;
    onRowCountChange?: (count: number) => void;
    dataRefreshing?: boolean;
}

const idComparator = (a, b, nodeA, nodeB) => {
    const aVal: IDataRequestSummary = nodeA.data;
    const bVal: IDataRequestSummary = nodeB.data;

    return aVal.idString.localeCompare(bVal.idString);
}

const updatedDateComparator = (a, b, nodeA, nodeB) => {
    const aVal: IDataRequestSummary = nodeA.data;
    const bVal: IDataRequestSummary = nodeB.data;

    return (aVal.updatedDate < bVal.updatedDate) ? -1 : 1;
}

const filterByList = (filter, filterTextValues: string, filterText: string) => {
    const filterByList = filterTextValues?.split(':::') || [];
    const filterTextValuesLowerCase = filterByList.filter(v => v).map(t => t.toLowerCase());
    const filterTextLowerCase = filterText.toString().toLowerCase();
    switch (filter) {
        case 'contains':
            return filterTextValuesLowerCase.some(v => v.indexOf(filterTextLowerCase) >= 0);
        case 'notContains':
            return !filterTextValuesLowerCase.some(v => v.indexOf(filterTextLowerCase) === -1);
        case 'equals':
            return filterTextValuesLowerCase.some(v => v === filterTextLowerCase);
        case 'notEqual':
            return filterTextValuesLowerCase.some(v => v != filterTextLowerCase);
        case 'startsWith':
            return filterTextValuesLowerCase.some(v => v.indexOf(filterTextLowerCase) === 0);
        case 'endsWith':
            return filterTextValuesLowerCase.some(v => {
                const index = v.lastIndexOf(filterTextLowerCase);
                return index >= 0 && index === (v.length - filterTextLowerCase.length);
            })
        default:
            // should never happen
            console.warn('invalid filter type ' + filter);
            return false;
    }
}

const exportColumnKeys = [
    'requestId',
    'requestTitle',
    'userName',
    'userEmail',
    'pi',
    'piEmail',
    'status',
    'dateOfLastAction',
    'dataContributorOrganizations'
]



const customComponents = [
    {
        component: DataRequestDateRendererComponent,
        name: GridCellRendererEnum.DataRequestDate
    }, {
        component: DataRequestStatusRenderer,
        name: GridCellRendererEnum.DataRequestStatus
    }, {
        component: DataRequestTitleRendererComponent,
        name: GridCellRendererEnum.DataRequestTitle
    }, {
        component: MyDataRequestTitleRendererComponent,
        name: GridCellRendererEnum.MyDataRequestTitle
    }
];

export const DataRequestsGridComponent = (
    {
        dataRequests,
        onRowClick,
        onGridReady,
        emptyMessage,
        hideFloatingFilter,
        autoHeight,
        displayType,
        asyncUpdateKey,
        onRowCountChange,
        dataRefreshing
    }: DataRequestsGridComponentProps) => {
    const handleRowClick = (dataRequest: IDataRequestSummary) => {
        onRowClick && onRowClick(dataRequest);
    }

    const {t} = useTranslation();
    const duaTranslation = t(TranslationKeyEnum.duaValidatedStatusString);
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const isMyRequestsGrid = displayType === 'myrequests';

    const exportCols: ColDef[] = [
        {
            field: exportColumnKeys[0],
            headerName: 'Request ID',
            hide: true,
            valueGetter: params => params.data.idString.replace('Vivli ID: ', '')
        },
        {
            field: exportColumnKeys[1],
            headerName: 'Data Request',
            hide: true
        },
        {
            field: exportColumnKeys[2],
            headerName: 'Requester',
            hide: true,
            valueGetter: params => params.data.userName || 'Unknown'
        },
        {
            field: exportColumnKeys[3],
            headerName: 'Requester\'s Email',
            hide: true,
            valueGetter: params => params.data.userEmail || 'Unknown'
        },
        {
            field: exportColumnKeys[4],
            headerName: 'Principal Investigator',
            hide: true,
            valueGetter: params =>
                isAmr
                    ? params.data.amrResearcherDetails?.researcherName
                    : DataRequestFormatService.getLeadResearcher(params.data)
        },
        {
            field: exportColumnKeys[5],
            headerName: 'PI Email',
            hide: true,
            valueGetter: params =>
                isAmr
                    ? params.data.amrResearcherDetails?.researcherEmail
                    : params.data.leadResearcherTeamMemberDetail?.email
        },
        {
            field: exportColumnKeys[6],
            headerName: 'Request Status',
            hide: true
        },
        {
            field: exportColumnKeys[7],
            headerName: 'Date of Last Update',
            hide: true,
            valueGetter: params => {
                const data: IDataRequestSummary = params.data;
                let updatedTime = moment(data.updatedDate).format('MM/DD/YY hh:mm a');
                return updatedTime;
            }
        },
        {
            field: exportColumnKeys[8],
            headerName: 'Data Contributor Organizations',
            hide: true,
            valueGetter: params => {
                const data: IDataRequestSummary = params.data;
                const dataContributorOrgs = data.requestedStudies.map(study => study.organizationName);
                const uniqueOrgs = new Set();
                dataContributorOrgs.forEach(org => uniqueOrgs.add(org));
                return Array.from(uniqueOrgs).join(';');
            }
        }
    ]

    const columns: ColDef[] = [
        {
            field: 'customTitle',
            headerName: 'Data Request',
            filter: true,
            sortable: true,
            cellRenderer: displayType === 'myrequests' ? GridCellRendererEnum.MyDataRequestTitle :
                GridCellRendererEnum.DataRequestTitle,
            comparator: displayType === 'myrequests' ? updatedDateComparator : idComparator,
            initialSort: 'desc',
            filterParams: {
                textCustomComparator: filterByList
            },
            filterValueGetter: params => {
                const data: IDataRequestSummary = params.data;
                const idString = data.idString.replace('Vivli ID: ', '');
                const leadResearcherDetail = data.leadResearcherTeamMemberDetail;
                const leadResearcher = leadResearcherDetail ? `${leadResearcherDetail.firstName} ${leadResearcherDetail.lastName}` : 'Unknown';
                const requestStudies = params.data.requestedStudies?.map((study: IRequestedStudy) => study.organizationName);

                return [
                    data.requestTitle,
                    idString,
                    data.userName,
                    leadResearcher,
                    data.userEmail,
                    data.doiStem,
                    leadResearcherDetail?.firstName,
                    leadResearcherDetail?.lastName,
                    leadResearcherDetail?.email,
                    ...requestStudies
                ].filter(s => s).join(':::');
            }
        },
        {
            field: 'customStatus',
            headerName: 'Status',
            filter: true,
            sortable: false,
            cellRenderer: GridCellRendererEnum.DataRequestStatus,
            cellRendererParams: {
                hideStatus: isMyRequestsGrid,
                alignRight: isMyRequestsGrid,
                showRemoveButton: isMyRequestsGrid
            },
            filterParams: {
                textCustomComparator: filterByList
            },
            filterValueGetter: params => {
                const data: IDataRequestSummary = params.data;
                const status = DataRequestFormatService.getApprovalStatuses(data);
                const message = DataRequestFormatService.mapStatusToMessage(data.status, isAmr, duaTranslation);
                const approved = status.approved > 0 && 'approved';
                const available = status.available > 0 && 'available';
                const pending = status.pending > 0 && 'pending';
                const notApproved = status.notApproved > 0 && 'not approved';
                return [message, approved, available, pending, notApproved].join(':::');
            }
        },
        ...exportCols
    ]

    const asyncPredicate = (oldValue: IDataRequest, newValue: IDataRequest) => {
        return oldValue.status !== newValue.status;
    }

    return <GridComponent
        rowData={dataRequests}
        columns={columns}
        hideHeader={true}
        hideFloatingFilter={hideFloatingFilter}
        fullWidthColumns={true}
        customComponents={customComponents}
        rowHeight={displayType === 'myrequests' ? 120 : 95}
        onRowClick={handleRowClick}
        onGridReady={onGridReady}
        emptyMessage={emptyMessage}
        autoHeight={autoHeight}
        asyncUpdateKey={asyncUpdateKey !== undefined ? asyncUpdateKey : 'id'}
        asyncUpdatePredicate={asyncPredicate}
        onRowCountChange={onRowCountChange}
        dataRefreshing={dataRefreshing}
    />
}
