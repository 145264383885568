import React, { CSSProperties, useEffect, useState } from 'react';
import { ExportCsvButtonComponent, TabComponent, TabsComponent } from '@vivli/shared/components';
import { GridApi } from 'ag-grid-community';
import { IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { Size } from '@vivli/shared/theme';
import { ITab } from '@vivli/shared/infrastructure/interface';
import { DataRequestsGridComponent } from './data-requests-grid.component';
import { BehaviorSubject } from 'rxjs';

const exportButtonStyle: CSSProperties = {
    right: '10px',
    '@media screen and (maxWidth: 1480px)': {
        position: 'initial',
    },
} as CSSProperties;

const searchInputStyle: CSSProperties = {
    flex: 'auto',
    resize: 'none',
    padding: Size.PADDING,
    borderRadius: '8px',
    borderColor: 'transparent',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 20px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    color: 'black',
    position: 'absolute',
    top: '20px',
    right: '40px',
};

const containerStyle: CSSProperties = {
    padding: `0 ${Size.INSET_SIZE}px`,
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgb(233, 234, 239)',
};

interface DataRequestsPageComponentProps {
    title?: string;
    subHeader?: string;
    tabs?: ITab<IDataRequestSummary[]>[];
    defaultTab?: string;
    onRowClick?: (dataRequest: IDataRequestSummary) => void;
    onTabClick?: (tab: ITab<IDataRequestSummary[]>) => void;
    showExport?: boolean;
    displayType?: 'default' | 'myrequests';
    hideFloatingFilter?: boolean;
    dataRefreshing?: boolean;
}

const exportColumnKeys = [
    'requestId',
    'requestTitle',
    'userName',
    'userEmail',
    'pi',
    'piEmail',
    'status',
    'dateOfLastAction',
    'dataContributorOrganizations',
];

const gridApis: { gridApi: GridApi; id: string }[] = [];

export const DataRequestsPageComponent = ({
    title,
    subHeader,
    tabs,
    defaultTab,
    onRowClick,
    onTabClick,
    showExport,
    displayType,
    hideFloatingFilter,
    dataRefreshing,
}: DataRequestsPageComponentProps) => {
    const [activeTabs, setActiveTabs] = useState<ITab<IDataRequestSummary[]>[]>(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const exportGridData = (tab: ITab<IDataRequestSummary[]>) => {
        const gridApi = gridApis.find((ga) => ga.id === tab.id)?.gridApi;
        const date = new Date();
        const dateStr = `${date.getMonth()}${date.getDay()}${date.getFullYear()}`;
        const titleStr = tab.title.replace(/\s+/g, '_').toLowerCase();
        const fileName = `${titleStr}_data_requests_${dateStr}`;

        gridApi.exportDataAsCsv({ fileName, columnKeys: exportColumnKeys });
    };

    const addGridApi = (gridApi: GridApi, title) => {
        gridApis.push({ gridApi, id: title });
    };

    const handleTabClick = (tab: ITab<IDataRequestSummary[]>) => {
        onTabClick && onTabClick(tab);
    };

    const handleRowClick = (dataRequest: IDataRequestSummary) => {
        onRowClick && onRowClick(dataRequest);
    };

    const handleSearchChange = (searchValue: string) => {
        gridApis?.forEach(({ gridApi, id }) => {
            gridApi.getFilterInstance('customTitle', (filterInstance) => {
                filterInstance.setModel({
                    filterType: 'text',
                    type: 'contains',
                    filter: searchValue,
                });

                gridApi.onFilterChanged();
            });
        });
    };

    useEffect(() => {
        if (!tabs) {
            return;
        }

        setActiveTabs([
            ...tabs.map((t) => ({
                ...t,
                countSubject: new BehaviorSubject(t.count),
            })),
        ]);
    }, [tabs]);

    useEffect(() => {
        if (!defaultTab || !activeTabs) {
            return;
        }

        const index = activeTabs.findIndex((t) => t.id.toLowerCase() === defaultTab.toLowerCase());

        setActiveTabIndex(index);
    }, [defaultTab, activeTabs]);

    return (
        <div style={containerStyle}>
            <input
                type="text"
                style={searchInputStyle}
                placeholder="Search data requests"
                onChange={(e) => handleSearchChange(e.target.value)}
            />
            <TabsComponent title={title} subTitle={subHeader} defaultTab={activeTabIndex}>
                {activeTabs?.map((tab, i) => {
                    const { title, data, hint, count, countSubject } = tab;
                    return (
                        <TabComponent
                            key={i}
                            title={title}
                            hint={hint}
                            count={count}
                            countSubject={countSubject}
                            onTabClick={() => handleTabClick(tab)}
                            rightHeaderContent={
                                showExport && <ExportCsvButtonComponent onClick={() => exportGridData(tab)} style={exportButtonStyle} />
                            }
                        >
                            <DataRequestsGridComponent
                                displayType={displayType}
                                dataRequests={data}
                                onRowClick={handleRowClick}
                                onGridReady={(gridApi) => addGridApi(gridApi, tab.id)}
                                hideFloatingFilter={hideFloatingFilter}
                                onRowCountChange={(rowCount) => {
                                    countSubject?.next(rowCount);
                                }}
                                dataRefreshing={dataRefreshing}
                            />
                        </TabComponent>
                    );
                })}
            </TabsComponent>
        </div>
    );
};
