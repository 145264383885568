import joi, {ObjectSchema} from 'joi';
import {useForm} from "react-hook-form";
import {joiResolver} from '@hookform/resolvers/joi';
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";
import {IStatusUpdate} from "@vivli/shared/infrastructure/interface";

const formSchema: ObjectSchema<IStatusUpdate> = joi.object({
    currentWorkflowStepTargetDays: joi.number().optional().integer().min(-1).allow(null, "").label('Target Time for Current Step (Days)'),
    billingRatePerDay: joi.number().optional().integer().min(0).allow(null, "").label('Billing Rate (Per Day)'),
}).options(JoiValidationOptionsConstant)

const defaultValues: IStatusUpdate = {
    firstPublicationDate: null,
    publicationProgress: '',
    requestReviewStatus: '',
    duaStatus: '',
    dataUploadStatus: '',
    additionalNotes: '',
    internalNotes: '',
    vivliOwner: '',
    therapeuticArea: '',
    actionsRequired: '',
    feedback: '',
    highlightedDaysInCurrentWorkflowStep: '',
    currentWorkflowStepStartDate: null,
    currentWorkflowStepTargetDays: null,
    vmBillingStartDate: null,
    vmFreeDaysBooked: '',
    vmSizeFromTitle: '',
    paymentRemindersStatusHistory: '',
    nextActionNotes: '',
    nextActionDate: null,
    billingRatePerDay: null,
    sasForVM: null
}

export function useStatusUpdateForm(statusUpdate: IStatusUpdate = defaultValues, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema),
        defaultValues: statusUpdate,
        reValidateMode: 'onChange'
    });
}
