import React, {CSSProperties, useEffect, useRef} from 'react';
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {
    useAmrSearchOptionsContext,
    useCtSearchOptionsContext,
    useSearchContext
} from "@vivli/features/search/infrastructure/context";
import {ISearchStudy} from "@vivli/features/dashboard/infrastructure/interface";
import {SearchFiltersVerticalComponent} from "./search-filters/search-filters-vertical.component";
import './search-results.styles.scss';
import {SearchInputComponent} from "./search-filters/search-input.component";
import {SearchTabEnum} from "@vivli/features/search/infrastructure/enum";
import {SearchSelectComponent} from "./search-filters/search-select.component";
import {SearchResultRowsComponent} from "./search-result-row/search-result-rows.component";
import {AssetsConstant} from "@vivli/shared/infrastructure/constants";

const searchFilterContainerStyle: CSSProperties = {
    overflow: 'auto'
}

const searchResultsContainerStyle: CSSProperties = {
    height: '100%',
    overflow: 'auto',
    padding: '0 15px'
}

const inputContainerStyle: CSSProperties = {
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 20px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '10px',
    margin: '15px',
    minHeight: '70px'
}

const searchContainerStyle: CSSProperties = {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: '20% auto'
}

interface SearchResultsComponent {
    isOpen?: boolean;
}

export const SearchResultsComponent = ({isOpen}: SearchResultsComponent) => {
    const {searchResults, updateStudyData, activeTab} = useSearchContext();
    const amrSearchOptionsContext = useAmrSearchOptionsContext();
    const ctSearchOptionsContext = useCtSearchOptionsContext();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const activeUser = useActiveUser();
    const scrollContainerRef = useRef<HTMLDivElement>();

    const containerStyle: CSSProperties = {
        position: "absolute",
        left: "0",
        right: "0",
        bottom: isOpen ? 0 : '-100%',
        height: '100%',
        transitionProperty: 'all',
        transitionDuration: '1s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
        display: 'grid',
        gridTemplateRows: '85px auto',
        background: `url(${AssetsConstant.VIVLI_BACKGROUND})`,
        zIndex: 20,
        rowGap: '15px'
    }

    const handleViewStudyClick = (e, searchResult: ISearchStudy) => {
        e.preventDefault();
        if ((e.ctrlKey || e.shiftKey) && activeUser.isVivliAdmin) {
            window.open(`/admin/studies/${searchResult.id}/studyinformation`);
        } else {
            const detailsPath = isAmr ? 'datasetDetails' : 'studyDetails';
            window.open(`/${detailsPath}/fromSearch/${searchResult.id}`);
        }
    }

    const getActiveInput = () => {
        if (isAmr) {
            return <SearchInputComponent disableInput={true} onChange={amrSearchOptionsContext.setSearchString}/>
        }

        switch (activeTab) {
            case SearchTabEnum.FirstTab:
                return <SearchInputComponent onChange={ctSearchOptionsContext.setSearchString}/>
            case SearchTabEnum.SecondTab:
                return <SearchSelectComponent/>
        }
    }

    useEffect(() => {
        scrollContainerRef.current?.scrollTo(0, 0);
    }, [isOpen])

    return <div style={containerStyle}>
        <div style={inputContainerStyle}>
            {getActiveInput()}
        </div>
        <div style={searchContainerStyle}>
            <div style={searchFilterContainerStyle}>
                <SearchFiltersVerticalComponent/>
            </div>
            <div style={searchResultsContainerStyle} ref={scrollContainerRef}>
                <SearchResultRowsComponent
                    searchResults={searchResults}
                    onRowClick={handleViewStudyClick}
                    isOpen={isOpen}/>
            </div>
        </div>
    </div>
}
