﻿import React from "react";
import {Size, Color} from "@vivli/shared/theme";
import {IRequestedStudy} from "@vivli/features/data-requests/infrastructure/interface";

interface StudyRowDetailProps {
    requestedStudy: IRequestedStudy;
    dataRequestDoi: string;
    ipdStatusDescription: string;
    style?: React.CSSProperties;
}

export const StudyRowDetailComponent = (
    {
         requestedStudy,
         dataRequestDoi,
         ipdStatusDescription,
         style
    }: StudyRowDetailProps
) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                width: '70%',
                ...style,
            }}
        >
            <div>{requestedStudy.studyTitle}</div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: Size.FontSize.Medium,
                }}
            >
                <div>
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                    PI:&nbsp;
                </span>
                    <span>{requestedStudy.principalInvestigatorName}</span>
                </div>
                <div
                    style={{
                        marginLeft: Size.PADDING,
                    }}
                >
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                   {requestedStudy.requestedStudyType !== 'Unlisted'
                       ? <span>Sponsor:&nbsp;</span>
                       : <span>Data Contributor:&nbsp;</span>}
                </span>
                    { requestedStudy.requestedStudyType !== 'Unlisted'
                        ? <span>{requestedStudy.sponsorName}</span>
                        : <span>{requestedStudy.organizationName }</span>
                    }
                </div>
                <div
                    style={{
                        marginLeft: Size.PADDING,
                    }}
                >
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                    Study ID:&nbsp;
                </span>
                    <span>{requestedStudy.nctId ? requestedStudy.nctId : requestedStudy.sponsorProtocolId }</span>
                </div>
                {requestedStudy.requestedStudyType !== 'Unlisted' && <div
                    style={{
                        marginLeft: Size.PADDING,
                    }}
                >
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                    IRP/Approver:&nbsp;
                </span>
                    <span>{requestedStudy.approvalBodyOrgName}</span>
                </div>}
                <div
                    style={{
                        marginLeft: Size.PADDING,
                    }}
                >
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                    Data Request ID:&nbsp;
                </span>
                    <span>{dataRequestDoi}</span>
                </div>
                <div
                    style={{
                        marginLeft: Size.PADDING,
                    }}
                >
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                    Sponsor ID:&nbsp;
                </span>
                    <span>{requestedStudy.sponsorProtocolId}</span>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: Size.FontSize.Medium,
                }}
            >

                <div>
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                   {requestedStudy.requestedStudyType !== 'Unlisted'
                       ? <span>Data Contributor:&nbsp;</span>
                       : <span>-</span>}
                </span>
                    { requestedStudy.requestedStudyType !== 'Unlisted'
                        ? <span>{requestedStudy.organizationName}</span>
                        : <span>-</span>
                    }
                </div>
                <div
                    style={{
                        marginLeft: Size.PADDING,
                    }}
                >
                <span
                    style={{
                        color: Color.DARK_GRAY,
                    }}
                >
                    IPD Uploaded:&nbsp;
                </span>
                    <span>{ipdStatusDescription}</span>
                </div>

            </div>
        </div>
    )
}
