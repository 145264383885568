import React, {CSSProperties} from 'react';
import {Route, Routes} from "react-router-dom";
import {ResultRequestFeature} from "@vivli/features/results-request/feature";

const containerStyle: CSSProperties = {
    height: '100%',
    width: '100%'
}

export const VmAppRoutesWrapper = () => (
    <div style={containerStyle}>
        <Routes>
            <Route path='/' element={<ResultRequestFeature />} />
        </Routes>
    </div>
)
