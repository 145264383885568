import React, { CSSProperties } from 'react';
import { useSearchContext } from '@vivli/features/search/infrastructure/context';
import { LoadIndicatorComponent, TranslationComponent } from '@vivli/shared/components';
import {LoadIndicatorColorEnum} from "@vivli/shared/infrastructure/enum";

const containerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'visible',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.6) 0px 1px 20px, rgba(0, 0, 0, 0.6) 0px 1px 2px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    pointerEvents: 'inherit',
    cursor: 'pointer',
    height: '52px',
    flexDirection: 'column',
    margin: '0 40px',
    alignSelf: 'end',
};

const countStyle: CSSProperties = {
    fontWeight: 300,
    fontSize: '20px',
    color: 'rgb(255, 255, 255)',
};

const textStyle: CSSProperties = {
    fontWeight: 300,
    fontSize: '10px',
    color: 'rgb(255, 255, 255)',
};

const baseTransitionStyle: CSSProperties = {
    transitionProperty: 'opacity',
    transitionDuration: '1s',
    transitionTimingFunction: 'ease',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const loadIndicatorContainerStyle = (isGettingStudies: boolean): CSSProperties => ({
    ...baseTransitionStyle,
    opacity: isGettingStudies ? 1 : 0,
});

const studyDisplayContainerStyle = (isGettingStudies: boolean): CSSProperties => ({
    ...baseTransitionStyle,
    opacity: isGettingStudies ? 0 : 1,
});

interface ISearchResultsBarComponent {
    count: number;
    onClick?: (e) => void;
    text?: string;
}

export const SearchResultsBarComponent = ({ count, onClick }: ISearchResultsBarComponent) => {
    const { isGettingStudies } = useSearchContext();

    return (
        <div style={containerStyle} onClick={onClick}>
            <div style={loadIndicatorContainerStyle(isGettingStudies)}>
                <LoadIndicatorComponent color={LoadIndicatorColorEnum.White} />
            </div>
            {
                <div style={studyDisplayContainerStyle(isGettingStudies)}>
                    <div style={countStyle}>{count}</div>
                    <div style={textStyle}>
                        <TranslationComponent>studies</TranslationComponent>
                    </div>
                </div>
            }
        </div>
    );
};
