﻿import {ICitation} from "@vivli/shared/features/citations/infrastructure/interface";

///This hook is used to format the citation for display currently in MLA format, e.g.
/// Alonso, Alvaro, and Julio A. Camargo. "Toxicity of Nitrite to Three Species of Freshwater Invertebrates."
/// Environmental Toxicology, vol. 21, no. 1, 3 Feb. 2006, pp. 90-94. Wiley Online Library, doi: 10.1002/tox.20155.
///NOTE journal title should be rendered in italics,.
export function useCitation(citation: ICitation){

    /// <summary>return the authors in the format: Last, F. M. & Last, F. M.</summary>
    const formattedAuthors = (useEtAl: boolean) => {
        const numAuthors = citation?.authors?.length;
        if (numAuthors === 0 || !citation || citation === undefined) {
            return "-";
        }

        let finalSeparator = ' and ';
        let finalSeparatorIndex = 0;
        switch (numAuthors) {
            case 1:
                break;
            default:
                finalSeparatorIndex = numAuthors - 2;
                break;
        }

        let authorIndex = 0;
        let authorString = "";

        citation.authors.map(
            author => {
                //Note: I used + instead of interpolation to make it easier to see the strings
                //get first initial of given name
                //const givenInitial = author.givenName.slice(0, 1) + '.';

                //get the final separator and string
                const separator = authorIndex === finalSeparatorIndex;

                //format the author
                const separatorString = separator ? finalSeparator : ', ';
                const formatted =
                    author.familyName + ", " + author.givenName + separatorString;

                //add to the author string
                authorString = authorString + formatted;
                authorIndex++;
            });
        const trimAmount = numAuthors === 1 ? -4 : -2;

        const formatted = authorString.slice(0, trimAmount);
        return formatted;

    }

    /// <summary>return the rest of the citation in the format: Journal Volume, Month Year Number Pages Doi</summary>
    const remainderFormatted = () => {
        if (!citation || citation === undefined) {
            return "-";
        }
        let remainderString;

        if (citation.doi){
            remainderString = ` vol. ${citation.volume},
            no. ${citation.number}, ${citation.monthPublished}. ${citation.yearPublished},  pp. ${citation.pages}, doi: ${citation.url}`;
        } else {
            //smaller set of things to show for non-doi citations
            const locationString = citation.pages ? ` ${citation.pages},` : "";
            remainderString = `${locationString} ${citation.yearPublished}, url: ${citation.url}`;

        }
        return remainderString;
    }

    return {
        formattedAuthors,
        remainderFormatted,
    }

}
