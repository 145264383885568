import React, {CSSProperties} from 'react'

interface WaitingMessageComponent {
    message: string;
    isActive: boolean;
}

export const WaitingMessageComponent = ({message, isActive}: WaitingMessageComponent) => {
    const containerStyle: CSSProperties = {
        fontSize: '2em',
        position: 'absolute',
        top: isActive ? 15 : 800,
        opacity: isActive ? 1 : 0,
        transition: `top 1.5s, opacity 0.6s`
    }

    return (
        <div style={containerStyle}>
            {message}
        </div>
    )
}
