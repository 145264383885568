﻿import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";
import {ResultRequestApiService} from "@vivli/features/results-request/infrastructure/service";
import {IResultRequestService} from "@vivli/features/results-request/infrastructure/interface";
import React from "react";
import {ResultRequestServiceContext} from "@vivli/features/results-request/infrastructure/context";

export const ResultRequestServiceContextWrapper = ({children}: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new ResultRequestApiService(apiOptions);

    const provider: IResultRequestService = {
        ...api
    }

    return <ResultRequestServiceContext.Provider value={provider}>
        {children}
    </ResultRequestServiceContext.Provider>
}
