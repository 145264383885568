import React, {CSSProperties, useState} from 'react';
import {ButtonComponent, CheckboxComponent} from "@vivli/shared/components";
import {Color} from "@vivli/shared/theme";
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    padding: 15,
}
const checkboxStyle: CSSProperties = {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    paddingTop: 10,
}
const btnContainerStyle: CSSProperties = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
}

const textStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
}

interface FileUploadWarningComponentProps {
    handleCancel: () => void;
    handleConfirm: () => void;
}


export const FileUploadWarningComponent = ({handleCancel, handleConfirm}
                                               : FileUploadWarningComponentProps) => {

    const [IPDCheck, setIPDCheck] = useState(false);

    return (
        <div style={containerStyle}>
            <div style={textStyle}>
                <div>
                    The Study Documents tab is to be used for supporting documents that will
                    help researchers determine if this study will support their research.
                    This tab must not be used for uploading Individual Participant Data (IPD)
                    since files on this tab are available to any user without placing a request.
                </div>
                <div>
                    If the file you just asked to load contains Individual Participant Data, please
                    click the Cancel button below. Individual Participant Data should be loaded only
                    in the "Data Package" tab. If the file does not contain Individual Participant Data,
                    check "This is not IPD, load this data" and click on OK
                </div>
            </div>

            <div>
                <CheckboxComponent
                    label={'This is not IPD, load this data'}
                    style={checkboxStyle}
                    checked={IPDCheck}
                    onChange={e => setIPDCheck(e.target.checked)}
                />
            </div>
            <div style={btnContainerStyle}>
                <ButtonComponent
                    onClick={handleConfirm}
                    disabled={!IPDCheck}
                    dataId={DTICommonConst.OkButton}
                >
                    Ok
                </ButtonComponent>

                <ButtonComponent
                    style={{backgroundColor: Color.VIVLI_RED}}
                    onClick={handleCancel}
                    dataId={DTICommonConst.CancelButton}
                >
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    );

}
