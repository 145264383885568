import React, {createContext, useContext} from "react";
import {IPicoSearchOptionsContext} from "@vivli/features/search/infrastructure/interface";

export const PicoSearchOptionsContext = createContext<IPicoSearchOptionsContext>(null);

export const usePicoSearchOptionsContext = () => useContext(PicoSearchOptionsContext);




