﻿import {DataRequestPermissionsService} from "@vivli/features/data-requests/infrastructure/service";
import {
    DataRequestStatusEnum,
    RequestedStudyTypeEnum,
    StudyRequestStatusEnum
} from "@vivli/features/data-requests/infrastructure/enum";
import {
    IDataRequest,
    IRequestedStudy,
    IRequestedStudyModification
} from "@vivli/features/data-requests/infrastructure/interface";
import {useRequestedStudyPermissions} from "./use-requested-study-permissions.hook";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";

export function useRequestedStudyBehavior() {

    const {userIsDataProviderOrgAdmin, userIsDatasetOwner} = useRequestedStudyPermissions();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const optionsConstants = {
        thisRequestOnlyIpd: 'tro',
        downloadable: 'downloadable',
        skipIrpReview: 'skip'
    }

    const optionOnTitlesConstants = {
        thisRequestOnlyIpd: 'Data loaded for this request only',
        downloadable: 'Data package downloadable                                    ',
        skipIrpReview: 'Bypass IRP Review          '
    }

    const optionOffTitlesConstants = {
        thisRequestOnlyIpd: 'Data loaded from stored data     ',
        downloadable: 'Data available in secure research environment only',
        skipIrpReview: 'Include standard IRP Review'
    }

    const statusConstants = {
        dataPackageProvided: 'Data Package Provided to Requester',
        awaitingValidation:  'Awaiting DUA Validation before Data Package Upload',
        validatedAndReadyForUpload: 'Data to be provided by Data Contributor',
        willUseExistingTemplateData: 'Data already on platform',
        needsLoadingAfterApproval: 'Data to be loaded after approval',
    }

    const getStudyIdForDisplay =(requestedStudy: IRequestedStudy) =>{
        let displayStudyId =  requestedStudy.nctId ? requestedStudy.nctId : requestedStudy.sponsorProtocolId
        //case below for unlisted studies
        displayStudyId = displayStudyId !== 'false' ? displayStudyId : requestedStudy.sponsorProtocolId;
        return displayStudyId;
    }

    //returns messages customized to current status and user;
    //most of these are for users who don't have rights to upload data
    //in cases not shown, message remains null
    const getStatusMessage = (activeRequestedStudy: IRequestedStudy,
                              activeDataRequest: IDataRequest,
                              dataPackageStatus: boolean,
                              user: any
    ) => {
        let msg = null;
        const canUpload = DataRequestPermissionsService.canUpload(activeDataRequest, activeRequestedStudy, user);
        const requestedStudyHasData = activeRequestedStudy.studyRequestStatus === StudyRequestStatusEnum.IPDProvided;
        const uploadNeededButNotByMe = !canUpload && !requestedStudyHasData;

        if (requestedStudyHasData) {
            return statusConstants.dataPackageProvided;
        }

        switch (activeDataRequest.status) {
            case DataRequestStatusEnum.Draft:
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.AwaitingIRPApproval:
                if (uploadNeededButNotByMe) {
                    msg =
                        dataPackageStatus
                            ? statusConstants.willUseExistingTemplateData
                            : statusConstants.needsLoadingAfterApproval
                }
                break;
            case DataRequestStatusEnum.Approved:
            case DataRequestStatusEnum.AwaitingDUAValidation:
                if (uploadNeededButNotByMe) {
                    msg = statusConstants.awaitingValidation;
                }
                break;
            case DataRequestStatusEnum.DUAValidated:
            case DataRequestStatusEnum.PartiallyFulfilled:
                if (uploadNeededButNotByMe) {
                    msg = statusConstants.validatedAndReadyForUpload
                }
                break;
            default:
                break;
            }

            return msg;
        }

    const canEditAdvancedOptions = (activeDataRequest: IDataRequest,
                                    activeRequestedStudy: IRequestedStudy,
                                    user: any) => {

        let canEdit = false;
        const unlistedStudy = activeRequestedStudy.requestedStudyType === RequestedStudyTypeEnum.Unlisted
        const isDataOwner = userIsDataProviderOrgAdmin(activeRequestedStudy) ||
            userIsDatasetOwner(activeRequestedStudy);
        if (activeRequestedStudy.studyRequestStatus === StudyRequestStatusEnum.IPDProvided
            || (unlistedStudy  && !user.isVivliAdmin)) {
            return canEdit;
        }

        switch (activeDataRequest.status) {
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.RejectedByDataProvider:
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
            case DataRequestStatusEnum.AwaitingIRPApproval:
            case DataRequestStatusEnum.RevisionsRequestedByIRP:
            case DataRequestStatusEnum.RejectedByIRP:
            case DataRequestStatusEnum.Approved:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.DUAValidated:
            case DataRequestStatusEnum.DUAReturnedToRequester:
            case DataRequestStatusEnum.PartiallyFulfilled:
                canEdit = (user.isVivliAdmin || isDataOwner);
                break;
        }

        return canEdit;
    }

    const canShowAdvancedOptionsStatus = (activeDataRequest: IDataRequest) => {

        let canShow = false;
        switch (activeDataRequest.status) {
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.AwaitingIRPApproval:
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
            case DataRequestStatusEnum.RejectedByDataProvider:
            case DataRequestStatusEnum.RevisionsRequestedByIRP:
            case DataRequestStatusEnum.RejectedByIRP:
            case DataRequestStatusEnum.Approved:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.DUAValidated:
            case DataRequestStatusEnum.DUAReturnedToRequester:
            case DataRequestStatusEnum.PartiallyFulfilled:
            case DataRequestStatusEnum.Fulfilled:
                canShow = true;
                break;
        }
        return canShow;
    }

    const canChangeIrpReview = (activeDataRequest: IDataRequest,
                                    activeRequestedStudy: IRequestedStudy,
                                    isVivliAdmin: boolean) => {

        let canEdit = false;
        switch (activeDataRequest.status) {
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
            case DataRequestStatusEnum.RejectedByDataProvider:
            case DataRequestStatusEnum.SubmittedToVivli:
                canEdit = isVivliAdmin
                break;
        }

        return canEdit;
    }

    const getOptionsToModify = (requestedStudy: IRequestedStudy,
                                dataRequest: IDataRequest,
                                isVivliAdmin: boolean,
                                options: string[]) => {


        const isThisRequestOnly = options.includes(optionsConstants.thisRequestOnlyIpd);

        //only vivli admin in CT mode allowed to see an isDownloadable checkbox,
        //so don't make any change for non-vivli admin
        //AND don't make any changes for vivli admin in AMR mode
        const isDownloadable = isVivliAdmin && !isAmr
            ? options.includes(optionsConstants.downloadable)
            : requestedStudy.downloadableRequestedStudyIPDDataPackage;

        const skipOptionSelected = options.includes(optionsConstants.skipIrpReview);

        let changeSkip = null;
        //only make the SkipIRPReview change if it is a change from current!
        if ((skipOptionSelected && !requestedStudy.bypassIRPReview)
            || (!skipOptionSelected && requestedStudy.bypassIRPReview)){
            changeSkip = skipOptionSelected;
        }

        const reqStudyMods: IRequestedStudyModification = {
            dataRequestId: dataRequest.id,
            studyId: requestedStudy.studyId,
            thisRequestOnlyIpd: isThisRequestOnly,
            dataPackageDownloadable: isDownloadable,
            skipIrpReview: changeSkip
        }
        return reqStudyMods;

    }

    return {
        getStatusMessage,
        getStudyIdForDisplay,
        canEditAdvancedOptions,
        getOptionsToModify,
        optionsConstants,
        canShowAdvancedOptionsStatus,
        canChangeIrpReview,
        optionOnTitlesConstants,
        optionOffTitlesConstants
    }
}
