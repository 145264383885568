import { IConfig } from '@vivli/shared/infrastructure/interface';
import { IAnonmyousApi } from '@vivli/core/infrastructure/interface';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import {
  AmrTranslationsConstant,
  DefaultTranslationsConstant,
} from '@vivli/shared/infrastructure/constants';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { RestApi } from '@vivli/core/infrastructure/rest';
import { Observable } from 'rxjs';

const getAssignedAppTypeTranslations = (
  assignedAppType: AssignedAppTypeEnum
) => {
  if (assignedAppType === AssignedAppTypeEnum.Amr) {
    return AmrTranslationsConstant;
  }

  return DefaultTranslationsConstant;
};

export class AnonmyousApiService extends RestApi implements IAnonmyousApi {
  getConfig = (): Observable<IConfig> => {
    return this.handleGet<IConfig>('/config', { bypassAuth: true });
  };

  getTranslations = (assignedAppType: AssignedAppTypeEnum): Observable<any> => {
    const json = getAssignedAppTypeTranslations(assignedAppType);
    return new Observable((observer) => {
      observer.next(json);

      observer.complete();
    });
  };

  getStudyMetadata = (searchResultId: string): Observable<IStudy> => {
    return this.handleGet<IStudy>(`api/studies/${searchResultId}/metadata`, {
      bypassAuth: true,
    });
  };
}
