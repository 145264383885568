﻿import React, {CSSProperties, useEffect, useState} from "react";
import {Msal2EventTypeEnum, useAuthService} from "@vivli/core/authentication";
import {useOrganizationsService} from "@vivli/features/organizations/infrastructure/context";
import {first} from "rxjs/operators";
import {ButtonComponent, CopyrightComponent, LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {Styles} from "@vivli/shared/theme";
import {AcceptedOrgInviteComponent} from "./accepted-org-invite.component";

const center500Style: CSSProperties = {
    textAlign: 'center',
    width: 500
}

interface AcceptOrgInviteModalProps {
    orgId: string
}
export const AcceptOrgInviteModal = (
{
    orgId
}:AcceptOrgInviteModalProps
) => {
    const orgsService = useOrganizationsService();
    const authService = useAuthService();

    const [accepted, setAccepted] = useState(false);
    const [isAcceptingOrg, setIsAcceptingOrg] = useState(false);
    const [error, setError] = useState(null);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [acceptedInvitation, setAcceptedInvitation] = useState(null);

    const handleOrgInvite = () => {
        setIsAcceptingOrg(true);
        orgsService.acceptOrganizationInvite(orgId)
            .pipe(first())
            .subscribe((acceptedInvite) => {
                setAcceptedInvitation(acceptedInvite);
                setAccepted(true);
                setIsAcceptingOrg(false);
            }, (error) => {
                setAccepted(false);
                setError(error);
                setIsAcceptingOrg(false);
            });
    }

    useEffect(() => {
        orgsService.getOrganization(orgId)
            .pipe(first())
            .subscribe(org =>{
                setCurrentOrg(org)
            });
    }, [orgId])

    useEffect(() => {
        const authSub = authService.authEvents.subscribe(event => {
            switch (event.type) {
                case Msal2EventTypeEnum.UserCancelledLogin:
                    break;
                case Msal2EventTypeEnum.SignedUpEvent:
                case Msal2EventTypeEnum.UserLoginEvent:
                    handleOrgInvite();
                    break;
            }
        });

        return () => {
            authSub.unsubscribe();
        }
    }, [])

    if (isAcceptingOrg) {
        return <LoadIndicatorCenteredComponent />;
    } else if (accepted) {
        return <AcceptedOrgInviteComponent acceptedInvitation={acceptedInvitation} />
    }

    return (
        <div style={center500Style}>
            Congratulations, you're almost done with accepting the invitation.
            <br/>It looks like you haven't logged in yet, you'll need to do that now.
            <br/><br/>
            Be sure to sign up with the email address <strong>{decodeURIComponent(location.search.split('=')[1])}</strong> or
            your acceptance of this invitation will not work.
            <br/><br/><br/>
            <ButtonComponent
                style={{...Styles.Button.BUTTON_VIVLI_BLUE}}
                onClick={() => authService.signUp()}>
                Create Account
            </ButtonComponent><br/><br/>
            <ButtonComponent
                style={{...Styles.Button.BUTTON_LIGHT_VIVLI_BLUE}}
                onClick={() => authService.login()}>
                Existing Account
            </ButtonComponent>

            {error &&
                <div>
                    <div style={{color: 'red'}}><br/>Error: {error}</div>
                </div>
            }

            <CopyrightComponent />
        </div>
    );
}
