import {IDataRequestHints} from "@vivli/shared/infrastructure/interface";

export const DataRequestHintsConstant: IDataRequestHints = {
    researchTeam:
        {
            sectionDescription: 'Please note that a statistician/data scientist with a degree in statistics or a related discipline should be part of the research team.\n\nFees, including licenses, are paid by the study sponsors for research team members to access data on the website and use the statistical software. The number of licenses for each research proposal may be limited to a maximum of five.\n\nFor this section, please include all researchers on your team. We should be notified where there is a change in membership of the research team.\n\nUp to 20 researchers can be entered.\n\n',
            leadResearcherTeamMemberDetail: {
                orcidId: 'ORCID iD # is a persistent digital identifier that distinguishes you from every other researcher and, through integration in key research workflows such as manuscript and grant submission, supports automated linkages between you and your professional activities ensuring that your work is recognized.\n\n',
                qualifications: 'Please make sure to include all educational and professional criteria relevant to the proposed research, for the lead researcher and statistician.  It is particularly important to elaborate on the qualifications of the statistician.  If the designated statistician does not have a degree in statistics or biostatistics but has conducted meta-analysis, please list these publications.',
            },
            researchTeamDetails: [
                {
                    orcidId: 'ORCID iD # is a persistent digital identifier that distinguishes you from every other researcher and, through integration in key research workflows such as manuscript and grant submission, supports automated linkages between you and your professional activities ensuring that your work is recognized.\n\n',
                },
            ],
            statisticianTeamMemberDetail: {
                orcidId: 'ORCID iD # is a persistent digital identifier that distinguishes you from every other researcher and, through integration in key research workflows such as manuscript and grant submission, supports automated linkages between you and your professional activities ensuring that your work is recognized.\n\n',
                qualifications: 'Please make sure to include all educational and professional criteria relevant to the proposed research, for the lead researcher and statistician.  It is particularly important to elaborate on the qualifications of the statistician.  If the designated statistician does not have a degree in statistics or biostatistics but has conducted meta-analysis, please list these publications.',
            },
        },
    researchProposal: {
        sectionDescription: 'Provide a description of the desired outcome of the research you are proposing to carry out, the design of the study, the timeline and your plans to distribute and/or publish the results of your study.',
        laySummary: 'Please provide a plain English summary of the proposed research that is suitable for a general or lay audience, clarifies the design, and explains the relevance of the research project to science and public health. Please use the following sub-headings:\n\n- The background to the research\n- Why does this research need to be done now?\n- How many patients / members of the public are potentially affected?\n- Provide references to prior work on the topic, if applicable\n- Objective\n- Provide a description of the specific aims of the project, including the specific hypotheses to be evaluated\n- How the research will add to medical science or patient care\n- How the research will be conducted\n- What design and methods have you chosen and why? (in brief)\n- How the findings will be interpreted and communicated to the public (publication plan)\n- Provide a structured abstract for the proposed work\n',
        purposeOfAnalysis: 'What is the purpose of the analysis being proposed? Please select all that apply.',
        researchProposalStudyDesign:
            {
                briefDescription: 'Please provide a brief description of the study design. For example: case-control, cohort, cross-sectional, historical controlled, hybrid designs, meta-analysis, or pooled analysis. Please also provide a description of the study population or populations for the proposed research. For example: the study arms from the requested clinical studies; intent-to-treat or per-protocol populations; the inclusion and exclusion criteria for any cohort or subgroup analysis.\n\nPlease describe the endpoints of the requested studies that will be analyzed. To ensure the use of the data aligns with the informed consent provided by clinical study participants, research proposals must relate to the intervention or disease that was the subject of the original clinical studies.\n\nPlease describe the specific outcomes\' elements and how they will be categorized/defined for your study, including Domain (e.g., anxiety), Specific measurement (e.g., Hamilton Anxiety Rating Scale), Specific Metric (e.g., change from baseline), Method of Aggregation (e.g., mean), and Time-point(s) (e.g., 3 and 6 months):\n\nMain Predictor/Independent Variable and how it will be categorized/defined for your study:\n\nOther variables of interest that will be used in your analysis and how they will be categorized/defined in your study (i.e. genders, age groups, ethnic groups):\n\nProvide an estimation of key milestone dates for the proposed study, including anticipated project start date analysis completion date. Please note: if your data request is approved, the Terms of Use Agreement allows for access for a 12-month period, with the possibility of an extension.\n\n',
                elementsforOutcomes: 'Please describe the specific outcomes\' elements and how they will be categorized/defined for your study, including Domain (e.g., anxiety), Specific measurement (e.g., Hamilton Anxiety Rating Scale), Specific Metric (e.g., change from baseline), Method of Aggregation (e.g., mean), and Time-point(s) (e.g., 3 and 6 months):\n\n',
                mainPredictorIndependentVariable: 'Main Predictor/Independent Variable and how it will be categorized/defined for your study:\n\n',
                otherVariablesOfInterest: 'Other variables of interest that will be used in your analysis and how they will be categorized/defined in your study (i.e. genders, age groups, ethnic groups):\n\n',
            },
        projectTimeline: {
            sectionDescription: 'Provide an estimation of key milestone dates for the proposed study, including anticipated project start date analysis completion date. Please note: if your data request is approved, the Terms of Use Agreement allows for access for a 12-month period, with the possibility of an extension.\n\n',
        },
        disseminationAndPublicationPlan: 'Provide a description of anticipated products and target audience, including expectation for study manuscripts and potentially suitable journals for submission of the completed research project. Include how the findings will be interpreted and communicated to the public (publication plan) You may provide a structured abstract for the proposed work.',
    },
    statisticalAnalysisPlan: {
        sectionDescription: 'Describe how you will analyze the clinical data that you have requested.',
        description: 'Describe how you will analyze the requested clinical study data, including descriptive, bivariate and multivariable analyses, and any other planned advanced analyses (such as propensity score methods, Kaplan-Meier or Cox modeling approaches, non-parametric testing). The following is provided as guidance for items to include in the statistical analysis plan:\n\n- Effect measure of interest (e.g. for inferential studies: risk or rate ratio, risk or rate difference, absolute difference; for descriptive studies: rate with confidence intervals)\n- Methods to control for bias (e.g. restriction, matching, stratification, covariate adjustment)\n- Assumptions and any planned adjustments for covariates or meta-regression or modelling of covariates\n- The statistical approach (e.g. Bayesian or frequentist (classical), fixed or random effects)\n- Meta-analysis approach where applicable (e.g. random effects meta-analysis, stratified meta-analysis)\n- Statistical tests and methods (e.g. Fisher’s exact test, Kaplan-Meier curves, log-rank test to compare groups, multiplicity adjustments)\n- Power to detect an effect, or the precision of the effect estimate given the sample size available\n- Statistical power calculations and levels of significance\n- Model fit tests, sensitivity or heterogeneity analyses (e.g. Chi-Squared Test, I squared statistic)\n- Analysis of subgroups (e.g. by age, disease status, ethnicity, socioeconomic status, presence or absence or co-morbidities); different types of intervention (e.g. drug dose)\n- Handling of missing data\n',
        analysisLocation: 'Country/countries where the analysis will be conducted\n',
    },
    funding: {
        sectionDescription: 'Provide information about the sources of funding.',
    },
    conflictsOfInterest: {
        sectionDescription: 'For each member of the research team, please provide information on financial relationships that could be perceived to influence the planning, conduct or interpretation of the proposed research.\n\nPlease include research grants from governments or government agencies, other grants or donations, funding from employers through employment contracts, other contracts, consultancies, honoraria and other payments that will be used for the proposed research. This should not be limited to financial relationships with the study sponsors involved in this initiative and other pharmaceutical or biotechnology companies within the last three years. It should include:\n\n- Board memberships\n- Consultancies\n- Employments\n- Grants/grants pending\n- Patents (planned, pending or issued)\n- Royalties\n- Stocks or shares (including options)\n\nPlease also include any other (e.g. non-financial real or potential conflicts of interest that could be perceived to influence the planning, conduct or interpretation of the proposed research. For example potential biases based on pre-existing personal views, academic or commercial competition, personal relationships or institutional affiliations.\n\nIf none, please enter “None.”\n\nFor each conflict, please summarize how real or potential conflicts of interest related to funding of the proposed research, other financial relationships or other real or potential conflicts of interest will be managed. For example through disclosure of interests when the research is presented and published.\n\n',
    },
    requirementsAndAttestations: {
        sectionDescription: 'Before submitting the request for data, you must check the box on this tab to certify that the information you are providing is complete and accurate.',
        hasAgreedToVivliDataAccessAgreement: 'Please check the box below to acknowledge that access to data will only be provided in the secure access data system. Select statistical software (e.g. STATA and R) is provided. There are controls to prevent the original study datasets from being downloaded.\n\n',
        hasCertifiedCompleteAndAccurate: 'Please check the box below to indicate that you as the Lead Researcher certify that the information provided is complete and accurate, and that you assume full responsibility for the research.',
    },
    otherInformation: 'This tab allows an opportunity to provide any other information that might be relevant to the research study, either entered on the form, or as an uploaded attachment.',
};
