import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {VmStatusEnum} from '@vivli/features/virtual-machine/infrastructure/enum';
import {Size, Styles} from '@vivli/shared/theme';
import {useVmStatusMapHook} from '@vivli/features/data-requests/infrastructure/hook';
import {WaitingMessageComponent} from '../waiting-message.component';
import {useResearchEnvironmentContext} from '@vivli/features/data-requests/infrastructure/context';
import {LoadIndicatorComponent} from '@vivli/shared/components';
import {AssetsConstant} from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    height: '100%',
    fontWeight: Size.FontWeight.XLight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '600px',
};

const statusContainerStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    fontSize: Size.FontSize.XLarge,
    flexDirection: 'column',
};

const waitingContainerStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    position: 'relative',
    height: 80,
    overflow: 'hidden',
};

const retryContainerStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    marginTop: 20,
};

const loadContainerStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    minHeight: '100px',
};

const titleStyle: CSSProperties = {marginBottom: '20px'};

const linkStyle: CSSProperties = {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
};

const genericWaitingMessages = [
    'This is a long-running operation and may take a while...',
    'We will send you an email when the process completes.',
    'This page will be updated when the operation completes.',
    'You should grab a cup of coffee or do something else while waiting...',
];

const addingPackagesWaitingMessages = [
    'Adding packages may take a while...',
    'Adding packages is complete when this screen disappears...',
];

export const VmProvisioningViewComponent = () => {
    const [activeMessage, setActiveMessage] = useState(0);
    const activeIndexRef = useRef(0);
    const getVmStatus = useVmStatusMapHook();
    const {
        retryProvisionVm,
        deProvisionVm,
        startVm,
        stopVm,
        addDataPackagesToVm,
        provisionStatus,
        subscribeToVmMessages
    } = useResearchEnvironmentContext();

    const vmStatus = getVmStatus(provisionStatus);
    const waitingMessages =
        provisionStatus === VmStatusEnum.AddingDataPackages
            ? addingPackagesWaitingMessages
            : genericWaitingMessages;

    const handleOnRetry = (e) => {
        e.stopPropagation();

        switch (provisionStatus) {
            case VmStatusEnum.Provisioning:
                retryProvisionVm();
                break;
            case VmStatusEnum.DeProvisioning:
                deProvisionVm();
                break;
            case VmStatusEnum.Starting:
                startVm();
                break;
            case VmStatusEnum.Stopping:
                stopVm();
                break;
            case VmStatusEnum.AddingDataPackages:
                addDataPackagesToVm();
                break;
        }
    };

    const updateActiveMessage = () => {
        const nextMsgInt = activeIndexRef.current + 1;

        if (waitingMessages.length === nextMsgInt) {
            // we're on the last message, start over
            setActiveMessage(0);
            activeIndexRef.current = 0;
        } else {
            // go to next message
            setActiveMessage(nextMsgInt);
            activeIndexRef.current = nextMsgInt;
        }
    };

    const updateProcessingMessages = (message: any) => {
        if (provisionStatus === VmStatusEnum.AddingDataPackages) {
            waitingMessages[0] = message.data;
        } else {
            waitingMessages[3] = message.data;
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateActiveMessage();
        }, 5000);

        subscribeToVmMessages(updateProcessingMessages);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div style={containerStyle}>
            <div style={statusContainerStyle}>
                <div style={titleStyle}>
                    Your Research Environment is {vmStatus}
                </div>
                <img src={AssetsConstant.VM_ICON_INACTIVE} width={200}/>
                <br/>
            </div>

            <div style={loadContainerStyle}>
                <LoadIndicatorComponent/>
            </div>

            <div style={waitingContainerStyle}>
                {waitingMessages.map((message, i) => (
                    <WaitingMessageComponent
                        key={i}
                        message={message}
                        isActive={i === activeMessage}
                    />
                ))}
            </div>

            <div style={retryContainerStyle}>
                If you think something has gone wrong, you can always &nbsp;
                <span onClick={handleOnRetry} style={linkStyle}>
                    Retry {vmStatus}
                </span>
            </div>
        </div>
    );
};
