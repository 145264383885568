﻿import React, {CSSProperties} from 'react';
import {Color, Size} from '@vivli/shared/theme';
import {NavPanelComponent} from './nav-panel.component';

interface NavTabsComponentProps {
    panelItems: Array<{ name?: string; title?: string; icon?: { active: any; inactive: any }; url?: string; defaultTab?: string }>;
}
export const NavTabsComponent = ({ panelItems }: NavTabsComponentProps) => {
    const panelGap = 2;
    const actualAdminLeftPanelWidth = Size.ADMIN_LEFT_PANEL_WIDTH + panelGap / 2;

    const containerStyle: CSSProperties = {
        '@media print': {
            display: 'none',
        },
        height: '100%',
        width: actualAdminLeftPanelWidth,
        backgroundColor: Color.WHITE,
        display: 'grid',
        gridAutoRows: 'minmax(35px, 70px)',
        rowGap: '10px',
        overflowY: 'auto',
        overflowX: 'hidden',
    } as React.CSSProperties;

    return (
        <div style={containerStyle} className={'do-not-print scrolly'}>
            {panelItems?.map((panelItem, index) => (
                <NavPanelComponent
                    key={index}
                    panelItem={panelItem}
                    panelGap={panelGap}
                    actualAdminLeftPanelWidth={actualAdminLeftPanelWidth}
                />
            ))}
        </div>
    );
};
