﻿import React, {useEffect, useState} from "react";
import {DropdownFormFieldComponent} from "@vivli/shared/components";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {useVivliConfig} from "@vivli/core/infrastructure/hook";

export const AmrResearcherLocationComponent = ({style}) => {
    const vivliConfig = useVivliConfig();
    const [countryItems, setCountryItems] = useState<IDropdownMenuItem[]>();

    useEffect(() => {
        if (vivliConfig?.countries?.length > 0) {
            const menuItems = vivliConfig.countries.map(({name, code, numberSites}) => ({
                value: {name, code, numberSites},
                title: name
            }));
            setCountryItems(menuItems);
        }
    }, [vivliConfig])

    return (
        <DropdownFormFieldComponent
            name={`antimicrobialDetail.amrResearcherDetails.amrResearcherLocation`}
            label={"Lead Researcher's Location"}
            items={countryItems}
            style={style}
            objectKey={'name'}
        />
    )
}
