import React, { useEffect, useState } from 'react';
import {
    IDataRequestsByType,
    IDataRequestSummary,
} from '@vivli/features/data-requests/infrastructure/interface';
import { DataRequestsPageComponent } from '@vivli/features/data-requests/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ITab, IUser } from '@vivli/shared/infrastructure/interface';
import { DataRequestFilterService } from '@vivli/features/data-requests/infrastructure/service';
import {useDataRequestDefaultTabHook, useMyDataRequests} from '@vivli/features/data-requests/infrastructure/hook';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useNavigate } from 'react-router-dom';
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";

enum TabTitleEnum {
    draft = 'Draft',
    active = 'Active',
    notApproved = 'Not Approved',
    withdrawn = 'Withdrawn',
    archived = 'Archived',
}

enum TabRouteEnum {
    archived = 'archived',
    withdrawn = 'withdrawn',
    notApproved = 'NotApproved',
    active = 'active',
    draft = 'draft',
}

export const MyDataRequestsFeature = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const getTabs = (user: IUser, requestsByType?: IDataRequestsByType) => {
        const requestTabs: ITab<IDataRequestSummary[]>[] = [
            {
                id: TabRouteEnum.draft,
                sortOrder: 1,
                title: TabTitleEnum.draft,
                hint: 'Requests you have not submitted yet',
                data: requestsByType?.draftRequests,
                count: requestsByType?.draftRequests?.length,
            },
            {
                id: TabRouteEnum.active,
                sortOrder: 2,
                title: TabTitleEnum.active,
                hint: 'Approved requests, and requests awaiting approvals, data upload, or resubmission',
                data: requestsByType?.active,
                count: requestsByType?.active?.length,
            },
            {
                id: TabRouteEnum.notApproved,
                sortOrder: 4,
                title: TabTitleEnum.notApproved,
                data: requestsByType?.notApproved,
                count: requestsByType?.notApproved?.length,
            },
            {
                id: TabRouteEnum.withdrawn,
                sortOrder: 5,
                title: TabTitleEnum.withdrawn,
                data: requestsByType?.withdrawn,
                count: requestsByType?.withdrawn?.length,
            },
            {
                id: TabRouteEnum.archived,
                sortOrder: 6,
                title: TabTitleEnum.archived,
                data: requestsByType?.archived,
                count: requestsByType?.archived?.length,
            },
        ];

        return requestTabs.sort((a, b) => a.sortOrder - b.sortOrder);
    };
    const [tabs, setTabs] = useState<ITab<IDataRequestSummary[]>[]>();
    const [defaultTab, setDefaultTab] = useState<string>();
    const user = useActiveUser();
    const navigate = useNavigate();
    const { getDataRequest } = useDataRequestsService();
    const { organizations } = useOrganizations();
    const { getDefaultTabRoute } = useDataRequestDefaultTabHook();

    const { myDataRequests, isLoadingMyDataRequests } = useMyDataRequests();

    const initDefaultTab = () => {
        const path = window.location.pathname;
        let tabRoute = path
            .toLowerCase()
            .replace('/mydatarequests/', '')
            .toLowerCase();

        if (tabRoute?.length <= 0) {
            tabRoute = 'draft';
        }

        setDefaultTab(tabRoute);
    };

    const handleRowClick = (
        dataRequest: IDataRequestSummary,
    ) => {
        const isDraft = dataRequest.status === 'Draft';

        getDataRequest(dataRequest.id).subscribe({
            next: (dataRequest) => {
                const defaultRoute = getDefaultTabRoute(dataRequest, organizations);
                navigate(defaultRoute, { state: { isEditable: isDraft }});
            },
        });
    };

    const handleTabClick = (tab: ITab<IDataRequestSummary[]>) => {
        navigate(`/mydatarequests/${tab.id}`);
    };

    useEffect(() => {
        initDefaultTab();
        setTabs(getTabs(user));
    }, [user]);

    useEffect(() => {
        if (!myDataRequests) {
            return;
        }

        const requestsByType = DataRequestFilterService.filterAllRequestsByType(
            myDataRequests as any,
            user
        );
    const updatedTabs = getTabs(user, requestsByType);

        setTabs(updatedTabs);
    }, [myDataRequests, user]);

    return (
        <DataRequestsPageComponent
            title={`My Data Requests (${myDataRequests?.length || 0})`}
            displayType="myrequests"
            tabs={tabs}
            defaultTab={defaultTab}
            onRowClick={handleRowClick}
            onTabClick={handleTabClick}
            hideFloatingFilter={true}
            dataRefreshing={isLoadingMyDataRequests}
        />
    );
};
