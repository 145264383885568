import React, {CSSProperties, forwardRef, useImperativeHandle, useRef} from 'react';
import {RadioGroupComponent} from "./radio-group.component";
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {FormFieldTypeEnum} from "@vivli/shared/infrastructure/enum";
import {BaseInputFieldComponent} from "./base-input-field.component";

interface YesNoComponentProps extends IFormField {
    defaultValue?: boolean;
    value?: boolean;
    onChange?: (value: boolean) => void;
    options?: string[]; //option 1: true, option2: false
    divStyle?: CSSProperties;
}

export const YesNoRadioComponent = forwardRef((props: YesNoComponentProps, ref) => {
    const {
        defaultValue,
        value,
        onChange,
        readonly,
        options,
        divStyle
    } = props;
    const inputRef = useRef<HTMLDivElement>();
    useImperativeHandle(ref, () => inputRef.current, [inputRef])

    const optionsToUse = options ? options : ['Yes', 'No'];
    const valueToUse = value !== undefined ? value : defaultValue;
    const defaultOption = valueToUse ? optionsToUse[0] : optionsToUse[1];

    const handleOnChange = (value: string) => {
        const selectedValue = value === optionsToUse[0];
        onChange && onChange(selectedValue);
    }

    return (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.YesNoRadio}
            inputRef={inputRef}
        >
            <RadioGroupComponent
                defaultOption={defaultOption}
                options={optionsToUse}
                onChange={handleOnChange}
                horizontal={true}
                style={{padding: '0', ...divStyle}}
                inputRef={inputRef}
                readonly={readonly}
            />
        </BaseInputFieldComponent>
    )
})
