﻿import React from "react";
import {IUser} from "@vivli/shared/infrastructure/interface";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {TextFormFieldComponent, oneAcrossStyle} from "@vivli/shared/components";
import {Styles} from "@vivli/shared/theme";
import {AdminCommonSubmissionDetailsComponent} from "./admin-common-submission-details.component";
import {StudyDatasetRequestTypeComponent} from "../study-dataset-request-type.component";
import {StudyDatasetIpdOptionsComponent} from "./study-dataset-ipd-options-component";
import {AdminAmrAdministrativeSharedComponent} from "./admin-amr-administrative-shared.component";

interface AdminAmrAdministrativeDetailsComponentProps  {
    user?: IUser,
    study?: IStudy,
}

export const AdminAmrAdministrativeDetailsComponent = (
    {
        user, study}: AdminAmrAdministrativeDetailsComponentProps
) => {
    const isVivliAdmin = user.isVivliAdmin;
    return (
        <div style={{width: '100%'}}>
            <AdminAmrAdministrativeSharedComponent isVivliAdmin={isVivliAdmin} study={study}/>
            {isVivliAdmin &&
                <div>
                    <div style={Styles.FORM_ROW}>
                        <TextFormFieldComponent
                            name='studyTitle'
                            label='Dataset Title'
                            style={oneAcrossStyle}
                            readonly={false}
                        />
                    </div>
                    <AdminCommonSubmissionDetailsComponent
                        user={user}
                        study={study}/>
                    <StudyDatasetRequestTypeComponent study={study}/>
                    <StudyDatasetIpdOptionsComponent extendedMetadataOption={false}/>
                </div>
                }
        </div>
    );
}
