import React, {CSSProperties} from 'react';
import {IFormField} from "@vivli/shared/infrastructure/interface";
import {BaseControlledFormFieldComponent} from "./base-controlled-form-field.component";
import {YesNoRadioComponent} from "../input/yes-no-radio.component";

interface YesNoRadioFormFieldComponentProps extends IFormField {
    name: string;
    options?: string[]; //option 1: true, option2: false
    divStyle?: CSSProperties;
}

export const YesNoRadioFormFieldComponent = (props: YesNoRadioFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <YesNoRadioComponent {...props} />
        </BaseControlledFormFieldComponent>
    )
}
