﻿import {useEffect, useState} from 'react';
import {finalize, first, map} from 'rxjs/operators';
import {useListingRequestService} from '@vivli/features/listing-request/infrastructure/context';
import {IListingRequestSummary} from '@vivli/features/listing-request/infrastructure/interface';
import {DataTransformationService} from '@vivli/shared/infrastructure/service';
import {useActiveUser} from '@vivli/core/infrastructure/context';

export function useListingRequestSummaries() {
    const user = useActiveUser();

    const [listingRequestSummaries, setListingRequestSummaries] =
        useState<IListingRequestSummary[]>();

    const [isLoadingListingRequests, setIsLoadingListingRequests] =
        useState(false);
    const listingRequestsService = useListingRequestService();

    useEffect(() => {
        if (!user) {
            return;
        }
        setIsLoadingListingRequests(true);
        const listingRequestSub = listingRequestsService
            .getAllListingRequestSummaries()
            .pipe(
                first((lr) => lr !== null),
                map(DataTransformationService.formatListingRequestSummaries),
                finalize(() => {
                    setIsLoadingListingRequests(false);
                })
            )
            .subscribe((requests) => {
                setListingRequestSummaries(requests)
            });
        return () => {
            listingRequestSub.unsubscribe();
        };
    }, [user]);

    return {
        listingRequestSummaries,
        isLoadingListingRequests,
    };
}
