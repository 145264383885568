import React from 'react';
import {useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {DataRequestViewComponent} from "../data-request-view/data-request-view.component";

export const DataRequestPrintViewComponent = () => {
    const {shouldHideReviewers} = useDataRequestPermissions();
    const {dataRequest, organizations} = useDataRequestContext();

    return <DataRequestViewComponent
        dataRequest={dataRequest as any}
        hideReviewers={shouldHideReviewers(dataRequest, organizations)}
    />
}
