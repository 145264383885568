import {CSSProperties} from 'react';
import {StudiesProvidedComponent} from '../studies-provided.component';
import {ResearchHelpLinkComponent} from '../research-help-link.component';
import {VmStatusEnum} from '@vivli/features/virtual-machine/infrastructure/enum';
import {VmProvisionedButtonsComponent} from './vm-provisioned-buttons.component';
import {VmProvisionedConnectComponent} from './vm-provisioned-connect.component';
import {Color, Size, Styles} from '@vivli/shared/theme';
import {useDataRequestContext, useResearchEnvironmentContext} from '@vivli/features/data-requests/infrastructure/context';
import {AssetsConstant} from '@vivli/shared/infrastructure/constants';
import {useVivliConfig} from "@vivli/core/infrastructure/hook";

const containerStyle: CSSProperties = {
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column',
};

const innerContainerStyle: CSSProperties = {
    ...Styles.CENTERED_FLEX,
    flexDirection: 'column',
    marginBottom: Size.INSET_SIZE,
};

const imageContainerStyle: CSSProperties = {
    marginTop: Size.PADDING,
};

const controlsContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
};

const infoBoxStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    alignItems: "center",
    gap: 10,
    flexDirection: "column",
    margin: "30px"

}
export const VmProvisionedViewComponent = () => {
    const {provisionStatus, authorizedUser, virtualMachine} = useResearchEnvironmentContext();
    const {dataRequest} = useDataRequestContext();
    const vivliConfig = useVivliConfig();
    const currentExpirationDate = dataRequest?.duaDetail?.currentExpirationDate;
    const noChargeExpirationWarningDays = vivliConfig?.noChargeExpirationWarningDays;
    const duaExpirationWarningDays = vivliConfig?.duaExpirationWarningDays;
    const noChargeNumber = vivliConfig?.virtualMachineTypes.find(type =>
        type.vmSize === virtualMachine.size)?.noCharge || null;
    const isVmRunning = provisionStatus === VmStatusEnum.Running;

    function getColorStyle(daysLeft, warningDays) {
        return daysLeft < warningDays ? {color: Color.VIVLI_RED} : {color: "black"};
    }


    function calculateDaysUntilExpiration(expirationDate) {
        const currentDate = new Date();
        const expDate = new Date(expirationDate);

        const diffTime = expDate.getTime() - currentDate.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    }

    function getNoChargePeriod(provisionedDate, noChargeNumber, dataRequest) {
        if (!vivliConfig) {
            return null;
        }
        const currentDate = new Date();
        const provisionedTime = new Date(provisionedDate);

        let startDateToUse: Date = new Date(provisionedTime);
        startDateToUse.setDate(provisionedTime.getDate() + noChargeNumber);

        const statusUpdateVal = dataRequest.statusUpdate?.vmBillingStartDate;
        const billingStartTime = new Date(dataRequest.statusUpdate?.vmBillingStartDate);
        if (billingStartTime && statusUpdateVal) {
            startDateToUse = new Date(billingStartTime);
        }
        const timeDiff = startDateToUse.getTime() - currentDate.getTime();

        if (timeDiff <= 0) {
            //start date was in the past
            return 0;
        } else {
            //start date is in the future
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            return diffDays;
        }
    }

    const duaDaysLeft = calculateDaysUntilExpiration(currentExpirationDate);
    const noChargeDaysLeft = getNoChargePeriod(virtualMachine.provisionedDateTime, noChargeNumber, dataRequest);


    const userNameContainerStyle: CSSProperties = {
        fontSize: Size.FontSize.XLarge,
        fontWeight: Size.FontWeight.Bold,
        backgroundSize: 100,
        position: 'relative',
        marginBottom: 30,
        color: isVmRunning ? Color.VIVLI_TEAL : Color.LIGHTER_GRAY,
    };

    const duaInfoColorStyle = getColorStyle(duaDaysLeft, duaExpirationWarningDays);
    const noChargeExpirationInfoStyle = getColorStyle(noChargeDaysLeft, noChargeExpirationWarningDays);

    return (
        <div style={containerStyle}>
            <div>
                <StudiesProvidedComponent/>
            </div>

            <ResearchHelpLinkComponent isNewVm={false}/>

            <div style={innerContainerStyle}>
                <div style={imageContainerStyle}>
                    <img
                        src={
                            isVmRunning
                                ? AssetsConstant.VM_RUNNING
                                : AssetsConstant.VM_NOT_RUNNING
                        }
                    />
                </div>

                <div style={userNameContainerStyle}>
                    {isVmRunning && (
                        <img
                            style={{marginRight: 15}}
                            src={AssetsConstant.VM_CHECK_MARK}
                            height={27}
                        />
                    )}
                    Research Environment {provisionStatus}

                </div>

                <div style={infoBoxStyle}>
                    <div style={noChargeExpirationInfoStyle}>
                        {noChargeDaysLeft} Days remain in no-charge period.
                    </div>

                    <div style={duaInfoColorStyle}>
                        The Data Use Agreement is valid for another {duaDaysLeft} days.
                    </div>
                </div>


                <div style={controlsContainerStyle}>
                    {isVmRunning && <VmProvisionedConnectComponent/>}

                    <VmProvisionedButtonsComponent/>
                </div>
            </div>
        </div>
    );
};
