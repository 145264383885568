import React, {CSSProperties} from 'react';
import {ExternalLinkMenuComponent} from "./external-link-menu/external-link-menu.component";
import {useConfigService} from "@vivli/core/infrastructure/context";
import {InternalLinkMenuComponent} from "./internal-link-menu/internal-link-menu.component";
import {EnvironmentBannerComponent} from "./environment-banner.component";
import {LogoComponent} from "./logo.component";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {PortalTitleComponent} from "./portal-title.component";

const externalLinksContainerStyle: CSSProperties = {
    maxHeight: 75,
    minHeight: 75,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
}

interface HeaderComponentProps {
    onFindStudiesClick: () => void;
}

export const HeaderComponent = (
    {
        onFindStudiesClick
    }: HeaderComponentProps
) => {
    const {viewRequestResultsOnly} = useConfigService();
    const assignedAppType = useAssignedAppType();

    return (
        <div className='do-not-print' style={{zIndex: 101}}>
            <EnvironmentBannerComponent />

            <div style={externalLinksContainerStyle}>
                <LogoComponent />
                <PortalTitleComponent />
                {!viewRequestResultsOnly && assignedAppType !== AssignedAppTypeEnum.Amr && (
                    <ExternalLinkMenuComponent onFindStudiesClick={onFindStudiesClick} />
                )}
            </div>
            <InternalLinkMenuComponent />
        </div>
    )
}
