﻿import React, {CSSProperties} from 'react';
import {DropdownFieldComponent} from "../input/dropdown-field.component";
import {TextFieldComponent} from "../input/text-field.component";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {ButtonComponent} from "../buttons";


const gridContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr 0.2fr',
    gap: '1px',
    backgroundColor: 'rgb(255, 246, 217)',
    height: 'fit-content',
    width: '100%',
    margin: '20px 0',
};

const gridItemStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: 'lightgrey',
};

const vivliPrivateFieldStyle: CSSProperties = {
    backgroundColor: 'rgb(255, 246, 217)',
};


const buttonStyle = (minature?: boolean): CSSProperties => ({
    maxWidth: 100,
    minWidth: minature ? 'initial' : '100px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    height: "100%",
    justifyContent: 'space-between',
    alignItems: 'center'
});


export const PlanningTableComponent = ({
                                           handleAddRow,
                                           handleDeleteRow,
                                           planningTableData,
                                           handleUpdateRow,
                                           getDefaultTemplateCode,
                                           organizationsOptions,
                                           templateOptions
                                       }) => {


    return (
        <div style={{width: "100%"}}>
            <div style={gridContainerStyle}>
                <div style={gridItemStyle}>Contributor</div>
                <div style={gridItemStyle}>DUA Template</div>
                <div style={gridItemStyle}>Notes</div>
                <div style={gridItemStyle}></div>
                {planningTableData?.map((item, index) => (
                    <React.Fragment key={index}>
                        <div style={{...gridItemStyle, ...vivliPrivateFieldStyle}}>

                            <DropdownFieldComponent style={{...vivliPrivateFieldStyle, paddingBottom: "7px"}}
                                                    truncateLabel={false}
                                                    value={item.contributorName}
                                                    onChange={(value) => handleUpdateRow(index, 'contributorName', value)}
                                                    items={organizationsOptions}/>

                        </div>
                        <div style={{...gridItemStyle, ...vivliPrivateFieldStyle}}>
                            <DropdownFieldComponent style={{...vivliPrivateFieldStyle, paddingBottom: "7px"}}
                                                    truncateLabel={false}
                                                    value={item.selectedTemplateCode?
                                                        item.selectedTemplateCode:getDefaultTemplateCode(item.contributorName)}
                                                    onChange={(value) => handleUpdateRow(index, 'selectedTemplateCode', value)}
                                                    items={templateOptions}/>
                        </div>
                        <div style={{...gridItemStyle, ...vivliPrivateFieldStyle}}>
                            <TextFieldComponent
                                onChange={(event) => handleUpdateRow(index, 'contributorTemplateNotes', event.target.value)}
                                value={item.contributorTemplateNotes}
                                style={{...vivliPrivateFieldStyle}} innerStyle={{width: "94%"}}
                            />
                        </div>

                        <div style={{...gridItemStyle, ...vivliPrivateFieldStyle}}>
                            <div onClick={() => handleDeleteRow(index)}
                                 style={buttonStyle(true)}>
                                <FontAwesomeIcon style={{color: "RED"}} icon={faTrash}/>
                            </div>

                        </div>

                    </React.Fragment>
                ))}
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <ButtonComponent onClick={() => handleAddRow()} style={{margin: '20px 0', width: "5%"}}>Add
                    Row</ButtonComponent>
            </div>
        </div>
    );
};
