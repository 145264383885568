﻿import React, {CSSProperties} from "react";
import {Color, Size} from '@vivli/shared/theme';

interface IAdvancedHeaderComponent {
    style?: CSSProperties;
    title: string;
}

export const AdvancedHeaderComponent = ({title, style}: IAdvancedHeaderComponent) => {
    return (
        <div
            style={{
                color: Color.DARK_GRAY,
                fontSize: 16,
                marginBottom: Size.HALF_PADDING,
                ...style,
            }}
        >
            {title}
        </div>
    )
}
