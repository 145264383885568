import React from "react";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {OrgRorContainerComponent} from "./ror/org-ror-container.component";

interface OrgRorTabComponentProps {
    organization: IOrganization
}

export const OrgRorTabComponent = (
    {
        organization
    }: OrgRorTabComponentProps
) => {

    const user = useActiveUser();
    const canEdit = user?.isVivliAdmin;

    return (
        <div>
            <OrgRorContainerComponent organization={organization} canEdit={canEdit}/>
        </div>
    )
}
