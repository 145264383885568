﻿import { IFundingOrganization, IRor } from '@vivli/features/organizations/infrastructure/interface';
import { Color, Size, Styles } from '@vivli/shared/theme';
import {
    fiveAcrossStyle,
    fourAcrossStyle,
    LinkFieldComponent,
    TextFieldReadOnlyFormComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';
import React, { CSSProperties } from 'react';

const detailContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '70%',
    minWidth: '350px',
};

const rorDetailContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 50,
    alignItems: 'center',
};

const fieldBodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: Size.FontSize.Large,
    flexWrap: 'wrap',
};

const valueStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
};

const labelStyle: CSSProperties = {
    color: Color.DARK_GRAY,
    marginRight: '10px',
};

const fiveAcrossStyleWithHeight: CSSProperties = {
    flex: '1 1 18',
    maxWidth: '18%',
    marginTop: '-5px',
    height: '87px',
    boxShadow: Size.BOX_SHADOW,
};

const fieldStyle: CSSProperties = { marginRight: '15px' };

interface RorComponentProps {
    ror: IRor | IFundingOrganization;
    isFundingOrg?: boolean;
    baseFormField?: string;
    fundingOrgIndex?: number;
    canModify?: boolean;
}

export const RorComponent = ({ ror, isFundingOrg, fundingOrgIndex, canModify, baseFormField }: RorComponentProps) => {
    const styleToUse = isFundingOrg ? fiveAcrossStyle : fourAcrossStyle;
    const formField = `fundingOrganizations[${fundingOrgIndex}].grantId`;
    const grantTitle = baseFormField ? `${baseFormField}.${formField}` : formField;
    const rorUrl = `${ror?.rorId}`;
    const rorParentUrl = `${ror?.parentRorId}`;
    const hasParentUrl = ror?.parentRorId !== null && ror?.parentRorId !== undefined && ror?.parentRorId !== '';

    return (
        <div style={{ width: '98%' }}>
            {!isFundingOrg && <div>ROR INFORMATION</div>}
            <div style={{ width: '100%' }}>
                <div style={Styles.FORM_ROW}>
                    {!isFundingOrg && (
                        <>
                            <TextFieldReadOnlyFormComponent
                                label={'Ror Name'}
                                style={styleToUse}
                                defaultValue={ror?.rorName}
                                readonly={true}
                            />
                            <LinkFieldComponent linkTitle={ror?.rorId} linkContent={ror?.rorId} label={'Ror ID'} style={styleToUse} />
                            <TextFieldReadOnlyFormComponent
                                label={'Ror Parent Name'}
                                style={styleToUse}
                                defaultValue={ror?.parentRorName}
                                readonly={true}
                            />
                            <LinkFieldComponent
                                linkTitle={ror?.parentRorId}
                                linkContent={ror?.parentRorId}
                                label={'Ror Parent ID'}
                                style={styleToUse}
                            />
                        </>
                    )}
                </div>
                {isFundingOrg && (
                    <div style={rorDetailContainerStyle}>
                        <div style={detailContainerStyle}>
                            <div style={fieldBodyStyle}>
                                <div style={fieldStyle}>
                                    <span style={labelStyle}>Ror Name:</span>
                                    <span style={valueStyle}>{ror?.rorName}</span>
                                </div>
                                <div style={fieldStyle}>
                                    <span style={labelStyle}>Ror ID:</span>
                                    <span style={valueStyle}>
                                        <a href={rorUrl} target="_blank">
                                            {rorUrl}
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div style={fieldBodyStyle}>
                                <div style={fieldStyle}>
                                    <span style={labelStyle}>Parent Ror Name:</span>
                                    <span style={valueStyle}>{ror?.parentRorName}</span>
                                </div>
                                <div style={fieldStyle}>
                                    <span style={labelStyle}>Parent Ror ID:</span>
                                    {hasParentUrl && (
                                        <span style={valueStyle}>
                                            <a href={rorParentUrl} target="_blank">
                                                {rorParentUrl}
                                            </a>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <TextFormFieldComponent
                            name={grantTitle}
                            label={'Grant or Contract Id'}
                            style={fiveAcrossStyleWithHeight}
                            readonly={!canModify}
                            truncateReadonly={false}
                            truncateLabel={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
