﻿import joi, {ObjectSchema} from 'joi';
import {useForm} from 'react-hook-form';
import {joiResolver} from '@hookform/resolvers/joi';
import {
    IAmrAnalysisDetails,
    IAmrResearcherDetails,
    IAntimicrobialDetail,
} from '@vivli/features/data-requests/infrastructure/interface';
import {
    JoiValidationOptionsConstant,
    OrcidRegex,
} from '@vivli/shared/infrastructure/constants';
import {orcidInputSchema} from "@vivli/shared/infrastructure/schema";

const amrAnalysisSchema: ObjectSchema<IAmrAnalysisDetails> = joi
    .object({
        summary: joi
            .string()
            .required()
            .max(9000)
            .label('Summary of your research (up to 300 Words)'),
        purposeList: joi
            .array()
            .min(1)
            .required()
            .label('Purpose(s) of Analysis')
            .default([]),
        purposeListOtherDescription: joi.when('purposeList', {
            is: joi
                .array()
                .items(joi.string().valid('Other').required(), joi.any())
                .required(),
            then: joi.string().required().label('Other Purpose'),
            otherwise: joi.optional(),
        }),
    })
    .options({allowUnknown: true});

const amrResearcherDetailsSchema: ObjectSchema<IAmrResearcherDetails> = joi
    .object({
        amrResearcherAffiliation: joi
            .string()
            .required()
            .max(500)
            .label("Lead Researcher's Affiliation"),
        orcId: orcidInputSchema("Lead Researcher's ORCID iD", false),
        amrResearcherLocation: joi
            .required()
            .not(null)
            .label("Lead Researcher's Location")
            .default([]),
        researcherName: joi.string().required().label("Lead Researcher's Name"),
        researcherEmail: joi
            .string()
            .required()
            .email({tlds: {allow: false}})
            .min(1)
            .max(500)
            .label("Lead Researcher's Email Address"),
    })
    .options(JoiValidationOptionsConstant);

export const antimicrobialFormSchema: ObjectSchema<IAntimicrobialDetail> =
    joi.object({
        agreesToPublicationAcknowledgment: joi
            .boolean()
            .required()
            .label('Publication Acknowledgment')
            .valid(true)
            .messages({'any.only': '{{#label}} must be checked.'}),
        agreesToTermsOfUse: joi
            .boolean()
            .required()
            .label('Terms of Use')
            .valid(true)
            .messages({'any.only': '{{#label}} must be checked.'}),
        amrAnalysisDetails: amrAnalysisSchema,
        amrResearcherDetails: amrResearcherDetailsSchema,
    });

export function useAntimicrobialDetailForm(
    antimicrobial: IAntimicrobialDetail,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all'
) {
    return useForm({
        mode,
        resolver: joiResolver(
            antimicrobialFormSchema.options(JoiValidationOptionsConstant)
        ),
        defaultValues: antimicrobial,
        reValidateMode: 'onChange',
    });
}
