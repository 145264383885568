import {Observable} from 'rxjs';
import {
    ICountCollectionDto,
    IStudiesApiService,
    IStudy,
    IStudyBulkUpdateSpecifier,
    IStudyBulkUploadSpecifier,
} from '@vivli/features/studies/infrastructure/interface';
import {StudyStatusEnum} from '@vivli/features/studies/infrastructure/enum';
import {RestApi} from '@vivli/core/infrastructure/rest';
import {map} from 'rxjs/operators';
import {DataTransformationService} from '@vivli/shared/infrastructure/service';
import {AssignedAppTypeEnum} from '@vivli/shared/infrastructure/enum';
import {IDatasetUsageData} from '@vivli/features/datasets/infrastructure/interface';
import moment from 'moment';
import {IExternalLink, IUser} from '@vivli/shared/infrastructure/interface';

export class StudiesApiService extends RestApi implements IStudiesApiService {
    getAllStudies = (assignedAppType: AssignedAppTypeEnum): Observable<IStudy[]> => {
        const studyTypes = Object.keys(StudyStatusEnum).join(',');
        const appType: string = assignedAppType;
        return this.handleGet<IStudy[]>(
            `/studies/assignedAppType/${appType}/statuses/${studyTypes}`
        );
    };

    getStudiesByStatus = (
        type: StudyStatusEnum,
        assignedAppType: AssignedAppTypeEnum
    ): Observable<IStudy[]> => {
        const appType = assignedAppType;
        return this.handleGet<IStudy[]>(
            `/studies/assignedAppType/${appType}/statuses/${type}`
        ).pipe(map(DataTransformationService.formatStudies));
    };

    getStudy = (studyId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${studyId}`);
    };

    getStudyAnonymous = (studyId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${studyId}/metadata`, { bypassAuth: true });
    };

    getStudyFromDoiId = (studyId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${studyId}/metadata/fromdoi`, { bypassAuth: true });
    };

    getSampleStudy = (nctId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${nctId}/extract`);
    };

    getStudyOwner = (userId: string): Observable<IUser> => {
        return this.handleGet<IUser>(`/users/${userId}/studyowner`);
    };

    getSampleStudyForListingRequest = (nctId: string, studyId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${nctId}/extract/forListRequest/${studyId}`);
    };

    getSampleStudyForNonNctListingRequest = (sponsorId: string, studyId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${sponsorId}/forListRequest/${studyId}`);
    };

    getUsedIn = (studyId: string): Observable<string> => {
        return this.handleGet<string>(`/studies/${studyId}/usedIn`);
    };

    getDoiForIpdDataPackage = (studyId: string, ipdDataPackageId: string): Observable<IStudy> => {
        return this.handleGet<IStudy>(`/studies/${ipdDataPackageId}/doi`);
    };

    updateStudy = (study: IStudy): Observable<IStudy> => {
        return this.handlePut<IStudy>(`/studies`, study);
    };

    updateWithNewHistoryEntry = (study: IStudy): Observable<IStudy> => {
        return this.handlePut<IStudy>(`/studies/history`, study);
    };

    setStudyStatus = (
        study: IStudy,
        status: StudyStatusEnum,
        comment: string
    ): Observable<IStudy> => {
        return this.handlePut<IStudy>(`/studies/${study.id}/status/`, { status, comment });
    };

    acceptJSONFromCochrane = (study: IStudy, comment: string): Observable<IStudy> => {
        return this.handlePut<IStudy>(`/studies/accept/`, { study, comment });
    };

    setStudyIpdSubmittedStatus = (study: IStudy, isSubmitted: boolean): Observable<IStudy> => {
        return this.handlePut<IStudy>(`/studies/ipdsubmittedonly/${isSubmitted}`, study);
    };

    addStudy = (study: IStudy, hasExtractedStudyData: boolean): Observable<IStudy> => {
        return this.handlePost<IStudy>(`/studies/${hasExtractedStudyData}`, study);
    };

    bulkUploadStudies = (uploadSpec: IStudyBulkUploadSpecifier): Observable<void> => {
        return this.handlePost<void>(`/studies/bulkupload`, uploadSpec);
    };

    bulkUpdateStudies = (updateSpec: IStudyBulkUpdateSpecifier): Observable<void> => {
        return this.handlePost<void>(`/studies/bulkupdate`, updateSpec);
    };

    createIpdDataPackage = (studyId: string): Observable<IStudy> => {
        return this.handlePost<IStudy>(`/studies/${studyId}/ipdpackage`);
    };

    //DO NOT USE THIS METHOD for studies after posting! - deletes the study without any accompanying objects
    deleteStudy = (studyId: string): Observable<void> => {
        return this.handleDelete<void>(`/studies/${studyId}`);
    };

    deleteStudyIPD = (studyId: string, ipdDataPackageId: string): Observable<void> => {
        return this.handleDelete<void>(`/studies/${studyId}/${ipdDataPackageId}`);
    };

    softDeleteStudyIPD = (studyId: string, ipdDataPackageId: string): Observable<void> => {
        return this.handleDelete<void>(`/studies/${studyId}/${ipdDataPackageId}/soft`);
    };

    //DataCount Methods
    sendDataCountCollectionData = (studyDoi: string, fileDoi: string): Observable<string> => {
        const dto: ICountCollectionDto = {
            clientIp: '',
            userAgent: navigator.userAgent,
            studyDoi: studyDoi,
            fileDoi: fileDoi,
            referrer: studyDoi,
        };
        return this.handlePut<string>('/datacount/collect', dto);
    };

    getDataCountUsage = (studyId: string, postedDate: Date): Observable<IDatasetUsageData> => {
        return this.handleGet<IDatasetUsageData>(
            `/datacount/usage?datasetId=${studyId}&postedDate=${moment(postedDate).format(
                `YYYY-MM-DD`
            )}`
        );
    };

    updateExternalLinks = (studyId: string, externalLinks: IExternalLink[]): Observable<IStudy> => {
        return this.handlePut<IStudy>(`/studies/external-links/${studyId}`, externalLinks);
    };

    getStudyExportData = (studyIds: { id: string, score: number }[]): Observable<string> => {
        return this.handlePost<string>(`/studies/searchStudiesExport`, studyIds);
    }
}
