import React from 'react';
import {AdvancedSelectComponent, AdvancedSubHeaderComponent} from "@vivli/shared/components";
import {amrFieldTitleStyle, inputGroupBreakStyle} from "../../search-filters.styles";
import {FilterGroupComponent} from "../../filter-group.component";
import {useAmrSearchOptionsContext, useSearchContext} from "@vivli/features/search/infrastructure/context";

export const ThirdFilterGroupComponent = () => {
    const {amrFilterValues} = useSearchContext();
    const {
        countrySelectedOptions,
        setCountrySelectedOptions,
        regionSelectedOptions,
        setRegionSelectedOptions
    } = useAmrSearchOptionsContext();

    const countryOptions = amrFilterValues?.countries.map(l => ({value: l.value, label: l.value}))
    const regionOptions = amrFilterValues?.regions.map(l => ({value: l.value, label: l.value}))

    return <FilterGroupComponent>
        <div style={inputGroupBreakStyle}>
            <AdvancedSubHeaderComponent title={'Country'} style={amrFieldTitleStyle} />
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                onChange={setCountrySelectedOptions}
                options={countryOptions}
                selectedOptions={countrySelectedOptions}
            />
        </div>

        <div>
            <AdvancedSubHeaderComponent title={'Region'} style={amrFieldTitleStyle} />
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={true}
                onChange={setRegionSelectedOptions}
                options={regionOptions}
                selectedOptions={regionSelectedOptions}
            />
        </div>
    </FilterGroupComponent>
}
