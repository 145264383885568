﻿import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {useChatApiOptions, useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";
import {ChatApiService, ChatOrganizationApiService} from "@vivli/features/chat/infrastructure/service";
import {IChatService} from "@vivli/features/chat/infrastructure/interface";
import React from "react";
import {ChatServiceContext} from "@vivli/features/chat/infrastructure/context";

export const ChatServiceContextWrapper = ({children}: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const chatApiOptions = useChatApiOptions();
    const chatApi = new ChatApiService(chatApiOptions);
    const chatOrganizationApi = new ChatOrganizationApiService(apiOptions)

    const provider: IChatService = {
        ...chatApi,
        ...chatOrganizationApi
    }

    return <ChatServiceContext.Provider value={provider}>
        {children}
    </ChatServiceContext.Provider>
}
