﻿import React, {CSSProperties, useEffect, useState} from "react";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {ButtonComponent, CopyrightComponent, LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {AcceptedDataRequestInviteComponent} from "./accepted-data-request-invite.component";
import {Styles} from "@vivli/shared/theme";
import {first} from "rxjs/operators";
import {Msal2EventTypeEnum, useAuthService} from "@vivli/core/authentication";

const center500Style: CSSProperties = {
    textAlign: 'center',
    width: 500
}

const getEmail = () => {
    return decodeURIComponent(window.location.search.split('&')[0].split('=')[1])
}

interface AcceptDataRequestInviteModalProps {
    dataRequestId: string,
    teamMemberId : string
}
export const AcceptDataRequestInviteModal = (
{
    dataRequestId,
    teamMemberId
}:AcceptDataRequestInviteModalProps
) => {
    const dataRequestsService = useDataRequestsService();
    const authService = useAuthService();
    const email = getEmail();

    const [accepted, setAccepted] = useState(false);
    const [isAccepting, setIsAccepting] = useState(false);
    const [error, setError] = useState(null);

    const acceptDataRequestInvite = () => {
        setIsAccepting(true);
        dataRequestsService.acceptDataRequestInvite(dataRequestId, teamMemberId)
            .pipe(first())
            .subscribe(() => {
                setAccepted(true);
                setIsAccepting(false);
            }, (error) => {
                setAccepted(false);
                setError(error);
                setIsAccepting(false);
            });
    }

    useEffect(() => {
        const authSub = authService.authEvents.subscribe(event => {
            switch (event.type) {
                case Msal2EventTypeEnum.UserCancelledLogin:
                    break;
                case Msal2EventTypeEnum.SignedUpEvent:
                case Msal2EventTypeEnum.UserLoginEvent:
                    acceptDataRequestInvite();
                    break;
            }
        });

        return () => {
            authSub.unsubscribe();
        }
    }, [])

    if (isAccepting) {
        return <LoadIndicatorCenteredComponent />;
    } else if (accepted) {
        return <AcceptedDataRequestInviteComponent />;
    }

    return (
        <div style={center500Style}>
            Congratulations, you're almost done with accepting the invitation.
            <br/>It looks like you haven't logged in yet, you'll need to do that now.
            <br/><br/>Be sure to sign up with the email address <strong>{email}</strong> or your
            acceptance of this invitation will not work.
            <br/><br/><br/>
            <ButtonComponent
                className='adr_createAccount'
                style={{...Styles.Button.BUTTON_VIVLI_BLUE}}
                onClick={() => authService.signUp()}
            >
                Create Account
            </ButtonComponent>
            <br/><br/>
            <ButtonComponent
                className='adr_existingAccount'
                style={{...Styles.Button.BUTTON_LIGHT_VIVLI_BLUE}}
                onClick={() => authService.login()}
            >
                Existing Account
            </ButtonComponent>

            {error &&
                <div>
                    <div style={{color: 'red'}}><br/>Error: {error}</div>
                </div>
            }
            <CopyrightComponent />
        </div>
    )
}
