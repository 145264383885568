import React, { CSSProperties, useEffect, useState } from 'react';
import { TermBoxComponent } from './term-box.component';
import { usePicoSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import { IPicoSearchTerm } from '@vivli/features/search/infrastructure/interface';
import { PicoTypeEnum } from '@vivli/shared/infrastructure/enum';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const picoTermsStyle: CSSProperties = {
    color: 'white',
    display: 'grid',
    gridTemplateColumns: 'fit-content(0) 1fr',
};

const picoDisplayPadding = {
    padding: '15px',
    display: 'flex',
};

const termLetterStyle: CSSProperties = {
    fontSize: '45px',
    padding: '15px 0',
    minWidth: '70px',
    maxWidth: '70px',
    textAlign: 'center',
};

const upArrowStyle: CSSProperties = {
    ...picoDisplayPadding,
};

const downArrowStyle: CSSProperties = {
    ...picoDisplayPadding,
};

const centerTermStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

interface TermsSelectorComponent {
    picoType: PicoTypeEnum;
    backgroundColor: string;
}

export const TermsSelectorComponent = ({ picoType, backgroundColor }: TermsSelectorComponent) => {
    const { picoTerms, activeTerm, replacePicoTerm, updateActiveTerm } = usePicoSearchOptionsContext();
    const [activeTermForType, setActiveTermForType] = useState<IPicoSearchTerm>();
    const [activePicoTerms, setActivePicoTerms] = useState<IPicoSearchTerm[]>([]);

    const picoTermsContainerStyle: CSSProperties = {
        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        padding: '15px',
        backgroundColor: `rgb(${backgroundColor})`,
        height: '100%',
        overflowY: 'auto',
    };

    const updateActiveTermForType = () => {
        const updatedTerm = activeTerm?.type === picoType ? activeTerm : null;
        setActiveTermForType(updatedTerm);
    };

    const updateActivePicoTerms = () => {
        const terms = picoTerms.filter((t) => t.type === picoType);
        setActivePicoTerms(terms);

        if (activeTermForType && !picoTerms.some((pt) => pt.key === activeTermForType.key)) {
            setActiveTermForType(null);
        }
    };

    const handleBroaderConceptUpdate = (index: number) => {
        const value = activeTerm.document.broaderConcept[index];
        const label = activeTerm.document.broaderConceptLabels[index];

        replacePicoTerm({ value, label }, activeTermForType);
    };

    const handleChildConceptUpdate = (index: number) => {
        const value = activeTerm.document.childConcept[index];
        const label = activeTerm.document.childConceptLabels[index];

        replacePicoTerm({ value, label }, activeTermForType);
    };

    useEffect(() => {
        updateActiveTermForType();
    }, [activeTerm]);

    useEffect(() => {
        updateActivePicoTerms();
    }, [picoTerms, activeTerm]);

    useEffect(() => {
        updateActiveTermForType();
        updateActivePicoTerms();
    }, []);

    return (
        <div style={picoTermsContainerStyle}>
            <div style={picoTermsStyle}>
                <div style={upArrowStyle}>
                    <img src={AssetsConstant.TERMS_UP_ARROW} />
                </div>
                <div>
                    {activeTermForType?.document?.broaderConceptLabels?.map((label, i) => (
                        <TermBoxComponent key={i} term={label} onClick={() => handleBroaderConceptUpdate(i)} />
                    ))}

                    {activeTermForType?.document?.broaderConceptLabels?.length <= 0 && <TermBoxComponent isDisabled={true} term="None" />}
                </div>
            </div>
            <div style={picoTermsStyle}>
                <div style={termLetterStyle}>{picoType}</div>
                <div style={centerTermStyle}>
                    {activePicoTerms.map(({ document, key }) => (
                        <TermBoxComponent
                            key={key}
                            isActive={true}
                            term={document.displayLabel}
                            showCheckmark={activeTerm?.key === key}
                            onClick={() => updateActiveTerm(key)}
                        />
                    ))}
                </div>
            </div>
            <div style={picoTermsStyle}>
                <div style={downArrowStyle}>
                    <img style={{ width: 40 }} src={AssetsConstant.TERMS_DOWN_ARROW} />
                </div>
                <div>
                    {activeTermForType?.document?.childConceptLabels?.map((label, i) => (
                        <TermBoxComponent key={i} term={label} onClick={() => handleChildConceptUpdate(i)} />
                    ))}

                    {activeTermForType?.document?.childConceptLabels?.length <= 0 && <TermBoxComponent isDisabled={true} term="None" />}
                </div>
            </div>
        </div>
    );
};
