﻿import React, {CSSProperties} from "react";
import {outerTabStyle, tabContentContainerStyle} from "../shared/styles";
import {ChatFeature} from "@vivli/features/chat/feature";
import {useListingRequestContext} from "@vivli/features/listing-request/infrastructure/context";
import {FieldHeaderComponent} from "@vivli/shared/components";
const chatRestrictedStyle: CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}
export const ListingRequestChatComponent = () => {
    const {listingRequest} = useListingRequestContext();

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'Send a message to Vivli with questions or requests - we will normally respond within a day, and you will receive an email notification when a response is available.'}/>
                <div>
                    <ChatFeature channelId={listingRequest?.chatChannelId} connectOnFirstSend={true}/>
                </div>
            </div>
            { !(listingRequest.chatChannelId) &&
                <div style={chatRestrictedStyle}>
                    Submission needs to be saved before the Chat becomes available. Please move to another tab to save the submission and then come back to the Chat tab.
                </div>

            }
        </div>
    )
}
