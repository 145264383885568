import {useEffect, useState} from "react";
import {filter, finalize} from "rxjs/operators";
import {useUsersService} from "@vivli/features/users/infrastructure/context";
import {IUserDetails} from "@vivli/features/users/infrastructure/interface";

export function useVivliAdmins() {
    const [vivliAdmins, setVivliAdmins] = useState<IUserDetails[]>();
    const [isLoadingVivliAdmins, setIsLoadingVivliAdmins] = useState(false);

    const usersService = useUsersService();

    useEffect(() => {
        setIsLoadingVivliAdmins(true);
        const usersSub = usersService.getVivliAdmins()
            .pipe(
                filter(users => users !== null),
                finalize(() => setIsLoadingVivliAdmins(false))
            )
            .subscribe(setVivliAdmins);

        return () => {
            usersSub.unsubscribe();
        }
    }, [])

    return {
        vivliAdmins,
        isLoadingVivliAdmins
    };
}
