export const externalMenuItemsIntercept = [
    { name: 'Home', url: 'https://vivli.org/', menuItems: [] },
    {
        name: 'About',
        url: 'https://vivli.org/about',
        menuItems: [
            {
                name: 'Overview',
                url: 'https://vivli.org/overview',
            },
            {
                name: 'Why Share your clinical Research Data',
                url: 'https://vivli.org/why-share-your-clinical-research-data/',
            },
            {
                name: 'Board of Directors',
                url: 'https://vivli.org/about/board-directors/',
            },
            {
                name: 'Leadership Team',
                url: 'https://vivli.org/about/leadership-team/',
            },
            {
                name: 'Committees',
                url: 'https://vivli.org/about/vivli-committees/',
            },
            {
                name: 'Funders & Partners',
                url: 'https://vivli.org/about/funders/',
            },
            {
                name: 'Independent Review Panel',
                url: 'https://vivli.org/about/independent-review-panel/',
            },
            {
                name: 'NIH GREI Project',
                url: 'https://vivli.org/about/nih-grei-project/',
            },
        ],
    },
    {
        name: 'Members',
        url: 'https://vivli.org/Members',
        menuItems: [
            {
                name: 'Our Members',
                url: 'https://vivli.org/members/ourmembers/',
            },
            {
                name: 'Become a Member',
                url: 'https://vivli.org/members/become-a-member/',
            },
            {
                name: 'Why we support Vivli',
                url: 'https://vivli.org/members/why-we-support-vivli/',
            },
            {
                name: 'Enquiries about Vivli Member Studies',
                url: 'https://vivli.org/members/enquiries-about-studies-not-listed-on-the-vivli-platform/',
            },
        ],
    },
    {
        name: 'News & Events',
        url: 'https://vivli.org/news/',
        menuItems: [
            { name: 'Events', url: 'https://vivli.org/news/events/' },
            {
                name: 'News',
                url: 'https://vivli.org/news/news/',
            },
            { name: 'Blog', url: 'https://vivli.org/news/blog/' },
            {
                name: 'Webinars & Publications',
                url: 'https://vivli.org/resources/publications/',
            },
        ],
    },
    {
        name: 'Resources',
        url: 'https://vivli.org/resources/resources/',
        menuItems: [
            {
                name: 'How to Share Data',
                url: 'https://vivli.org/resources/sharedata/',
            },
            {
                name: 'How to request data',
                url: 'https://vivli.org/resources/requestdata/',
            },
            {
                name: 'Vivli Metrics',
                url: 'https://vivli.org/resources/platform_metrics/',
            },
            {
                name: 'How to Guides',
                url: 'https://vivli.org/resources/resources/',
            },
            {
                name: 'Public Disclosures',
                url: 'https://vivli.org/resources/public-disclosures/',
            },
            { name: 'FAQs', url: 'https://vivli.org/resources/faqs/' },
        ],
    },
];
