import joi, {ObjectSchema} from 'joi';
import {useForm} from "react-hook-form";
import {joiResolver} from '@hookform/resolvers/joi';
import {ISafetyConcernReport} from "@vivli/features/data-requests/infrastructure/interface";
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";

const formSchema: ObjectSchema<ISafetyConcernReport> = joi.object({
    name: joi.string().required().label('Name'),
    email: joi.string().required().label('Email'),
    phoneNumber: joi.string().required().label('Phone Number'),
    safetyConcernDescription: joi.string().required().label('Safety Concern Description')
}).options(JoiValidationOptionsConstant)

const defaultValues: ISafetyConcernReport = {
    name: '',
    email: '',
    phoneNumber: '',
    safetyConcernDescription: ''
}

export function useSafetyConcernForm(safetyConcernReport: ISafetyConcernReport = defaultValues, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema),
        defaultValues: safetyConcernReport,
        reValidateMode: 'onChange'
    });
}
