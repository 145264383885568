import React, {CSSProperties} from 'react';
import {Color} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: Color.WHITE,
    width: '100vw',
    height: '100vh',
}

const textStyle: CSSProperties = {
    fontSize: 30,
    alignSelf: 'center',
}

export const CookieWarningComponent = () => {
    return (
        <div style={containerStyle}>
            <div style={textStyle}>
                Cookies must be enabled in order to to use the Vivli Platform.
            </div>
        </div>
    )
}
