import React, {CSSProperties} from 'react';
import {ISafetyConcern} from "@vivli/features/data-requests/infrastructure/interface";
import {Color, Size} from "@vivli/shared/theme";
import moment from "moment";

const containerStyle: CSSProperties = {
    width: '100%',
    backgroundColor: Color.WHITE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    marginBottom: Size.FIELD_SEPARATION,
    padding: Size.PADDING,
    minHeight: '85px',
    flexGrow: 1,
}

const detailStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'space-around',
    width: '30%',
}

interface ReportSafetyConcernListItemComponentProps {
    safetyConcern: ISafetyConcern;
}

export const ReportSafetyConcernListItemComponent = (
    {
        safetyConcern
    }: ReportSafetyConcernListItemComponentProps) => (
    <div style={containerStyle}>
        <div style={detailStyle}>
            <div>
                <strong>{safetyConcern.safetyConcernSubmitter.name}</strong>
            </div>
            <div>
                {safetyConcern.safetyConcernSubmitter.email}
            </div>
            <div>
                {safetyConcern.safetyConcernSubmitter.phoneNumber}
            </div>
        </div>

        <div style={{ width: '20%' }}>
            {navigator.language === 'en-US' ? moment(safetyConcern.submittedDateTime).format('M/D/YY h:mm a') :
                moment(safetyConcern.submittedDateTime).format('D/M/YY h:mm a')}
        </div>

        <div style={{ width: '50%' }}>
            {safetyConcern.safetyConcernDescription}
        </div>
    </div>
)
