﻿import { CSSProperties } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

export const btnStyle: CSSProperties = {
    marginRight: Size.PADDING,
    border: `1px solid ${Color.VIVLI_BLUE}`,
    backgroundColor: Color.TRANSPARENT,
    color: Color.VIVLI_BLUE,
    alignSelf: 'center',
};

export const verticalTopStyle: CSSProperties = {
    verticalAlign: 'text-top',
};

export const fieldHeaderStyle: CSSProperties = {
    fontSize: 16,
    fontWeight: Size.FontWeight.Regular,
};

export const titleStyle: CSSProperties = {
    fontSize: 20,
    fontWeight: Size.FontWeight.XLight,
    color: Color.DARK_GRAY,
    lineHeight: '27px',
    paddingRight: Size.PADDING,
    wordWrap: 'break-word',
    width: '100%',
    marginRight: 5,
    marginBottom: -20,
    paddingBottom: Size.PADDING,
};

export const innerFormContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
};

export const outerFormContainerStyle: CSSProperties = {
    padding: '10px 20px',
    position: 'relative',
    background: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 101,
    height: '70px',
    minHeight: '70px',
    wordBreak: 'break-all',
};

export const leftHeaderContainerStyle: CSSProperties = {
    display: 'flex',
    flex: 1,
};

export const rightHeaderContainerStyle: CSSProperties = {
    marginRight: 15,
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const submitBtnStyle: CSSProperties = {
    ...Styles.Button.BUTTON_LIGHT_VIVLI_BLUE,
    backgroundPosition: 'center left 15px',
    backgroundImage: `url(${AssetsConstant.WHITE_CHECK_MARK})`,
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: Size.PADDING,
    height: 40,
    minWidth: 0,
    paddingLeft: 40,
    alignSelf: 'center',
};

export const editRequestBtnStyle: CSSProperties = {
    height: 20,
    fontSize: 12,
    marginTop: 2,
};

export const editRequestTitleContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 30,
};

export const oneAcrossStyle: CSSProperties = {
    flex: '0 1 100%',
    maxWidth: '100%',
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
    whiteSpace: 'pre-wrap',
};

export const twoAcrossStyleCbs: CSSProperties = {
    flex: '1 1 50%',
    backgroundColor: 'White',
    maxWidth: '49%',
    maxHeight: '63px',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    alignSelf: 'flex-start',
};

export const twoBigSplitAcrossStyle: CSSProperties = {
    flex: '1 1 80%',
    maxWidth: '79%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const twoSmallSplitAcrossStyle: CSSProperties = {
    flex: '1 1 20%',
    maxWidth: '19%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const twoAcrossStyle: CSSProperties = {
    flex: '1 1 50%',
    maxWidth: '49%',
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const threeSmallSplitAcrossStyle: CSSProperties = {
    flex: '1 1 26%',
    maxWidth: '25%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const threeAcrossStyle: CSSProperties = {
    flex: '1 1 32%',
    maxWidth: '32%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const fourBigSplitAcrossStyle: CSSProperties = {
    flex: '1 1 40',
    maxWidth: '39%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const fourSmallSplitAcrossStyle: CSSProperties = {
    flex: '1 1 10',
    maxWidth: '9%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const fourAcrossStyle: CSSProperties = {
    flex: '1 1 24',
    maxWidth: '24%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};

export const fiveAcrossStyle: CSSProperties = {
    flex: '1 1 18',
    maxWidth: '18%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    boxShadow: Size.BOX_SHADOW,
};
