import React, {createContext, useContext} from 'react';
import {IDataPackageFileService} from "@vivli/shared/features/data-package/infrastructure/interface";

export const DataPackageFileServiceSecureContext = createContext<IDataPackageFileService>(null);
export const DataPackageFileServiceContext = createContext<IDataPackageFileService>(null);

export const useDataPackageFileService = (useSecureStorage = true) => {
    const currentContext = useSecureStorage ? DataPackageFileServiceSecureContext : DataPackageFileServiceContext;
    return useContext(currentContext);
}


