﻿import {IContextWrapper} from "@vivli/shared/infrastructure/interface";
import {NotificationContext, useNotificationService} from "@vivli/shared/features/notification/infrastructure/context";

import {useModalService, useToastService} from "@vivli/shared/infrastructure/context";

import {useRef, useState} from "react";
import {first} from 'rxjs/operators';
import {INotification} from "@vivli/shared/features/notification/infrastructure/interface";
import {UseFormReturn} from "react-hook-form";
import {NotificationTypeEnum} from "@vivli/shared/features/notification/infrastructure/enum";
import {useActiveUser, useConfigService} from "@vivli/core/infrastructure/context";


export const NotificationContextWrapper = ({children}: IContextWrapper) => {


    const toastService = useToastService();
    const modalService = useModalService();
    const activeUser = useActiveUser();
    const notificationService = useNotificationService()
    const [notifications, setNotifications] = useState<INotification[]>([])
    const [topicId, setTopicId] = useState<string>();
    const config = useConfigService();
    const activeEnv = config.queryUri.split('-')[1];
    const formApiRef = useRef<UseFormReturn<INotification>>();
    const [showForm, setShowForm] = useState<boolean>(false)
    const [lastSentMessageState, setlastSentMessageState] = useState<string>("N/A")
    const [firstAndLastDates, setFirstAndLastDates] = useState<string[]>([])
    const [isSaving, setIsSaving] = useState<boolean>()
    const [showSaveButton, setShowSaveButton] = useState<boolean>()

    const handleSaveSuccess = () => {
        toastService.success('Successfully saved your notification');
        setIsSaving(false);
        getNotifications(topicId);
    };
    const handleFailure = (err: string) => {
        modalService.error(`An error occurred submitting notifications. Please try again or contact Vivli support. Message: ${err}`);
        setIsSaving(false);
    };

    const handleDates = (datesObj) => {

        if (datesObj.lastSendFailed) {
            setlastSentMessageState("Failed")
        }

        const dates = datesObj.lastAndNextSendDates
        const previousDate = dates[0]?.toString().substring(0, 10)
        const nextDate = dates[1]?.toString().substring(0, 10)
        setFirstAndLastDates([previousDate, nextDate])
        setShowForm(true)
    }
    const handleForm = (notificationArr) => {
        setNotifications(notificationArr)

        // if notifications exists
        if (notificationArr?.length > 0) {
            setShowForm(false)
            const notificationId = parseInt(notificationArr[0].id)
            notificationService.getLastAndNextDate(notificationId).pipe(first())
                .subscribe(datesObj => handleDates(datesObj), handleFailure)
        }

        //  if no notifications exist we get an empty array
        if (notificationArr?.length < 1) {
            setShowForm(true)
        }
    }

    const getNotifications = (topicId: string) => {

        setTopicId(topicId);
        notificationService.getNotificationsForTopic(topicId).pipe(first())
            .subscribe(notificationArr => handleForm(notificationArr), handleFailure)
    }


    const createNewNotification = (updatedNotificationForm, topicType) => {
        const newNotification = {
            ...updatedNotificationForm,
            topicId: topicId,
            createdBy: activeUser.userId,
            updatedBy: activeUser.userId,
            environment: activeEnv,
            topicType: topicType,
            notificationType: NotificationTypeEnum.ChatMessage
        }
        setIsSaving(true);

        if (newNotification.active == false) {
            modalService.confirm("Are you sure you want to leave the notification inactive.", {
                title: "Please Confirm",
                confirmText: 'Ok',
                cancelText: 'Cancel',
                onConfirm: () => {
                    notificationService.createNotification(newNotification).pipe(first())
                        .subscribe(handleSaveSuccess, handleFailure)

                },
                onCancel: () => {
                    setIsSaving(false)
                }
            });
        } else {
            notificationService.createNotification(newNotification).pipe(first())
                .subscribe(handleSaveSuccess, handleFailure)
        }
    }
    const handleUpdate = (updatedNotificationForm) => {
        const updatedNotification = {...updatedNotificationForm, updatedBy: activeUser.userId}
        setIsSaving(true);

        if (updatedNotification.active == false) {
            modalService.confirm("Are you sure you want to leave the notification inactive?", {
                title: "Please Confirm",
                confirmText: 'Ok',
                cancelText: 'Cancel',
                onConfirm: () => {
                    notificationService.updateNotification(updatedNotification).pipe(first())
                        .subscribe(handleSaveSuccess, handleFailure)

                },
                onCancel: () => {
                    setIsSaving(false)
                }
            });
        } else {
            notificationService.updateNotification(updatedNotification).pipe(first())
                .subscribe(handleSaveSuccess, handleFailure)
        }

    }


    const handleSubmit = (topicType) => {
        const updatedNotificationForm: INotification = formApiRef.current.getValues();

        if (notifications.length <= 0) {
            createNewNotification(updatedNotificationForm, topicType)
        } else {
            handleUpdate(updatedNotificationForm)
        }
    }
    const resetNotificationForm = () => {
        setShowForm(false)

        modalService.confirm("Are you sure you want to create a new notification?", {
            title: "Please Confirm",
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: () => {
                setNotifications([])
                setFirstAndLastDates([])
                formApiRef.current.reset()
                setShowForm(true)
            },
            onCancel: () => {
                setShowForm(true)
            }
        });
    }


    const setNotificationFormApi = (formApi: UseFormReturn<INotification>) => {
        formApiRef.current = formApi;
        setShowSaveButton(formApi.formState.isValid)
    };


    const provider = {
        getNotifications,
        setNotificationFormApi,
        resetNotificationForm,
        notifications,
        handleSubmit,
        showForm,
        firstAndLastDates,
        lastSentMessageState,
        isSaving,
        showSaveButton
    }

    return <NotificationContext.Provider value={provider}>
        {children}
    </NotificationContext.Provider>


}
