import React, {CSSProperties} from 'react';
import {ButtonComponent, FieldHeaderComponent, LoadIndicatorComponent} from '@vivli/shared/components';
import {Color} from '@vivli/shared/theme';
import {TeamMemberListItemComponent} from './team-member-list-item.component';
import {TeamMemberModalComponent} from './team-member-modal.component';
import {
    useResearchTeamCreate,
    useResearchTeamMemberId,
    useResearchTeamPermissions,
} from '@vivli/features/data-requests/infrastructure/hook';
import {IDataRequest, IResearchTeamMemberDetail} from '@vivli/features/data-requests/infrastructure/interface';
import {ResearchTeamMemberRoleEnum} from '@vivli/features/data-requests/infrastructure/enum';
import {useModalService} from '@vivli/shared/infrastructure/context';
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    width: `100%`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
};

const btnStyle = {
    backgroundColor: Color.WHITE,
    borderColor: '#A0A0A0',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#A0A0A0',
};

interface ResearchTeamListFeatureProps {
    dataRequest: IDataRequest;
    researchTeamMembers: IResearchTeamMemberDetail[];
    onRemove?: (teamMember: IResearchTeamMemberDetail) => void;
    onSave?: (teamMember: IResearchTeamMemberDetail) => void;
    onAdd?: (teamMember: IResearchTeamMemberDetail) => void;
    teamMembersLoading?: string[];
    onChange?: (dataRequest: IDataRequest) => void;
    title?: string;
}

export const ResearchTeamListComponent = ({
    dataRequest,
    researchTeamMembers,
    onRemove,
    onSave,
    onAdd,
    teamMembersLoading,
    onChange,
    title = 'RESEARCHERS',
}: ResearchTeamListFeatureProps) => {
    const modalService = useModalService();
    const { userCanEdit } = useResearchTeamPermissions(dataRequest);
    const createResearcher = useResearchTeamCreate();
    const getTeamMemberId = useResearchTeamMemberId();

    const handleAddResearcher = () => {
        const newMember = createResearcher(ResearchTeamMemberRoleEnum.AdditionalResearcher);

        const modalId = modalService.custom(
            <TeamMemberModalComponent
                onSave={(teamMember) => {
                    modalService.dismiss(modalId);
                    onAdd && onAdd(teamMember);
                }}
                onCancel={() => modalService.dismiss(modalId)}
                teamMember={newMember}
                dataRequest={dataRequest}
            />,
            {
                style: {
                    width: '80%',
                },
            }
        );
    };

    const buildResearcherRows = () => {
        return researchTeamMembers?.map((teamMember, index) => {
            const readonly = teamMember.isLeadResearcher || teamMember.isStatistician || !userCanEdit;

            return (
                <TeamMemberListItemComponent
                    key={index}
                    teamMember={teamMember}
                    dataRequestId={dataRequest.id}
                    readonly={readonly}
                    onSave={onSave}
                    onChange={onChange}
                    onRemove={onRemove}
                    isSaving={teamMembersLoading?.some((id) => id === getTeamMemberId(teamMember))}
                    dataRequest={dataRequest}
                />
            );
        });
    };

    if (!researchTeamMembers || !dataRequest) {
        return (
            <LoadIndicatorComponent
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            />
        );
    }

    return (
        <div style={containerStyle}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FieldHeaderComponent title={title} />
                    {userCanEdit && (
                        <ButtonComponent style={btnStyle} onClick={handleAddResearcher} dataId={DTICommonConst.AddButton}>
                            Add +
                        </ButtonComponent>
                    )}
                </div>
            </div>
            {buildResearcherRows()}
        </div>
    );
};
