import React, { CSSProperties, useState } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { ButtonComponent, TextAreaComponent } from '@vivli/shared/components';
import {DTISignDocument} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: Color.WHITE,
    minWidth: 300,
    borderRadius: 15,
};

const titleStyle: CSSProperties = {
    paddingBottom: Size.PADDING * 2,
    paddingLeft: Size.PADDING * 2,
    paddingRight: Size.PADDING * 2,
    paddingTop: Size.PADDING * 2,
    borderBottom: '1px solid white',
    fontSize: Size.FontSize.Large,
    alignContent: 'center',
};

const documentContainerStyle: CSSProperties = {
    paddingBottom: Size.PADDING * 2,
    paddingLeft: Size.PADDING * 2,
    paddingRight: Size.PADDING * 2,
    paddingTop: Size.PADDING * 2,
    fontSize: Size.FontSize.Medium,
};

const documentStyle: CSSProperties = {
    backgroundColor: 'white',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    color: 'black',
    overflowY: 'scroll',
    height: 400,
    width: 600,
};

const signatureContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    justifyContent: 'space-evenly',
    paddingBottom: Size.PADDING,
    gap: '15px',
    padding: '15px',
};

interface SignDocumentComponent {
    onCancel: () => void;
    onSigned: (signature: string) => void;
    isLoading?: boolean;
}

export const SignDocumentComponent = ({ onCancel, onSigned, isLoading }: SignDocumentComponent) => {
    const [userIntent, setUserIntent] = useState<string>(null);
    const vivliConfig = useVivliConfig();

    const getError = (): string => {
        return userIntent?.toLowerCase() !== 'i agree' ? "You must enter 'I agree' to sign this document" : null;
    };

    const isSigned = () => {
        return getError() === null;
    };

    return (
        <div style={containerStyle}>
            <div style={titleStyle}>Sign Document</div>

            <div style={documentContainerStyle}>
                <div style={documentStyle} className="scrolly" dangerouslySetInnerHTML={{ __html: vivliConfig?.termsOfUseAgreement }}></div>
                <br />
                To agree and sign, enter 'I Agree' in the textbox below and click 'Sign'. <br /> <br />
            </div>
            <div style={signatureContainerStyle}>
                <TextAreaComponent
                    rows={1}
                    label={"Type 'I Agree'"}
                    value={userIntent}
                    onChange={(e) => setUserIntent(e.target.value)}
                    error={getError()}
                    dataId={DTISignDocument.IAgreeInput}
                />

                <ButtonComponent
                    isLoading={isLoading}
                    onClick={() => onSigned(userIntent)}
                    disabled={!isSigned()}
                    className="signDocument_sign"
                    dataId={DTISignDocument.SignButton}
                >
                    Sign
                </ButtonComponent>

                <ButtonComponent
                    style={{ backgroundColor: Color.VIVLI_RED }}
                    disabled={isLoading}
                    onClick={onCancel}
                    className="signDocument_cancel"
                    dataId={DTISignDocument.SignCancelButton}
                >
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    );
};
