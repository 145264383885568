import React from 'react';

/* eslint-disable-next-line */

export function AdminConfigSettingsComponent() {
    return (
        <div>
            <h1>ConfigSettings component</h1>
        </div>
    );
}
