﻿import {createContext, useContext} from "react";
import {IDataRequest} from "@vivli/features/data-requests/infrastructure/interface";


interface IUserInfoContext {
    handleUpdateUserInfo?: () => void;
    isSavingNotes?: boolean;
    userDataRequests?: IDataRequest[];

}

export const UserInfoContext = createContext<IUserInfoContext>(null)

export const useUserInfoContext = () => useContext(UserInfoContext)
