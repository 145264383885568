import { IExternalLink } from '@vivli/shared/infrastructure/interface';
import { CSSProperties } from 'react';
import { DeleteButtonComponent, EditButtonComponent } from '../buttons';

const containerStyle: CSSProperties = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    columnGap: '10px',
    padding: '5px',
    cursor: 'pointer',
};

const rowStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    columnGap: '15px',
    color: 'blue',
    textDecoration: 'underline',
};

interface IDataPackageLinkRendererComponent {
    data: IExternalLink;
    handleEditClick: (data: IExternalLink, event: any) => void;
    handleDeleteClick: (data: IExternalLink, event: any) => void;
    handleRowClick: (data: IExternalLink, event: any) => void;
    editable?: boolean;
}

export const ExternalLinkRendererComponent = ({
    data,
    handleDeleteClick,
    handleEditClick,
    handleRowClick,
    editable,
}: IDataPackageLinkRendererComponent) => {
    return (
        <div style={containerStyle}>
            <div onClick={(e) => handleRowClick(data, e)} style={rowStyle} title={data.uri}>
                <div>{data.title}</div>
                {editable && <div>{data.uri}</div>}
            </div>
            {editable && (
                <>
                    <EditButtonComponent onClick={(e) => handleEditClick(data, e)} />
                    <DeleteButtonComponent onClick={(e) => handleDeleteClick(data, e)} />
                </>
            )}
        </div>
    );
};
