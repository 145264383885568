import React, {CSSProperties, ReactNode, useEffect, useState} from 'react';
import {ColDef, GridApi} from 'ag-grid-community';
import {AdminHeaderComponent, LoadIndicatorComponent} from '@vivli/shared/components';
import {AdminHeaderTitleComponent} from '@vivli/shared/components';
import {ExportCsvButtonComponent} from '@vivli/shared/components';
import {GridComponent} from '@vivli/shared/components';
import {useUsers} from "@vivli/features/users/infrastructure/hook";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {IUserDetails} from "@vivli/features/users/infrastructure/interface";
import {DataTransformationService} from "@vivli/shared/infrastructure/service";
import {GridCellRendererEnum} from "@vivli/shared/infrastructure/enum";

const containerStyle: CSSProperties = {
    padding: '0px 40px 20px 40px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
}

const columnDefs: ColDef[] = [
    {
        field: 'email',
        headerName: 'Email Address',
        filter: true,
        sortable: true
    },
    {
        field: 'displayNameWithAdminStatus',
        headerName: 'Name',
        filter: true,
        sortable: true,
        initialSort: 'asc'
    },
    {
        field: 'activeOrgs',
        headerName: 'Organizations',
        filter: true,
        sortable: true,
        cellRenderer: GridCellRendererEnum.GridCellList,
        cellRendererParams: {emptyMessage: 'No Organization'}
    },
    {
        field: 'status',
        headerName: 'Account Status',
        filter: true,
        sortable: true
    },
    {
        field: 'daysSinceLastLogin',
        headerName: 'Days Since Last Login',
        filter: true,
        sortable: true
    },
    {
        // Know the Power of the "hide" attribute
        field: 'notes',
        headerName: 'Notes',
        hide: true,
        sortable: true
    },
    {
        field: 'dataRequestsForUser',
        headerName: 'Data Requests for User',
        hide: true,
        sortable: true
    }
]
interface UserGridPageComponentProps {
    title: string;
    exportTitle: string;
    onRowClick?: (data: any) => void;
    fullWidthColumns?: boolean;
    asyncUpdateKey?: any;
    onGridReady?: (gridApi: GridApi) => void;
    customHeader?: ReactNode;
    customComponent?: ReactNode;
    flushPrevious?: boolean;
    isVivliAdmin?: boolean;
}

export const UserGridPageComponent = (
    {
        onRowClick,
        title,
        exportTitle,
        fullWidthColumns,
        onGridReady,
        customHeader,
        customComponent,
        flushPrevious,
        isVivliAdmin
    }: UserGridPageComponentProps) => {
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [formattedUsers, setFormattedUsers] = useState<IUserDetails[]>(null);
    const {users,getUsers, isLoadingUsers} = useUsers();
    const {organizations} = useOrganizations();
    const {formatUserDetails} = DataTransformationService;

    const exportColumnKeys = [
        'email',
        'displayNameWithAdminStatus',
        'activeOrgs',
        'status',
        'daysSinceLastLogin',
        'notes',
        'dataRequestsForUser'
    ];

    const exportColumnKeysOrgAdmin = [
        'email',
        'displayNameWithAdminStatus',
        'activeOrgs',
        'status',
        'daysSinceLastLogin',
        'dataRequestsForUser'
    ];

    const columnKeysForUser = isVivliAdmin ? exportColumnKeys : exportColumnKeysOrgAdmin;

    const exportToCsv = () => {
        // get users with datarequests, then do the export
        getUsers(true,finishExportToCsv );
    }
    const finishExportToCsv = () => {
        formatUsers(users);
        const dateStr = new Date().toLocaleDateString().replace('/', '_');
        const fileName = `${exportTitle}_${dateStr}`;
        gridApi.exportDataAsCsv({fileName, columnKeys: columnKeysForUser});
    }
    const handleOnGridReady = (gridApi: GridApi) => {
        setGridApi(gridApi);
        onGridReady && onGridReady(gridApi);
    }
    const formatUser = (user: IUserDetails) => {
        const orgs = user.userRole.orgMemberships.map(role => {
            return organizations.find(o => o.id === role.orgId)?.name || null;
        }).filter(org => org);


        const activeOrgs = user.userRole.orgMemberships.map(role => {
            return organizations.find(o => o.id === role.orgId && role.isEnabled
                == true)?.name || null;
        }).filter(org => org);


        return {
            ...formatUserDetails(user),
            orgs,
            activeOrgs
        }
    }

    const sortUsers = (a, b) => a.displayName.localeCompare(b.displayName);

    const formatUsers = (users: IUserDetails[]) => {
        const formattedUsers = users.map(formatUser).sort(sortUsers);
        setFormattedUsers(formattedUsers);
    }

    useEffect(() => {
        if (!users || !organizations) {
            return;
        }

        formatUsers(users);
    }, [users, organizations])


    return (
        <div style={containerStyle}>
            <AdminHeaderComponent style={{margin: '20px 0 0 0'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <AdminHeaderTitleComponent title={title}/>
                </div>
                {customHeader &&
                    <div style={{boxShadow: 'none', marginLeft: '50px', marginRight: '50px'}}>
                        {customHeader}
                    </div>
                }
                {isLoadingUsers &&
                    <LoadIndicatorComponent style = {{marginTop: 10}}/>
                }
                {!isLoadingUsers &&
                    <ExportCsvButtonComponent onClick={exportToCsv} style={{right: '10px', height: 'initial'}}/>
                }
            </AdminHeaderComponent>

            {customComponent &&
                <div>
                    {customComponent}
                </div>
            }
            <GridComponent
                rowData={formattedUsers}
                columns={columnDefs}
                onRowClick={onRowClick}
                onGridReady={handleOnGridReady}
                fullWidthColumns={fullWidthColumns}
                style={{height: '90%', marginTop: '35px'}}
                flushPrevious={flushPrevious}
                dataRefreshing={isLoadingUsers}
            />
        </div>
    )
}
