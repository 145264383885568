import React, { CSSProperties, useState } from 'react';
import {Popover} from 'react-tiny-popover';
import { Color, Size, Styles } from '@vivli/shared/theme';
import {
    QuickSearchComponent,
    QuickSearchResultsComponent,
} from '@vivli/features/search/components';
import { useQuickSearchContext } from '@vivli/features/search/infrastructure/context';
import { IFilterSet } from '@vivli/features/search/infrastructure/interface';
import { FilterOperatorEnum } from '@vivli/features/search/infrastructure/enum';
import {AssetsConstant, DTIQuickStudyLookupButton} from '@vivli/shared/infrastructure/constants';

const quickSearchContentStyle: CSSProperties = {
    backgroundColor: '#0097c6',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
};

const quickSearchMenuContainerStyle = (
    isQuickSearchMenuOpen: boolean
): CSSProperties => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    color: 'white',
    backgroundColor: isQuickSearchMenuOpen ? 'rgba(255, 255, 255, 0.2)' : null,
    paddingLeft: 15,
    paddingRight: 15,
    cursor: 'pointer',
});

const quickSearchTextStyle: CSSProperties = {
    paddingRight: Size.PADDING / 2,
    ...Styles.NO_SELECT,
    display: 'flex',
    alignItems: 'center',
    transition: `background-color ${Size.HOVER_TRANSITION_TIME}s`,
    color: Color.WHITE,
    fontSize: Size.FontSize.Medium,
};

export const QuickStudyLookupButtonComponent = () => {
    const [isQuickSearchMenuOpen, setIsQuickSearchMenuOpen] = useState(false);
    const { isShowingResults, addFilterSet, setSearchText } =
        useQuickSearchContext();

    const handleSearch = (id: string) => {
        setSearchText(id);
        const isNctId = id?.toLocaleLowerCase().startsWith('nct');

        const filterSet: IFilterSet = {
            key: isNctId ? 'nctId' : 'sponsorProtocolId',
            items: [{ value: id }],
            itemOperator: FilterOperatorEnum.And,
            operator: FilterOperatorEnum.And,
        };
        addFilterSet(filterSet);
        setIsQuickSearchMenuOpen(false);
    };

    const handleClickOutside = (e: any) => {
        let trulyOutside = true;
        (e?.path as Element[]).forEach((div) => {
            if (div.className === 'quick-search-container') {
                trulyOutside = false;
            }
        });
        if (trulyOutside) {
            setIsQuickSearchMenuOpen(false);
        }
    };

    return (
        <>
            <Popover
                isOpen={isQuickSearchMenuOpen}
                padding={0}
                containerClassName={'quick-search-container'}
                containerStyle={{ zIndex: '4' }}
                positions={['bottom']}
                onClickOutside={handleClickOutside}
                align={'end'}
                content={
                    <div style={quickSearchContentStyle}>
                        <QuickSearchComponent executeSearch={handleSearch} />
                    </div>
                }
            >
                <div
                    style={quickSearchMenuContainerStyle(isQuickSearchMenuOpen)}
                    onClick={() =>
                        setIsQuickSearchMenuOpen(!isQuickSearchMenuOpen)
                    }
                    className="header_quickStudyLookup"
                    data-test-id={DTIQuickStudyLookupButton.HeaderQuickStudyLookUp}
                >
                    <div style={quickSearchTextStyle}>QUICK STUDY LOOKUP</div>
                    <img
                        src={AssetsConstant.downArrowWhiteIcon}
                        style={{ maxWidth: 12 }}
                    />
                </div>
            </Popover>

            <QuickSearchResultsComponent isOpen={isShowingResults} />
        </>
    );
};
