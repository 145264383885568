﻿import React, {CSSProperties} from 'react';
import {DropdownFieldComponent} from "@vivli/shared/components";

const tableContainer: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    border: '1px solid #ddd',
    width: '50%',
    padding: '8px',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};

const tableHeader: CSSProperties = {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    fontSize: '20px',
    borderBottom: '1px solid #ddd',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
};

const tableCell: CSSProperties = {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    margin: '4px 0',
};

const selectField: CSSProperties = {
    padding: '5px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    width: '100%',
    margin: '4px 0',
};
export const LoggingAreaTableComponent = ({loggingData, handleDropdownChange}) => {

    return (
        <div style={{display: 'flex', gap: '10px'}}>

            <div style={tableContainer}>
                <div style={tableHeader}>Logging Area</div>
                <div style={tableHeader}>Logging Level</div>
                {loggingData.map((item, index) => (
                    <React.Fragment key={index}>
                        <div style={tableCell}>{item.keyField}</div>
                        <DropdownFieldComponent
                            value={item.valueField}
                            onChange={(value) => handleDropdownChange(index, value)}
                            items={[
                                {"title": "Error", "value": "Error"},
                                {"title": "Warning", "value": "Warning"},
                                {"title": "Information", "value": "Information"},
                                {"title": "Debug", "value": "Debug"}
                            ]}
                            style={selectField}
                        /></React.Fragment>
                ))}
            </div>

        </div>
    );
};
