﻿import {useFieldArray} from "react-hook-form";
import {EnquiryDataInfoRequestComponent} from "./enquiry-data-info-request.component";
import {IEnquiry} from "@vivli/features/enquiry/infastructure/interface";
import {useEnquiryContext} from "@vivli/features/enquiry/infastructure/context";

export const EnquiryDataInfoRequestsComponent = () => {
    const {fields, append, remove, update} = useFieldArray<IEnquiry>({
        name: "dataInfoRequests",
        keyName: "id1" as "id", //we need to rename the field key property or it overrides the data request info "id" property
    });

    const {handleDirArrayInit} = useEnquiryContext();

    handleDirArrayInit(append);

    return <>
        {fields.map((field, i) => <EnquiryDataInfoRequestComponent key={field.id}
                                                                   dirIndex={i}
                                                                   onRemove={remove}
                                                                   onUpdate={update}
        />)}
    </>
}
