import {IFundingOrganization, IOrganization, IRor} from '@vivli/features/organizations/infrastructure/interface';
import {RorSearchComponent} from './ror-search.component';
import {RorComponent} from './ror.component';

interface OrgRorContainerComponentProps {
    organization?: IOrganization;
    handleRemoveRow?: (fundingOrganization: IFundingOrganization) => void;
    canEdit?: boolean;
}

export const OrgRorContainerComponent = ({organization, canEdit}: OrgRorContainerComponentProps) => {
    let orgToUse: IRor | IFundingOrganization;

    if (organization) {
        orgToUse = organization.rorInformation;
    }

    return (
        <div style={{width: '100%'}}>
            {canEdit && <RorSearchComponent organization={organization}/>}
            {organization && <RorComponent ror={orgToUse} canModify={canEdit}/>}
        </div>
    );
};
