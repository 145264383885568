import React, {CSSProperties, useEffect, useState} from 'react';
import {FilterGroupComponent} from "../../filter-group.component";

import 'react-input-range/lib/css/index.css';
import './sample-size.styles.scss';
import {useCtSearchOptionsContext, useSearchContext} from "@vivli/features/search/infrastructure/context";
import {RangeSliderComponent} from "@vivli/shared/components";
import {ISliderRange} from "@vivli/shared/infrastructure/interface";

const inputRangeContainerStyle: CSSProperties = {
    padding: '0 20px',
    paddingTop: '40px'
}

const checkboxStyle: CSSProperties = {
    transform: 'scale(1.5)'
}

const checkboxContainerStyle: CSSProperties = {
    position: 'absolute',
    right: '10px',
    top: '5px',
    display: 'flex'
}

const labelStyle: CSSProperties = {
    fontSize: '0.7rem',
    color: 'grey',
    marginTop: '3px',
    marginRight: '5px'
}

export const SampleSizeFilterGroupComponent = () => {
    const {ctFilterValues} = useSearchContext();
    const {sampleSize, setSampleSize, sampleSizeEnabled, setSampleSizeEnabled} = useCtSearchOptionsContext();
    const [rangeValues, setRangeValues] = useState<string[]>(null);
    const [sliderRange, setSliderRange] = useState(null);

    const handleCheckboxOnChange = (e) => {
        setSampleSizeEnabled(e.target.checked);
    }

    const handleOnChange = (range: ISliderRange) => {
        if (range) {
            const min = range.min ? parseInt(range.min) : null;
            const max = range.max ? parseInt(range.max) : null;

            setSampleSize({min, max});
        } else {
            setSampleSize(null);
        }
    }

    useEffect(() => {
        const newRangeValues = ctFilterValues?.actualEnrollment.map(x => x.value.toString());

        if (newRangeValues) {
            setRangeValues(newRangeValues);
        }
    }, [ctFilterValues])

    useEffect(() => {
        const newSliderRange: ISliderRange = sampleSize ? { min: sampleSize.min?.toString(), max: sampleSize.max?.toString() } : null;

        setSliderRange(newSliderRange);
    }, [sampleSize])

    return <FilterGroupComponent title={'SAMPLE SIZE'} innerStyle={{position: 'relative', minHeight: '120px'}}>
        <div style={checkboxContainerStyle}>
            <label style={labelStyle}>({sampleSizeEnabled ? 'Enabled' : 'Disabled'})</label>
            <input
                style={checkboxStyle}
                type='checkbox'
                onChange={handleCheckboxOnChange}
                checked={sampleSizeEnabled}
            />
        </div>
        <div style={inputRangeContainerStyle}>
            <RangeSliderComponent
                rangeValues={rangeValues}
                value={sliderRange}
                disabled={!sampleSizeEnabled}
                onChange={handleOnChange}
            />
        </div>
    </FilterGroupComponent>
}
