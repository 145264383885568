﻿import React, {CSSProperties,useState, useEffect} from "react";
import {Size, Styles} from "@vivli/shared/theme";
import {TextAreaReadOnlyFormComponent} from "@vivli/shared/components";
import {DTICTFormFields} from "@vivli/shared/infrastructure/constants";

const containerDivStyle = (containerStyle): CSSProperties => ({
    ...Styles.CENTERED_FLEX,
    marginTop: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    flex: 'auto',
    flexDirection: 'column',
    height: '19em',
    ...containerStyle
})
const textAreaStyle = (style): CSSProperties => ({
    height: '18.5em',
    width: '100%',
    marginBottom: Size.FIELD_SEPARATION,
    fontSize: 14,
    fontWeight: "normal",
    ...style
})

interface StudyDatasetJsonComponentProps {
    json: string;
    containerStyle?: CSSProperties;
    style?: CSSProperties;
    showTitle?: boolean;
}
export const StudyDatasetJsonComponent = (
    {
        json,
        containerStyle,
        style,
        showTitle
    }: StudyDatasetJsonComponentProps
) => {
    const hasJson = json?.length > 0 && json !== "null";
    const [jsonToShow,SetJsonToShow] = useState("");

    useEffect(() => {
        SetJsonToShow (hasJson ? json : "JSON is empty");
    },[json]);


    return (
        <div style={containerDivStyle(containerStyle)}>
            {showTitle &&
                <div style={{ marginBottom: Size.FIELD_SEPARATION }}>
                    Raw JSON
                </div>
            }

            <TextAreaReadOnlyFormComponent
                style={textAreaStyle(style)}
                textStyle={textAreaStyle(style)}
                defaultValue={jsonToShow}
                dataId={DTICTFormFields.JsonList}
            />
        </div>
    );
}
