import React, { CSSProperties, useEffect, useState } from 'react';
import { PicoTypeModalComponent } from './pico-type-modal.component';
import Tippy from '@tippyjs/react';

import './multi-value-container.styles.scss';
import { usePicoSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import { PicoColorEnum } from '@vivli/features/search/infrastructure/enum';
import { PicoTypeEnum } from '@vivli/shared/infrastructure/enum';
import { IPicoSearchTerm } from '@vivli/features/search/infrastructure/interface';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const containerStyle = (color: string, isActive: boolean): CSSProperties => ({
    backgroundImage: `linear-gradient(${color}, white)`,
    backgroundColor: 'transparent',
    padding: isActive ? '5px' : '10px 5px',
    fontSize: '20px',
    cursor: 'pointer',
    marginRight: '10px',
});

const closeIconStyle = (isHovered: boolean): CSSProperties => ({
    marginLeft: '5px',
    borderRadius: '5px',
    padding: '5px',
    cursor: 'pointer',
    backgroundColor: isHovered ? 'rgba(255, 255, 255, 0.5)' : 'transparent',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const closeIconImgStyle: CSSProperties = {
    opacity: 0.7,
    cursor: 'default',
};

export const MultiValueContainerComponent = ({ children, ...props }: any) => {
    const getTransparentTermColor = (color: string) => {
        return `rgba(${color}, 0.70), rgba(${color}, 0.40)`;
    };
    const defaultColor = getTransparentTermColor(
        props.data.type ? PicoColorEnum[props.data.type] : PicoColorEnum.None
    );

    const [isVisible, setIsVisible] = useState(false);
    const [isActive, setIsActive] = useState(props.data.active !== undefined);
    const [activeColor, setActiveColor] = useState<string>(defaultColor);
    const [selectedTerm, setSelectedTerm] = useState<IPicoSearchTerm>();
    const [isHovered, setIsHovered] = useState(false);
    const { updateActiveTerm, activeTerm, picoTerms, removePicoTerm } =
        usePicoSearchOptionsContext();

    const handleClickOutside = () => {
        setIsVisible(false);
    };

    const handleOnClick = () => {
        setIsVisible(!isVisible);
        updateActiveTerm(selectedTerm.key);
    };

    const updateActiveColor = () => {
        const picoTerm = picoTerms.find((t) => t.key === selectedTerm.key);
        const picoType = picoTerm?.type || PicoTypeEnum.None;

        let updatedColor;
        switch (picoType) {
            case PicoTypeEnum.P:
                updatedColor = getTransparentTermColor(PicoColorEnum.P);
                break;
            case PicoTypeEnum.I:
                updatedColor = getTransparentTermColor(PicoColorEnum.I);
                break;
            case PicoTypeEnum.C:
                updatedColor = getTransparentTermColor(PicoColorEnum.C);
                break;
            case PicoTypeEnum.O:
                updatedColor = getTransparentTermColor(PicoColorEnum.O);
                break;
            default:
                updatedColor = defaultColor;
                break;
        }

        // only update if we are changing the color actively since this can also trigger when replacing terms
        if (updatedColor !== activeColor) {
            setActiveColor(updatedColor);
        }
    };

    const updateSelectedTerm = () => {
        // when we first initialize this component, grab the term based on the key value instead of the ID
        const picoTerm = picoTerms.find((pt) => pt.key === props.data.value);
        setSelectedTerm(picoTerm);
    };

    const handleRemoveClick = (e) => {
        e.stopPropagation();
        removePicoTerm(selectedTerm);
    };

    useEffect(() => {
        if (!selectedTerm) {
            return;
        }

        if (isActive && activeTerm?.key !== selectedTerm.key) {
            setIsActive(false);
        } else if (!isActive && activeTerm?.key === selectedTerm.key) {
            setIsActive(true);
        }

        updateActiveColor();
    }, [activeTerm, selectedTerm]);

    useEffect(() => {
        updateSelectedTerm();
    }, [picoTerms]);

    return (
        // popover used to select PICO type for vocab terms
        <Tippy
            placement={'top'}
            onClickOutside={handleClickOutside}
            content={<PicoTypeModalComponent picoTerm={selectedTerm} />}
            visible={isVisible}
            interactive={true}
            appendTo={document.getElementById('search-feature-container')}
            className={'tippy-pico-modal'}
        >
            <div
                onClick={handleOnClick}
                style={containerStyle(activeColor, isActive)}
            >
                {selectedTerm?.document?.displayLabel || props.data.label}
                <span
                    style={closeIconStyle(isHovered)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <img
                        src={AssetsConstant.CLOSE_X_BLACK}
                        style={closeIconImgStyle}
                        onClick={handleRemoveClick}
                    />
                </span>
            </div>
        </Tippy>
    );
};
