import React, {CSSProperties} from 'react';
import {useConfigService} from '@vivli/core/infrastructure/context';
import {AppResultRequestModule} from './app-result-request.module';
import {AppDefaultModule} from './app-default.module';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
};

export const AppInitComponent = () => {
    const { viewRequestResultsOnly } = useConfigService();

    return <div style={containerStyle}>{viewRequestResultsOnly ? <AppResultRequestModule /> : <AppDefaultModule />}</div>;
};
