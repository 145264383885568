﻿import React, {ReactNode, useEffect, useState} from "react";
import {ISafetyConcern} from "@vivli/features/data-requests/infrastructure/interface";
import {SafetyConcernsContext, useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {first} from "rxjs/operators";

interface SafetyConcernsContextWrapperProps {
    children: ReactNode;
    dataRequestId: string;
}

export const SafetyConcernsContextWrapper = ({ dataRequestId, children }: SafetyConcernsContextWrapperProps) => {
    const [safetyConcerns, setSafetyConcerns] = useState<ISafetyConcern[]>();

    const dataRequestsService = useDataRequestsService();

    const addSafetyConcern = (safetyConcern: ISafetyConcern) => {
        const updatedConcerns = [...safetyConcerns, safetyConcern];
        setSafetyConcerns(updatedConcerns);
    }

    const provider = {
        safetyConcerns,
        addSafetyConcern
    }

    useEffect(() => {
        if (!dataRequestId) { return; }

        const sub = dataRequestsService.getSafetyConcerns(dataRequestId)
            .pipe(first())
            .subscribe(setSafetyConcerns)

        return () => {
            sub?.unsubscribe();
        }
    }, [dataRequestId])

    return (
        <SafetyConcernsContext.Provider value={provider}>{children}</SafetyConcernsContext.Provider>
    )
}
