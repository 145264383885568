import React from 'react';
import {AdvancedSelectComponent, AdvancedSubHeaderComponent} from "@vivli/shared/components";
import {inputGroupBreakStyle} from "../../search-filters.styles";
import {FilterGroupComponent} from "../../filter-group.component";
import {useCtSearchOptionsContext, useSearchContext} from "@vivli/features/search/infrastructure/context";
import {
    DTIStudyDesignFilterGroup,
    InterventionalStudyOptionsConstant,
    ObservationalStudyOptionsConstant
} from "@vivli/shared/infrastructure/constants";

export const StudyDesignFilterGroupComponent = () => {
    const {ctFilterValues} = useSearchContext();
    const {
        interventionalStudiesSelectedOptions,
        setInterventionalStudiesSelectedOptions,
        observationalStudiesSelectedOptions,
        setObservationalStudiesSelectedOptions
    } = useCtSearchOptionsContext();

    const options = ctFilterValues?.studyDesign;

    const interventionalStudiesOptions = InterventionalStudyOptionsConstant.filter(o => options?.some(sfo => sfo.value === o.value));

    const observationalOptions = ObservationalStudyOptionsConstant.filter(o => options?.some(sfo => sfo.value === o.value));

    return <FilterGroupComponent title={'STUDY DESIGN'} noBottomPad={true}>
        <div style={inputGroupBreakStyle}>
            <AdvancedSubHeaderComponent title={'INTERVENTIONAL STUDIES'}/>
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={false}
                onChange={setInterventionalStudiesSelectedOptions}
                options={interventionalStudiesOptions}
                selectedOptions={interventionalStudiesSelectedOptions}
                data-test-id={DTIStudyDesignFilterGroup.InterventionalOption}

            />
        </div>

        <div>
            <AdvancedSubHeaderComponent title={'OBSERVATIONAL STUDIES'}/>
            <AdvancedSelectComponent
                isMulti={true}
                isSearchable={false}
                onChange={setObservationalStudiesSelectedOptions}
                options={observationalOptions}
                selectedOptions={observationalStudiesSelectedOptions}
                data-test-id={DTIStudyDesignFilterGroup.ObservationalOption}
            />
        </div>
    </FilterGroupComponent>
}
