/* eslint-disable-next-line */
import {RestApi} from "@vivli/core/infrastructure/rest";
import {
    IEnquiry, IEnquiryAndDataRequest,
    IEnquiryApiService,
    IEnquiryOrganizations
} from "@vivli/features/enquiry/infastructure/interface";
import {Observable} from "rxjs";
import {EnquiryStatusEnum} from "@vivli/features/enquiry/infastructure/enum";


export class EnquiryApiService extends RestApi implements IEnquiryApiService{

    getEnquiryList = ():Observable<IEnquiry[]> =>{
        return this.handleGet<IEnquiry[]>(`/Enquiry/list`);
    }

    updateEnquiry = (enquiry:IEnquiry, notify: boolean): Observable<IEnquiry> => {
        const notifyString = notify ? 'true' : 'false';
        return this.handlePut<IEnquiry>(`/Enquiry/${notifyString}`, enquiry);
    }
    getStudyInfo = (nctIdorsponsorId: string): Observable<any> => {
        return this.handleGet(`/Enquiry/studyinfo/${nctIdorsponsorId}`);
    }

    getEnquiryOrganizations = (): Observable<IEnquiryOrganizations[]> => {
        return this.handleGet(`/Enquiry/organizations`);
    }

    getEnquiry = (id: string): Observable<IEnquiry> => {
        return this.handleGet<IEnquiry>(`/Enquiry/${id}`);
    }

    updateStatus  = (enquiry:IEnquiry, status: EnquiryStatusEnum): Observable<IEnquiry> => {
        return this.handlePut<IEnquiry>(`/Enquiry/changestatus/${enquiry.id}/${status}`, enquiry);
    }

    addDirStudy = (enquiryId: number, dirId: number, targetDataRequestTitle: string): Observable<IEnquiryAndDataRequest> => {
        return this.handlePost<IEnquiryAndDataRequest>(`/DataRequests/enquiry/addToDataRequest/${enquiryId}/${dirId}/${targetDataRequestTitle}`);
    };
}

