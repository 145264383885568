import React, {CSSProperties} from 'react';
import {DataPackageValidationModeEnum} from "@vivli/features/studies/infrastructure/enum";
import {Color, Size} from "@vivli/shared/theme";
import {FieldHeaderComponent} from "@vivli/shared/components";
import {IDataPackageFileDescriptor} from "@vivli/shared/features/data-package/infrastructure/interface";
import {useTranslation} from "react-i18next";

const containerStyle: CSSProperties = {
    color: Color.ERROR_RED,
    marginBottom: Size.PADDING,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    border: '1px solid red',
}

const accessoryStyle: CSSProperties = {
    width: '100%',
    display: 'block',
    whiteSpace: 'normal'
}

const anyStyle: CSSProperties = {
    fontWeight: Size.FontWeight.Bold,
    color: Color.ERROR_RED,
}

interface DataPackageValidationMessageComponentProps {
    validationMode: DataPackageValidationModeEnum;
    requiredTypes: string[];
    files: IDataPackageFileDescriptor[];
}

export const DataPackageValidationMessageComponent = (
    {
        validationMode,
        requiredTypes,
        files
    }: DataPackageValidationMessageComponentProps
) => {
    const anyMessage = 'YOU MUST SUPPLY ONE OF THE FOLLOWING REQUIRED FILE TYPES AS DISTINCT FILES, AND FOR EACH FILE, YOU MUST SPECIFY THE FILE TYPE. If a file type is unavailable, please provide a file with a note of explanation';
    const allMessage = 'YOU MUST SUPPLY ALL REQUIRED FILE TYPES AS DISTINCT FILES, AND FOR EACH FILE, YOU MUST SPECIFY THE FILE TYPE. If a file type is unavailable, please provide a file with a note of explanation';

    const isAnyMode = validationMode === DataPackageValidationModeEnum.Any;
    const {t} = useTranslation();

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent
                style={isAnyMode && anyStyle}
                accessoryStyle={accessoryStyle}
                title={isAnyMode ? anyMessage : allMessage}
            />

            {requiredTypes && (
                <ul>
                    {requiredTypes
                        .filter(type => !files.some((fd) => fd.type === type))
                        .map((type, i) => <li key={i}>{t(type)}</li>)}
                </ul>
            )}
        </div>
    )
}
