﻿import {LinkFieldComponent, TextFieldReadOnlyFormComponent} from "@vivli/shared/components";
import React, {CSSProperties} from "react";
import {Size} from "@vivli/shared/theme";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {DTICommonConst, DTICTFormFields} from "@vivli/shared/infrastructure/constants";
import {useDoiTransform} from "@vivli/features/studies/infrastructure/hook";

const textFieldHalfWidthStyle: CSSProperties = {
    flex: '0 1 50%',
    maxWidth: '45%',
    marginBottom: Size.FIELD_SEPARATION,
    cursor: 'default',
}

interface ViewDataPackageStudyReferenceComponentProps {
    study: IStudy
}
export const ViewDataPackageStudyReferenceComponent = (
    {
        study
    }:ViewDataPackageStudyReferenceComponentProps
) => {
    const {transformDoiUrl} = useDoiTransform();

    return (
        <>
            <LinkFieldComponent
                label='Vivli Doi'
                linkContent={transformDoiUrl(study?.studyMetadataDoi) /*11301*/}
                linkTitle={study?.studyMetadataDoi }
                style={textFieldHalfWidthStyle}
                dataId={DTICommonConst.HeaderApprovalButton('VivliDoi')}
            />
            <TextFieldReadOnlyFormComponent
                label={'Sponsor Protocol ID'}
                defaultValue={study?.sponsorProtocolId}
                style={textFieldHalfWidthStyle}
                dataId={DTICTFormFields.SponsorProtocolId}
            />
        </>
    )
}
