import React, {CSSProperties, ReactNode} from 'react';
import {filterGroupBorderStyle, filterGroupStyle} from "./search-filters.styles";
import {AdvancedHeaderComponent} from "@vivli/shared/components";

interface FilterGroupComponent {
    children: ReactNode;
    title?: string;
    innerStyle?: CSSProperties;
    noBottomPad?: boolean;
}

export const FilterGroupComponent = ({children, title, innerStyle, noBottomPad = false}: FilterGroupComponent) => {
    const containerStyle = {
        ...filterGroupStyle
    }

    if (noBottomPad) {
        containerStyle.paddingBottom = '0';
    }

    return (
        <div style={containerStyle}>
            <div style={{...filterGroupBorderStyle, ...innerStyle}}>
                {title && <AdvancedHeaderComponent title={title}/>}
                {children}
            </div>
        </div>
    )
}
