import React, {CSSProperties} from 'react';
import {Color, Size} from "@vivli/shared/theme";

interface EmptyListPlaceholderProps {
    text: string;
    image: string;
    height?: number;
    imageHeight?: number | string;
    optionalStyles?: any;
}

export const EmptyListPlaceholderComponent = ({ text, image, height, imageHeight, optionalStyles }: EmptyListPlaceholderProps) => {
    const containerStyle: CSSProperties = {
        width: '100%',
        height,
        color: Color.BLACK,
        opacity: .2,
        textAlign: 'center',
        fontSize: 30,
        paddingLeft: Size.INSET_SIZE,
        paddingRight: Size.INSET_SIZE,
        ...optionalStyles,
    }

    const imageStyle: CSSProperties = {
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: '30%',
        height: imageHeight || height - 70,
        marginTop: 35,
    }

    return (
        <div style={containerStyle}>
            {text}
            <div style={imageStyle}/>
        </div>
    )
}
