﻿import React, {CSSProperties} from 'react';
import {useParams} from "react-router";
import {useStudyWithUserCheck} from "@vivli/features/studies/infrastructure/hook";
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {AdminStudyFormComponent} from "@vivli/features/studies/components";
import {StudyContext} from "@vivli/features/studies/infrastructure/context";
import {StudyContextWrapper} from "@vivli/features/studies/infrastructure/wrappers";

// This feature is the top level for forms for ADMINS to manipulate the individual content of an
// object that implements the IStudy interface. Note that this "IStudy object" may be a clinical trial
// (for Default mode) or an Amr Dataset (for Amr mode).
//
// An ADMIN is a data contributor who "owns" the IStudy object
// (access controlled by permissions that dictate ordinary users or org admins only),
// or a Vivli Admin.
//
// The feature lives in the "studies" area for legacy reasons only. The "datasets" area
// contains NON-EDITABLE, NON-ADMIN views of the IStudy object (clinical trial or Amr dataset) that
// are shown from search or when the IStudy object is used in a data request. In a few cases we share
// components between the NON-ADMIN area ("datasets") and the ADMIN area ("studies") - this is for
// for particular cases where we want non-editable behavior in the ADMIN area
//
// To distinguish the ADMIN components from their NON-ADMIN counterparts "admin" is frequently used in the
// ADMIN component names. Also, components used in both amr and clinical trials typically have "amr" and "ct"
// in their names.

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)', // force chrome to use GPU for rendering - fixes UI 'whiteout' glitch related to scrollbars (I think),
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
}

export const AdminStudyFeature = () => {
    const {studyId} = useParams();
    const {study, studyUsedIn,setStudy, studyIpdDataPackageSubmitted} = useStudyWithUserCheck(studyId, false,  true);

return (
    <div style={containerStyle}>
        <StudyContextWrapper
            study={study}
            setStudy={setStudy}
            usedIn={studyUsedIn}
            studyIpdPackageSubmitted={studyIpdDataPackageSubmitted}
        >
            <StudyContext.Consumer>
                {({study}) => {
                    if (!study) {
                        return <LoadIndicatorCenteredComponent/>;
                    }
                    return (
                        <AdminStudyFormComponent/>
                    )
                }}
            </StudyContext.Consumer>
        </StudyContextWrapper>
    </div>
);
}
