import React from 'react';
import {EnquiryComponent} from '@vivli/features/enquiry/components';
import {EnquiryContextWrapper} from '@vivli/features/enquiry/infastructure/wrappers';


export const EnquiryFeature = () => {
    return (
        <EnquiryContextWrapper>
            <EnquiryComponent />
        </EnquiryContextWrapper>
    );
};
