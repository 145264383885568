import React, {useEffect} from "react";
import {FieldHeaderComponent} from "@vivli/shared/components";
import {IOrganization} from "@vivli/features/organizations/infrastructure/interface";
import {useCurrentMembersHook} from "@vivli/features/organizations/infrastructure/hook";
import {OrgMembersGridComponent} from "./org-members-grid.component";
import {OrgResourcesLinkComponent} from "./modals/org-resources-link.component";
import {InviteMembersWithRightsComponent} from "./invite-members-with-rights.component";
import {PendingInvitesComponent} from "./pending-invites.component";
import {useOrganizationDetailsContext} from "@vivli/features/organizations/infrastructure/context";

interface OrgMembersTabComponentProps {
    organization?: IOrganization
}
export const OrgMembersTabComponent = (
    {
        organization
    } : OrgMembersTabComponentProps
) => {

    const {currentMembers} = useOrganizationDetailsContext();
//TODO - remove <PendingInvitesComponent /> entirely (after release 3.3)

    return (
        <div>
            <OrgResourcesLinkComponent />
            <InviteMembersWithRightsComponent organization={organization}/>

            <PendingInvitesComponent />

            <FieldHeaderComponent title={'ORGANIZATION MEMBERS'}/>

            {currentMembers && Object.keys(currentMembers).length > 0 &&
                <>
                    <OrgMembersGridComponent
                        currentOrgMembers={currentMembers as any}
                        organization={organization as any}
                    />
                </>
            }
        </div>
    )
}
