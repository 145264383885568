﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { ListingRequestTabComponent } from './listing-request-tab.component';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useParams } from 'react-router';
import { usePathEndingHook } from '@vivli/shared/infrastructure/hook';
import { useNavigate } from 'react-router-dom';

const bottomDivStyle: CSSProperties = {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    background: 'white',
    position: 'relative',
    zIndex: 14,
    display: 'flex',
    flexGrow: 2,
};

const panelItems = [
    {
        name: 'InformationAboutYourTeam',
        title: '1. Information About Your Team',
        url: 'InformationAboutYourTeam',
    },
    {
        name: 'YourOrganization',
        title: '2. Your Organization',
        url: 'YourOrganization',
    },
    {
        name: 'YourStudy',
        title: '3. Your Study',
        url: 'YourStudy',
    },
    {
        name: 'DataSharingSettings',
        title: '4. Data Sharing Settings',
        url: 'DataSharingSettings',
    },
    {
        name: 'Agreements',
        title: '5. Agreements',
        url: 'Agreements',
    },
    {
        name: 'UploadData',
        title: '6. Upload Data',
        url: 'uploadData',
    },
    {
        name: 'History',
        title: 'History',
        url: 'History',
    },
    {
        name: 'Chat',
        title: 'Chat',
        url: 'chat',
    },
    {
        name: 'StatusUpdate',
        title: 'Status Update',
        url: 'statusUpdate',
    },
];

export const ListingRequestTabsComponent = () => {
    //Do not display Upload tab vivli admins
    const user = useActiveUser();
    const { id } = useParams();
    const tab = usePathEndingHook();
    const navigate = useNavigate();
    const [activeItems, setActiveItems] = useState([]);

    useEffect(() => {
        let selectedItems = panelItems;
        if (user?.isVivliAdmin) {
            selectedItems = selectedItems.filter((l) => l.name !== 'UploadData');
        }
        if (!user?.isVivliAdmin) {
            selectedItems = selectedItems.filter((l) => l.name !== 'StatusUpdate');
        }
        setActiveItems([...selectedItems]);

        // check if the last piece of the URL (the "tab") matches the id
        // this will happen when coming from an email ink, in which case we need to select the default tab
        if (id === tab) {
            navigate('InformationAboutYourTeam', { replace: true });
        }
    }, []);

    const tabsContainerStyle: CSSProperties = {
        minWidth: 250,
        maxWidth: 250,
        display: 'flex',
        flexDirection: 'column',
    };

    return (
        <div style={tabsContainerStyle}>
            {activeItems.map((p, i) => (
                <ListingRequestTabComponent panelItem={p} key={i} />
            ))}

            <div style={bottomDivStyle} />
        </div>
    );
};
