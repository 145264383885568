export const partnersIntercept = [
    {
        name: 'addidev',
        code: '04CA586D-6A81-4ABC-8184-86DBCE073C1F',
        active: false,
        sso: true,
        identityprovider:
            'https://adb2caddi.b2clogin.com/71873d2c-f9d0-44d0-aad5-1652dccfd856/v2.0/',
        displayname: 'ADWB DEV',
    },
    {
        name: 'addi',
        code: '1790F0E2-54AE-4AE9-8F12-51D61E077858',
        active: false,
        sso: true,
        identityprovider:
            'https://aridhiadevelopmentmcab2c.b2clogin.com/117239ad-4d13-4708-832d-5e4cbdb50c69/v2.0/',
        displayname: 'ADWB',
    },
    {
        name: 'addiprod',
        code: 'A0A22C82-F7BD-4AAE-BEBF-74A5B0611D0A',
        active: true,
        sso: true,
        identityprovider:
            'https://addib2c.b2clogin.com/c201a81c-a008-4c46-8128-ddebdd6ac1be/v2.0/',
        displayname: 'ADWB',
    },
    {
        name: 'addidev2',
        code: '71C17F5C-445E-4617-8340-AB269BED2A47',
        active: true,
        sso: true,
        identityprovider:
            'https://aridhiadevelopmentmcab2c.b2clogin.com/117239ad-4d13-4708-832d-5e4cbdb50c69/v2.0/',
        displayname: 'ADWB',
    },
];
