import {useEffect, useState} from "react";
import {
    IDataRequest,
    IDataRequestSummary,
    IRequestedStudy
} from "@vivli/features/data-requests/infrastructure/interface";
import {useUserPermissions} from "@vivli/features/users/infrastructure/hook";
import {useDataRequestFilters} from "@vivli/features/data-requests/infrastructure/hook";
import {useRequestedStudyFilters} from "./use-requested-study-filters.hook";

export function useRequestedStudies(dataRequests: IDataRequestSummary[]) {
    const [requestedStudies, setRequestedStudies] = useState<IRequestedStudy[]>();

    const { canUploadIpd } = useUserPermissions();
    const { filterAwaitingUpload } = useDataRequestFilters();
    const { filterIsNewStudy, filterWithRequestId } = useRequestedStudyFilters()

    const getStudiesFromRequests = (requests: IDataRequestSummary[]): IRequestedStudy[] => {
        const filteredRequests = filterAwaitingUpload(requests);

        return filteredRequests.reduce((acc: IRequestedStudy[], dr: IDataRequestSummary): IRequestedStudy[] => {
            const studiesWithRequestId: IRequestedStudy[] = filterWithRequestId(dr)
                .filter(rs => filterIsNewStudy(rs) && canUploadIpd(rs.organizationId));

            acc.push(...studiesWithRequestId);

            return acc;
        }, []);
    }

    useEffect(() => {
        if (!dataRequests) {
            return;
        }

        const requestStudies = getStudiesFromRequests(dataRequests);
        setRequestedStudies(requestStudies || []); // default to empty if a null result is returned
    }, [dataRequests])

    return requestedStudies;
}
