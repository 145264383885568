import React, { CSSProperties } from 'react';
import { TabComponent, TabsComponent } from '@vivli/shared/components';
import { OrgMembersTabComponent } from './org-members-tab.component';
import { OrgDetailsTabComponent } from './org-details-tab.component';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { OrgRorTabComponent } from '@vivli/features/organizations/components';

const heightAndWidthStyle: CSSProperties = {
    height: '100%',
    width: '100%',
};

const containerStyle: CSSProperties = {
    position: 'relative',
    height: '100%',
    width: '100%',
};

interface OrgTabsComponentProps {
    organization: IOrganization;
}

export const OrgTabsComponent = ({ organization }: OrgTabsComponentProps) => {
    return (
        <div style={heightAndWidthStyle}>
            <div style={containerStyle}>
                <TabsComponent defaultTab={0}>
                    <TabComponent title={'Details'}>
                        <OrgDetailsTabComponent organization={organization} />
                    </TabComponent>
                    <TabComponent title={'Members'}>
                        <OrgMembersTabComponent organization={organization} />
                    </TabComponent>
                    <TabComponent title={'ROR'} disabled={!organization}>
                        <OrgRorTabComponent organization={organization} />
                    </TabComponent>
                </TabsComponent>
            </div>
        </div>
    );
};
