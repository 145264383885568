﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import tinycolor from 'tinycolor2';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { useFormContext } from 'react-hook-form';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { usePathEndingHook } from '@vivli/shared/infrastructure/hook';

const innerContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

export const ListingRequestTabComponent = ({ panelItem }) => {
    const tab = usePathEndingHook();
    const formApi = useFormContext<IListingRequest>();
    const { handleNext } = useListingRequestContext();
    const [isActive, setIsActive] = useState(false);

    const tabContainerStyle: CSSProperties = {
        ...Styles.NO_SELECT,
        maxHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT,
        minHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT - 40,
        marginBottom: 0,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        paddingLeft: Size.PADDING,
        fontSize: Size.FontSize.Large,
        backgroundColor: isActive ? 'rgb(233, 234, 239)' : Color.WHITE,
        cursor: 'pointer',
        pointerEvents: 'inherit',
        boxShadow: isActive ? 'none' : 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
        position: 'relative',
        zIndex: isActive ? 0 : 12,
    };
    const textStyle: CSSProperties = {
        color: isActive ? Color.TEXT_DARK_BLUE : tinycolor(Color.TEXT_DARK_BLUE).clone().lighten(20).toRgbString(),
        fontWeight: isActive ? Size.FontWeight.Bold : Size.FontWeight.Regular,
    };

    useEffect(() => {
        if (!tab) {
            return;
        }
        setIsActive(panelItem?.url.toLowerCase() === tab.toLowerCase());
    }, [tab]);

    return (
        <div style={tabContainerStyle} onClick={() => handleNext(formApi, panelItem?.url)}>
            <div style={innerContainerStyle}>
                <span style={textStyle}>{panelItem.title}</span>
            </div>
        </div>
    );
};
