import { useDatasetContext } from '@vivli/features/datasets/infrastructure/context';
import React, { CSSProperties } from 'react';
import { DownloadOnlyDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Styles } from '@vivli/shared/theme';
import { FieldHeaderComponent, StudyExternalLinksComponent } from '@vivli/shared/components';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    height: '100%',
};

export const DatasetAttachedDocumentsComponent = () => {
    const { study, user } = useDatasetContext();
    const { t } = useTranslation();
    const studyString = t(TranslationKeyEnum.study);

    return (
        <div style={containerStyle}>
            {user && (
                <>
                    <DownloadOnlyDataPackageFeature
                        dataPackageId={study.metaDataDataPackageId}
                        secondaryDataPackageId={study.ipdDataPackageId}
                        datasetMetaDataDoi={study.studyMetadataDoi}
                        studyOrgId={study.orgId}
                    />
                    {
                        <StudyExternalLinksComponent
                            externalLinks={study.externalLinks}
                            documentId={study.id}
                        />
                    }
                </>
            )}
            {!user && (
                <FieldHeaderComponent
                    style={Styles.FORM_ROW}
                    title={`Please log in to view and download any available ${studyString} documents. Use the Sign up dialog first if you do not yet have a login.`}
                />
            )}
        </div>
    );
};
