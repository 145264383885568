import React from 'react';
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {LoadIndicatorCenteredComponent} from "@vivli/shared/components";
import {VmStatusGuardComponent} from "./research-environment/guard/vm-status-guard.component";
import {ResearchEnvironmentComponent} from "./research-environment/research-environment.component";
import {VmAccessGuardComponent} from "./research-environment/guard/vm-access-guard.component";
import {ResearchEnvironmentContextWrapper} from "@vivli/features/data-requests/infrastructure/wrappers";

export const DataRequestResearchEnvironmentComponent = () => {
    const {dataRequest} = useDataRequestContext();

    if (!dataRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <ResearchEnvironmentContextWrapper>
            <VmStatusGuardComponent>
                <VmAccessGuardComponent>
                    <ResearchEnvironmentComponent />
                </VmAccessGuardComponent>
            </VmStatusGuardComponent>
        </ResearchEnvironmentContextWrapper>
    )
}
