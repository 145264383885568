import React, { CSSProperties } from 'react';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';

const rootStyles: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
};

export const GlobalStylesWrapper = ({ children }: IContextWrapper) => {
    return <div style={rootStyles}>{children}</div>;
};
