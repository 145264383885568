import {useConfigService} from "@vivli/core/infrastructure/context";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {ICtaButtonConfig} from "@vivli/shared/infrastructure/interface";

export const useDisplayBannerHook = () => {
    const {bannerConfig} = useConfigService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const getNumberOfBannerButtonsPresent = (): number => {
        let numberOfButtons = 0;
        if (bannerButtonShouldDisplay(bannerConfig.ctaButtonOne)) {
            numberOfButtons++;
        }
        if (bannerButtonShouldDisplay(bannerConfig.ctaButtonTwo)) {
            numberOfButtons++;
        }
        return numberOfButtons
    }
    const bannerButtonShouldDisplay = (config: ICtaButtonConfig) => (config && config.ctaButtonEnabled && config.ctaButtonText && config.ctaButtonUrl);
    const bannerTitleShouldDisplay = () => (bannerConfig && bannerConfig.bannerTitle && bannerConfig.bannerTitle.length > 0);
    const bannerDescriptionShouldDisplay = () => (bannerConfig && bannerConfig.bannerDescription && bannerConfig.bannerDescription.length > 0);

    const shouldDisplayBanner = (): boolean => {
        return (
            bannerConfig && (!isAmr && bannerConfig.displayBanner || isAmr && bannerConfig.displayBannerAmr) &&
            (
                (bannerTitleShouldDisplay()) ||
                (bannerDescriptionShouldDisplay()) ||
                getNumberOfBannerButtonsPresent() > 0
            )
        );
    };

    return {
        shouldDisplayBanner,
        getNumberOfBannerButtonsPresent,
        bannerButtonShouldDisplay,
        bannerTitleShouldDisplay,
        bannerDescriptionShouldDisplay
    };
}
