﻿import {useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {IUser} from "@vivli/shared/infrastructure/interface";
import joi, {ObjectSchema} from "joi";
import {JoiValidationOptionsConstant} from "@vivli/shared/infrastructure/constants";


const formSchema: ObjectSchema<IUser> = joi.object({
    notes: joi.string().allow('')
}).options(JoiValidationOptionsConstant)

export function useUserInfoFormHook(user: IUser, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'onChange') {

    return useForm(
        {
            mode,
            resolver: joiResolver(formSchema),
            defaultValues: user,
            reValidateMode: 'onChange'
        }
    )
}
