import {AccountInfo} from "@azure/msal-common";
import {Msal2EventTypeEnum} from "./msal-2-event-type.enum";

export class AuthEventsInitializedEvent {
    public readonly type = Msal2EventTypeEnum.AuthEventsInitialized;
}

export class UpdatingPasswordEvent {
    public readonly type = Msal2EventTypeEnum.UpdatingPasswordEvent;
}

export class UpdatingProfileEvent {
    public readonly type = Msal2EventTypeEnum.UpdatingProfileEvent;
}

export class UpdatedPasswordEvent {
    public readonly type = Msal2EventTypeEnum.UpdatedPasswordEvent;

    constructor(public idToken?: string) {
    }
}

export class UpdatedProfileEvent {
    public readonly type = Msal2EventTypeEnum.UpdatedProfileEvent;

    constructor(public idToken?: string) {
    }
}

export class SignUpEvent {
    public readonly type = Msal2EventTypeEnum.SignUpEvent;
}

export class SignedUpEvent {
    public readonly type = Msal2EventTypeEnum.SignedUpEvent;

    constructor(public idToken?: string) {
    }
}

export class LoggingInEvent {
    public readonly type = Msal2EventTypeEnum.LoggingInEvent;
}

export class SilentLoggingInEvent {
    public readonly type = Msal2EventTypeEnum.SilentLoggingInEvent;
}

export class LoginEvent {
    public readonly type = Msal2EventTypeEnum.LoginEvent;

    constructor(public account: AccountInfo, public idToken: string) {
    }
}

export class UserLoginEvent {
    public readonly type = Msal2EventTypeEnum.UserLoginEvent;
}

export class SilentLoginEvent {
    public readonly type = Msal2EventTypeEnum.SilentLoginEvent;

    constructor(public account: AccountInfo, public idToken?: string) {
    }
}

export class LogoutEvent {
    public readonly type = Msal2EventTypeEnum.LogoutEvent;
}

export class UserCancelledLogin {
    public readonly type = Msal2EventTypeEnum.UserCancelledLogin;
}

export class RefreshAuthExpirationEvent {
    public readonly type = Msal2EventTypeEnum.RefreshAuthExpirationEvent;

    constructor(public idToken: string) {
    }
}

export class UnauthorizedEvent {
    public readonly type = Msal2EventTypeEnum.UnauthorizedEvent;

    constructor(public error?: any) {
    }
}

export class FailedToInitialize {
    public readonly type = Msal2EventTypeEnum.FailedToInitialize;
}

export class LogoutWarning {
    public readonly type = Msal2EventTypeEnum.LogoutWarning;
}
