import React from 'react';
import {GridComponent} from '@vivli/shared/components';
import {ColDef} from 'ag-grid-community';
import {IDataRequest, IRequestedStudy,} from '@vivli/features/data-requests/infrastructure/interface';
import {RequestedStudyStatusButtonsRendererComponent} from "./request-studies-button-renderer.component";
import {GridCellRendererEnum} from "@vivli/shared/infrastructure/enum";

interface RequestDataStudiesGridComponentProps {
    studies: IRequestedStudy[];
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    autoHeight?: boolean;
    asyncUpdateKey?: string;
    dataRefreshing?: boolean;
    onChange?: (dataRequest: IDataRequest) => void;
}

const customComponents = [{
    component: RequestedStudyStatusButtonsRendererComponent,
    name: GridCellRendererEnum.GridDataStudyButton
}]

const RequestedStudyDescription
    = ( data: IRequestedStudy ): string => {
    return `${data.sponsorProtocolId}, ${((data.nctId)??'N/A')}, ${data.requestedStudyType}, ${data.studyRequestStatus}, ${data.organizationName}`
}
export const RequestDataStudiesGridComponent = (
    {
        studies,
        emptyMessage,
        hideFloatingFilter,
    }: RequestDataStudiesGridComponentProps) => {

    const columnDefs: ColDef[] = [
        {
            headerName: 'Requested Studies',
            valueGetter: (params: { data: IRequestedStudy }) => RequestedStudyDescription(params.data),
            tooltipValueGetter: (params) => RequestedStudyDescription(params.data),

        },
        {
            headerName: 'Requested Study Data Packages',
            cellRenderer:GridCellRendererEnum.GridDataStudyButton ,
            cellStyle: {height: '180px', padding:'15px 0'}
        }
    ]

    return (
        <GridComponent
            rowData={studies}
            fullWidthColumns={true}
            customComponents={customComponents}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={hideFloatingFilter}
            autoHeight={true}
        />
    )
}
