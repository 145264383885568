import React from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext
} from "@vivli/shared/features/data-package/components";

interface AttachmentsDataPackageViewFeatureProps {
    dataPackageId: string;
    allowUpload: boolean;
}

export const AttachmentsDataPackageFeature = (
    {
        dataPackageId,
        allowUpload
    }: AttachmentsDataPackageViewFeatureProps
) => (
    <DataPackageBaseComponent dataPackageId={dataPackageId}>
        <DataPackageContext.Consumer>
            {dataPackage => <DataPackageComponent
                dataPackage={dataPackage}
                readOnly={true}
                allowDownload={true}
                allowUpload={allowUpload}
                allowDelete={false}
                allowSubmit={false}
                availableTypes={[
                    'Research Proposal Supplement',
                    'Funding Information',
                    'Statistical Analysis Plan',
                    'Other',
                    'Unknown'
                ]}
            />}
        </DataPackageContext.Consumer>
    </DataPackageBaseComponent>
)

