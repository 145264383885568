import React, {CSSProperties} from 'react';
import {Size} from "@vivli/shared/theme";
import {CheckboxFormFieldComponent, FieldHeaderComponent} from "@vivli/shared/components";
import {
    DataRequestHintsConstant,
    DTIAttestationsForm
} from "@vivli/shared/infrastructure/constants";

const containerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: Size.INSET_SIZE
}

const checkboxFieldStyle: CSSProperties = {flexDirection: 'row-reverse', justifyContent: 'flex-end'}

export const AttestationsFormComponent = () => {
    return (
        <div style={containerStyle}>
            <div>
                <FieldHeaderComponent title='Certify Complete and Accurate'/>
                {DataRequestHintsConstant.requirementsAndAttestations.hasCertifiedCompleteAndAccurate}

                <div>
                    <CheckboxFormFieldComponent
                        name={'requirementsAndAttestations.hasCertifiedCompleteAndAccurate'}
                        label={`I certify the information provided is complete and accurate.`}
                        truncateLabel={false}
                        style={checkboxFieldStyle}
                        dataId={DTIAttestationsForm.AttestationCheckBox}
                    />
                </div>

                <FieldHeaderComponent title='Data Use Agreement'/>

                <div>
                    Please note that all Data Requestors wishing to receive access to data must execute the Data Use
                    Agreement (DUA) before the data can be provided. The DUA is the product of extensive negotiation
                    with the organizations that contribute data to Vivli, and as such, the agreement is
                    non-negotiable. The DUA form must be completed and signed and is available {<a target='_blank'
                                                                                                   href='https://vivli.org/resources/Vivli-Data-Use-Agreement/'>here</a>}.
                </div>
                <div style={{marginTop: 10}}>
                    You can either fill out the DUA form and sign it digitally, or print it out, sign it and scan it
                    as PDF. Once the DUA has been signed by your organization, please upload it using the Signed
                    Agreements tab of this data request (visible once the data request is submitted).
                </div>
                <div style={{marginTop: 10}}>
                    If you have any questions regarding the DUA, please contact a Vivli admin at <a
                    href='mailto:support@vivli.org'>support@vivli.org</a>.
                </div>
            </div>
        </div>)
}
