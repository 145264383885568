import React, {useState} from 'react';
import {
    DataPackageBaseComponent,
    DataPackageComponent,
    DataPackageContext
} from "@vivli/shared/features/data-package/components";
import {IDataPackage} from "@vivli/shared/features/data-package/infrastructure/interface";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {useDataPackageService} from "@vivli/shared/features/data-package/infrastructure/context";
import {DataPackageStatusEnum} from "@vivli/features/studies/infrastructure/enum";

interface ResultRequestDataPackageFeatureProps {
    dataPackageId: string;
    resultRequestId: string;
    dataRequestId: string;
    onPackageSubmitted: (dataPackage: IDataPackage) => void;
}

export const ResultRequestDataPackageFeature = (
    {
        dataPackageId,
        resultRequestId,
        dataRequestId,
        onPackageSubmitted
    }: ResultRequestDataPackageFeatureProps
) => {
    const [submittingDataPackage, setSubmittingDataPackage] = useState(false);
    const dataPackageService = useDataPackageService();

    const dataRequestService = useDataRequestsService();
    const modalService = useModalService();

    const handlePackageSubmitted = (dataPackage: IDataPackage) => {
        modalService.confirm('Are you sure all files have been uploaded? This action cannot be undone.', {
            title: 'Submit Files?',
            onConfirm: () => {
                setSubmittingDataPackage(true);
                dataRequestService.startHeuristics(dataRequestId, resultRequestId, dataPackage)
                    .pipe(first())
                    .subscribe(() => {
                        setSubmittingDataPackage(false);
                        onPackageSubmitted && onPackageSubmitted(dataPackage);
                    }, (error)=>{
                        dataPackageService.handleSubmitErrorWithFileCheck(error,true,"Result Request", modalService);
                    })
            }
        })
    }

    return (
        <DataPackageBaseComponent dataPackageId={dataPackageId}>
            <DataPackageContext.Consumer>
                {dataPackage => <DataPackageComponent
                    dataPackage={dataPackage}
                    allowUpload={dataPackage.status === DataPackageStatusEnum.Draft}
                    allowSubmit={true}
                    allowDelete={true}
                    onPackageSubmitted={handlePackageSubmitted}
                    disableSubmit={submittingDataPackage}
                />}
            </DataPackageContext.Consumer>
        </DataPackageBaseComponent>
    )
}
