﻿import React from 'react';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

export const WhiteBackgroundLogoComponent = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    return (
        <img
            src={
                isAmr
                    ? AssetsConstant.VIVLI_AMR_RGB_LOGO
                    : AssetsConstant.VIVLI_LOGO
            }
            width={isAmr ? 340 : 314}
            height={111}
        />
    );
};
