import React from 'react';
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {AmrSearchFiltersVerticalComponent} from "./amr/amr-search-filters-vertical.component";
import {CtSearchFiltersVerticalComponent} from "./ct/ct-search-filters-vertical.component";

export const SearchFiltersVerticalComponent = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    if (isAmr) {
        return <AmrSearchFiltersVerticalComponent />
    }

    return <CtSearchFiltersVerticalComponent />
}
