/* eslint-disable-next-line */
import {ReactNode} from "react";
import {EnquiryServiceContext} from "@vivli/features/enquiry/infastructure/context";
import {EnquiryApiService} from "@vivli/features/enquiry/infastructure/service";
import {useDefaultApiOptions} from "@vivli/shared/infrastructure/hook";

export interface EnquiryServiceContextWrappersProps {
    children?: ReactNode;
}

export const EnquiryServiceContextWrapper = ({children}: EnquiryServiceContextWrappersProps) => {
    const apiOptions = useDefaultApiOptions();
    const api = new EnquiryApiService(apiOptions);

    const provider = {
        ...api
    }
    return <EnquiryServiceContext.Provider value={provider}>
        {children}
    </EnquiryServiceContext.Provider>
}

