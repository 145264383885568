﻿import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { StorageService } from '@vivli/shared/infrastructure/service';
import { environment } from '@vivli/core/environments';
import { useMemo } from 'react';

//hook that stores the AssignedAppType (amr or default). In deployed environments,
//to access Amr mode in non-prod environments, browse to https://{domain}/mode/amr to run the app
//in Prod, an Amr user will browse to a domain already containing "amr", as in
//amr.search.vivli.org. This hook supports both usages, as well as an escape for
//local dev amr testing that uses the environment file

const storeAppType = (assignedAppType: string) => {
    StorageService.setItem('assignedAppType', assignedAppType, 'local');
};

const getAppType = () => {
    return StorageService.getItem<AssignedAppTypeEnum>('assignedAppType', 'local');
};

const initLogic = () => {
    const loweredHost = window.location.host.toLowerCase();
    const isAutomation = environment.isAutomation;
    const isLocalUrl = loweredHost.includes('vivli.local');
    const isAmrUrl = loweredHost.includes(AssignedAppTypeEnum.Amr.toLowerCase());

    // escape for vivli.local and automation testing
    if (isLocalUrl || isAutomation) {
        const { assignedAppType } = environment;

        //store the assigned appType in local storage
        storeAppType(assignedAppType);

        return assignedAppType;
    }

    // parse host to see if it includes "amr" text
    const appTypeFromUrl = isAmrUrl ? AssignedAppTypeEnum.Amr : AssignedAppTypeEnum.Default;

    // return the currently stored type if it exists in local storage and matches the
    // type that matches the url
    const currentlyStoredAppType = getAppType();
    if (currentlyStoredAppType && currentlyStoredAppType === appTypeFromUrl) {
        return currentlyStoredAppType;
    }

    // determine what type we SHOULD be using and store it
    // initialize to default
    let assignedAppType = AssignedAppTypeEnum.Default;

    // use app type from url if different
    if (appTypeFromUrl !== assignedAppType) {
        assignedAppType = AssignedAppTypeEnum.Amr;
    }

    // store the assigned appType in local storage
    storeAppType(assignedAppType);

    // leaving for future testing - JM 2/16/23
    // const getItemTest = StorageService.getItem<AssignedAppTypeEnum>('assignedAppType', 'local');

    return assignedAppType;
};

export function useAssignedAppTypeInitialization() {
    return useMemo(() => initLogic(), []);
}
