import {RestApi} from "@vivli/core/infrastructure/rest";
import {
    IAzureSearchApiService,
    IAzureSearchRequestOptions,
    IAzureSearchResponse
} from "@vivli/features/search/infrastructure/interface";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {IRestApiOptions} from "@vivli/core/infrastructure/interface";

export class AzureSearchApiService<DocumentType> extends RestApi implements IAzureSearchApiService<DocumentType> {
    private readonly _baseSearchParams: URLSearchParams;

    constructor(protected options: IRestApiOptions) {
        super(options);

        this._baseSearchParams = options.params;
    }

    private _parseUrlParams(
        {
            search,
            filter,
            orderBy,
            includeTotalCount,
            facets,
            top,
            skip,
            select
        }: IAzureSearchRequestOptions): URLSearchParams {
        const result = new URLSearchParams(this._baseSearchParams);

        if (top) {
            result.append('$top', top.toString())
        }

        if (skip) {
            result.append('$skip', skip.toString())
        }

        if (search) {
            result.append('search', search);
        }

        if (filter) {
            result.append('$filter', filter);
        }

        if (orderBy) {
            result.append('$orderby', orderBy);
        }

        if (includeTotalCount) {
            result.append('$count', 'true');
        }

        if (facets) {
            facets.forEach(facet => {
                result.append('facet', facet);
            })
        }

        if (select) {
            result.append('$select', select);
        }

        return result;
    }

    getFacets<FacetType>(options: IAzureSearchRequestOptions): Observable<FacetType> {
        const params = this._parseUrlParams(options);

        return this.handleGet<IAzureSearchResponse<DocumentType, FacetType>>('', {params})
            .pipe(map(result => result["@search.facets"]));
    }

    getDocuments(options: IAzureSearchRequestOptions): Observable<IAzureSearchResponse<DocumentType, any>> {
        const params = this._parseUrlParams(options);

        return this.handleGet<IAzureSearchResponse<DocumentType, any>>('', {params});
    }

    getDocument<T>(documentId: string, options: IAzureSearchRequestOptions): Observable<T> {
        const params = this._parseUrlParams(options);

        return this.handleGet<T>(`${documentId}`, {params});
    }
}
