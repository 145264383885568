export enum GridCellRendererEnum {
    GridCellList = 'GridCellList',
    GridCheckbox = 'GridCheckbox',
    GridCellLargeText = 'GridCellLargeText',
    DataRequestDate = 'DataRequestDate',
    DataRequestStatus = 'DataRequestStatus',
    DataRequestTitle = 'DataRequestTitle',
    MyDataRequestTitle = 'MyDataRequestTitle',
    GridDataStudyButton = 'GridDataStudyButton',
    GridCellDateTime = 'GridCellDateTime',
}
