import React from 'react';
import { useDatasetContext } from '@vivli/features/datasets/infrastructure/context';
import { Styles } from '@vivli/shared/theme';
import {
    fourAcrossStyle,
    LinkFieldComponent,
    oneAcrossStyle,
    TextAreaReadOnlyFormComponent,
    TextFieldReadOnlyFormComponent,
    threeAcrossStyle,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { PhaseEnum } from '@vivli/features/studies/infrastructure/enum';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { DesignHistoryComponent } from './design-history/design-history.component';
import { PicoComponent } from './pico/pico.component';
import { useStudyPrimaryRegistryUrlHook } from '@vivli/features/studies/infrastructure/hook';
import {DTIFormFields} from "@vivli/shared/infrastructure/constants";

export const TrialsDatasetDetailsComponent = () => {
    const config = useConfigService();
    const { study } = useDatasetContext();
    const extendedMetadata =
        config.enhancedMetadata && study.showExtendedStudyMetadata;
    const {registryUrl} = useStudyPrimaryRegistryUrlHook();
    const nctString = registryUrl(study);

    const phase = study?.phase ? PhaseEnum[study.phase] : 'Not available';
    const conditions = study?.extractedConditions
        ? study.extractedConditions.join(', ')
        : 'Not available';
    const interventions = study?.extractedInterventions
        ? study.extractedInterventions.join(', ')
        : 'Not available';
    const summary = study?.extractedBriefSummary
        ? study.extractedBriefSummary
        : 'Not available';
    const ageEligibility =
        study?.participantEligibility &&
        study?.participantEligibility.ageDescription;
    const sexesEligibility =
        study?.participantEligibility && study?.participantEligibility?.sex
            ? study.participantEligibility.sex
            : 'Not available';
    const acceptsHealthyVolunteers =
        (study?.participantEligibility?.acceptsHealthyVolunteers &&
            study?.participantEligibility?.acceptsHealthyVolunteers) ||
        '';
    const enrollment = study?.actualEnrollment
        ? study.actualEnrollment.toString()
        : 'Not available';
    const locations = study?.locationsOfStudySites
        ?.map((option) => ` ${option.name} (${option.numberSites})`)
        .toString();

    return (
        <div style={{ width: '100%' }}>
            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label={'Phase'}
                    style={twoAcrossStyle}
                    defaultValue={phase}
                    readonly={true}
                    dataId={DTIFormFields.FormPhase}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Condition or Disease'}
                    style={twoAcrossStyle}
                    defaultValue={conditions}
                    readonly={true}
                    dataId={DTIFormFields.FormCondition}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextAreaReadOnlyFormComponent
                    label={'Intervention/treatment'}
                    style={oneAcrossStyle}
                    defaultValue={interventions}
                    rows={3}
                    dataId={DTIFormFields.FormIntervention}
                />
                <TextAreaReadOnlyFormComponent
                    label={'Brief Summary'}
                    style={oneAcrossStyle}
                    defaultValue={summary}
                    rows={3}
                    dataId={DTIFormFields.FormSummary}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label={'Ages Eligible For Study'}
                    style={fourAcrossStyle}
                    defaultValue={ageEligibility}
                    readonly={true}
                    dataId={DTIFormFields.FormAgeAbility}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Sexes Eligible For Study'}
                    style={fourAcrossStyle}
                    defaultValue={sexesEligibility}
                    readonly={true}
                    dataId={DTIFormFields.FormSexEligibility}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Accepts Healthy Volunteers'}
                    style={fourAcrossStyle}
                    defaultValue={acceptsHealthyVolunteers}
                    readonly={true}
                    dataId={DTIFormFields.FormHealthyVolunteer}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Actual Enrollment'}
                    style={fourAcrossStyle}
                    defaultValue={enrollment}
                    readonly={true}
                    dataId={DTIFormFields.FormEnrollment}
                />
            </div>

            {study.locationsOfStudySites &&
                study.locationsOfStudySites.length > 0 && (
                    <div style={Styles.FORM_ROW}>
                        <TextAreaReadOnlyFormComponent
                            label={'Locations'}
                            style={oneAcrossStyle}
                            defaultValue={locations}
                            readonly={true}
                            rows={3}
                            dataId={DTIFormFields.FormLocation}
                        />
                    </div>
                )}

            {study.additionalInformation && (
                <div style={Styles.FORM_ROW}>
                    <TextAreaReadOnlyFormComponent
                        label={'Additional Information'}
                        style={oneAcrossStyle}
                        defaultValue={study.additionalInformation}
                        readonly={true}
                        rows={3}
                        dataId={DTIFormFields.AdditionalInformation}
                    />
                </div>
            )}

            {/*Todo : come back and make this better in the future*/}
            <div style={Styles.FORM_ROW}>
                {study.registryInfo &&
                    [
                        {
                            link: study.registryInfo[0].registryName,
                            title: 'Primary Registry Name',
                        },
                        {
                            link: study.registryInfo[0].registryId,
                            title: 'Primary Registry ID',
                        },
                    ].map(({ link, title }, i) => (
                        <TextFieldReadOnlyFormComponent
                            key={i}
                            label={title}
                            style={threeAcrossStyle}
                            defaultValue={link}
                            readonly={true}
                            dataId={DTIFormFields.FormLinkName('i')}
                        />
                    ))}
                {study.registryInfo &&
                    [{ link: nctString, title: 'Primary Registry Url' }].map(
                        ({ link, title }, i) => (
                            <LinkFieldComponent
                                linkTitle={nctString}
                                linkContent={nctString}
                                key={i}
                                label={title}
                                style={threeAcrossStyle}
                                dataId={DTIFormFields.FormLinkNct('i')}
                            />
                        )
                    )}
            </div>

            {extendedMetadata && (
                <>
                    <DesignHistoryComponent study={study} />
                    <PicoComponent study={study} />
                </>
            )}
        </div>
    );
};
