import React, { CSSProperties } from 'react';
import { WhiteBackgroundLogoComponent } from '@vivli/shared/components';
import { Size } from '@vivli/shared/theme';
import { loginSignUpLandingButtonStyle, outerTabStyle } from './shared/styles';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { useAuthService } from '@vivli/core/authentication';

const outerContainerStyle: CSSProperties = {
    ...outerTabStyle,
    display: 'flex',
    justifyContent: 'center',
};
const containerStyle: CSSProperties = {
    maxWidth: 700,
    minWidth: 400,
    marginTop: '4%',
};
const stepsStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
    fontSize: Size.FontSize.Larger,
    paddingTop: Size.PADDING,
    paddingBottom: Size.PADDING,
};
const boxRowStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginBottom: 10,
};
const logoPaddingStyle: CSSProperties = {
    textAlign: 'center',
    paddingBottom: Size.PADDING,
};
const adsTextStyle: CSSProperties = {
    fontSize: Size.FontSize.Large,
};
const accountStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    paddingBottom: 10,
};

export const ListingLandingViewComponent = () => {
    const { signUp, login } = useAuthService();

    return (
        <div style={outerContainerStyle} className={'scrolly'}>
            <div style={containerStyle}>
                <div style={logoPaddingStyle}>
                    <WhiteBackgroundLogoComponent />
                </div>

                <div style={stepsStyle}>Steps to share your data on Vivli:</div>

                <div style={boxRowStyle}>
                    <img
                        src={AssetsConstant.ACADEMIC_DATA_SHARING_GRAPHIC}
                        width={'100%'}
                    />
                </div>

                <div style={adsTextStyle}>
                    <p>
                        Vivli is here to make it as efficient and easy as
                        possible to share your human-subject participant level
                        data and supporting documents. The Vivli team will
                        support you every step of the way. For more information,
                        please see our webpage on{' '}
                        <a href="https://vivli.org/resources/sharedata/">
                            How to Share Data
                        </a>
                    </p>
                    <p>
                        If you have questions, please email us at{' '}
                        <a href="mailto:support@vivli.org">support@vivli.org</a>
                    </p>
                    <p>
                        As a first step, please create an account or login to
                        the platform.
                    </p>
                </div>

                <div style={accountStyle}>
                    <div
                        onClick={() => signUp()}
                        style={loginSignUpLandingButtonStyle}
                    >
                        Create Account
                    </div>

                    <div
                        onClick={() => login()}
                        style={loginSignUpLandingButtonStyle}
                    >
                        Login
                    </div>
                </div>
            </div>
        </div>
    );
};
