import React, {CSSProperties, useEffect} from 'react';
import {FieldHeaderComponent, TextFormFieldComponent, YesNoRadioFormFieldComponent} from "@vivli/shared/components";
import {useFormContext, useWatch} from 'react-hook-form';
import {Size} from "@vivli/shared/theme";

const containerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: Size.INSET_SIZE
}

const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}

const fieldStyle: CSSProperties = {
    marginBottom: '20px'
}

export const FundingFormComponent = () => {
    const formApi = useFormContext();

    const showResearchGrantDetails = useWatch({ name: 'funding.researchGrantsFromGovernments' })
    const showFundingFromEmployersDetails = useWatch({ name: 'funding.fundingFromEmployers' })
    const showAdditionalContractsAndConsultanciesDetails = useWatch({ name: 'funding.additionalContractsAndConsultancies' })
    const showFundingFromCommercialOrganizationsDetails = useWatch({ name: 'funding.fundingFromCommercialOrganizations' })

    const handleRadioChange = (fieldName: string, isChecked: boolean) => {
        if (!isChecked) {
            formApi.setValue(fieldName, '', {shouldValidate: true});
        } else {
            formApi.trigger(fieldName);
        }
    }

    useEffect(() => {
        handleRadioChange('funding.researchGrantsFromGovernmentsDetail', showResearchGrantDetails);
    }, [showResearchGrantDetails])

    useEffect(() => {
        handleRadioChange('funding.fundingFromEmployersDetail', showFundingFromEmployersDetails);
    }, [showFundingFromEmployersDetails])

    useEffect(() => {
        handleRadioChange('funding.additionalContractsAndConsultanciesDetail', showAdditionalContractsAndConsultanciesDetails);
    }, [showAdditionalContractsAndConsultanciesDetails])

    useEffect(() => {
        handleRadioChange('funding.fundingFromCommercialOrganizationsDetail', showFundingFromCommercialOrganizationsDetails);
    }, [showFundingFromCommercialOrganizationsDetails])

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title='GENERAL'/>
            <div style={bodyStyle}>
                <YesNoRadioFormFieldComponent
                    name={'funding.researchGrantsFromGovernments'}
                    style={fieldStyle}
                    label={'Is the proposed research being funded by research grants from government agencies?'}
                />

                {showResearchGrantDetails && <TextFormFieldComponent
                    name={'funding.researchGrantsFromGovernmentsDetail'}
                    style={fieldStyle}
                    label={'Please provide further details'}
                />}

                <YesNoRadioFormFieldComponent
                    name={'funding.fundingFromEmployers'}
                    style={fieldStyle}
                    label={'Is the proposed research being funded by employers through employment contracts?'}
                />

                {showFundingFromEmployersDetails && <TextFormFieldComponent
                    name={'funding.fundingFromEmployersDetail'}
                    style={fieldStyle}
                    label={'Please provide further details'}
                />}

                <YesNoRadioFormFieldComponent
                    name={'funding.additionalContractsAndConsultancies'}
                    style={fieldStyle}
                    label={'Is the proposed research being funded by additional contracts or consultancies?'}
                />

                {showAdditionalContractsAndConsultanciesDetails && <TextFormFieldComponent
                    name={'funding.additionalContractsAndConsultanciesDetail'}
                    style={fieldStyle}
                    label={'Please provide further details'}
                />}

                <YesNoRadioFormFieldComponent
                    name={'funding.fundingFromCommercialOrganizations'}
                    style={fieldStyle}
                    label={'Is the proposed research being funded by commercial organizations?'}
                />

                {showFundingFromCommercialOrganizationsDetails && <TextFormFieldComponent
                    name={'funding.fundingFromCommercialOrganizationsDetail'}
                    style={fieldStyle}
                    label={'Please provide further details'}
                />}
            </div>
        </div>
    )
}
