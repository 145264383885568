import {useEffect, useRef} from "react";
import {ISelectOption} from "@vivli/features/search/infrastructure/interface";

export function useYearRangeFilterHook({from, to, onChange, key, earliestYear}: {
    key: string,
    from: ISelectOption[],
    to: ISelectOption[],
    onChange: (key: string, fromYear: number, toYear: number, earliestYear) => void,
    earliestYear: number,
}) {
    const fromYear = () => from ? parseInt(from[0]?.value) : null;
    const toYear = () => to ? parseInt(to[0]?.value) : null;

    const fromRef = useRef(fromYear());
    const toRef = useRef(toYear());

    useEffect(() => {
        const fromChanged = fromRef.current !== fromYear();
        const toChanged = toRef.current !== toYear();

        if (!fromChanged && !toChanged){
            return;
        }

        fromRef.current = fromYear();
        toRef.current = toYear();
        onChange(key, fromYear(), toYear(), earliestYear);
    }, [from, to])
}
