import {useEffect, useState} from "react";
import {IDataRequestSummary} from "@vivli/features/data-requests/infrastructure/interface";
import {useDataRequestsService} from "@vivli/features/data-requests/infrastructure/context";
import {first} from "rxjs/operators";
import {useAssignedAppType} from "@vivli/core/infrastructure/hook";

export function useDataRequestSummaries() {
    const [dataRequestSummaries, setDataRequestSummaries] = useState<IDataRequestSummary[]>();
    const [isLoadingDataRequestSummaries, setIsLoadingDataRequestSummaries] = useState(true);
    const assignedAppType = useAssignedAppType();
    const dataRequestsService = useDataRequestsService();

    useEffect(() => {
        const dataRequestsSub = dataRequestsService.getDataRequestSummaries(assignedAppType)
            .pipe(first(dr => dr !== null))
            .subscribe(dataRequests => {
                setDataRequestSummaries(dataRequests);
                setIsLoadingDataRequestSummaries(false);
            });

        return () => {
            dataRequestsSub.unsubscribe();
        }
    }, [])

    return {
        dataRequestSummaries,
        isLoadingDataRequestSummaries
    };
}
