﻿import React from 'react';
import { outerTabStyle, tabContentContainerStyle } from '../shared/styles';
import { FieldHeaderComponent, StudyResearchTeamMemberRowsComponent } from '@vivli/shared/components';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { useFormContext } from 'react-hook-form';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';

export const InformationAboutYourTeamComponent = () => {
    const formApi = useFormContext<IListingRequest>();
    const user = useActiveUser();
    const { isReadOnly, handleNext, listingRequest } = useListingRequestContext();
    const arrayName = 'datasets[0].researchTeam';
    const explanatoryText =
        'The names that you enter using "Add Team Member" will get public recognition for their contributions to the study.  You can select one or more CRediT roles - for a list of the available roles and descriptions, click on the Help icon at the top of the field.';
    const datasetId =
        user.isVivliAdmin || listingRequest?.status == ListingRequestStatusEnum.Draft ? listingRequest?.datasets[0]?.id : null;
    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'TELL US ABOUT THE RESEARCH TEAM'} />
                <div>{explanatoryText}</div>
                <StudyResearchTeamMemberRowsComponent
                    arrayName={arrayName}
                    isReadOnly={isReadOnly}
                    showNextButton={true}
                    handleNext={() => handleNext(formApi, 'YourOrganization')}
                    datasetId={datasetId}
                />
            </div>
        </div>
    );
};
