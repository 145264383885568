export enum VmStatusEnum {
    Provisioning = 'Provisioning',
    ProvisioningFailed = 'ProvisioningFailed',
    Provisioned = 'Provisioned',
    Starting = 'Starting',
    StartingFailed = 'StartingFailed',
    Running = 'Running',
    Stopping = 'Stopping',
    Stopped = 'Stopped',
    StoppingFailed = 'StoppingFailed',
    DeProvisioning = 'DeProvisioning',
    DeProvisioningFailed = 'DeProvisioningFailed',
    DeProvisioned = 'DeProvisioned',
    AddingDataPackages = 'AddingDataPackages',
    AddingDataPackagesFailed = 'AddingDataPackagesFailed',
    Attaching = 'Attaching',
    None = 'None',
    vmStatusNotCreated = 'No VM Created Yet'
}
