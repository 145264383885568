interface ITabFocusHook {
    onTabFocus: () => void,
    onTabBlur: () => void
}

export function useTabFocusHook() {
    return ({onTabFocus, onTabBlur}: ITabFocusHook) => {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                onTabFocus();
            } else {
                onTabBlur();
            }
        });
    }
}
