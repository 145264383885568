﻿import {useLocation} from "react-router";


export function useDataRequestAdminPath() {
    const location = useLocation();
    const path = location.pathname.toLowerCase();

    const isAdminRoute = path.search('admindatarequests') >= 0;

    return isAdminRoute;
}
