﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { adminDivStyle, nextButtonStyle, outerTabStyle, tabContentContainerStyle } from '../shared/styles';
import {
    ButtonComponent,
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    oneAcrossStyle,
    TextFormFieldComponent,
    twoAcrossStyle,
    YesNoRadioComponent,
} from '@vivli/shared/components';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { useFormContext, useWatch } from 'react-hook-form';
import { Styles } from '@vivli/shared/theme';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { useMapListToFormHook } from '@vivli/shared/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { vivliOrgHint } from '@vivli/features/listing-request/infrastructure/constants';

const infoTextStyle: CSSProperties = {
    lineHeight: '1.4',
    fontFamily: 'Verdana, sans-serif',
};

const rowStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 10,
};

const yesNoStyle: CSSProperties = {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    boxShadow: 'none',
};

const emailButtonStyle: CSSProperties = {
    ...nextButtonStyle,
    marginRight: 20,
};

const alphabeticalSort = (a, b) => {
    return a.title < b.title ? -1 : 1;
};

export const YourOrganizationComponent = () => {
    const user = useActiveUser();
    const { isReadOnly, handleNext } = useListingRequestContext();
    const { organizations } = useOrganizations();
    const formApi = useFormContext<IListingRequest>();
    const [showOrgDropdown, setShowOrgDropdown] = useState(true);

    const organizationsOptions = organizations
        ?.map(
            (org) =>
                ({
                    title: org.name,
                    value: org.id,
                    code: org.code,
                } as IDropdownMenuItem)
        )
        .sort(alphabeticalSort);

    //set name + code values for org
    useMapListToFormHook({
        formFieldName: 'datasets[0].orgId',
        formFieldToUpdate: 'datasets[0].orgCode',
        objectValueToReturn: 'code',
        listOfObjects: organizationsOptions,
        formApi: formApi,
    });
    useMapListToFormHook({
        formFieldName: 'datasets[0].orgId',
        formFieldToUpdate: 'datasets[0].orgName',
        objectValueToReturn: 'title',
        listOfObjects: organizationsOptions,
        formApi: formApi,
    });

    const getOrganizationsDiv = () => {
        if (showOrgDropdown) {
            return (
                <div style={Styles.FORM_ROW}>
                    <DropdownFormFieldComponent
                        label={'Organizations'}
                        items={organizationsOptions}
                        name={'datasets[0].orgId'}
                        style={twoAcrossStyle}
                        readonly={isReadOnly}
                    />
                </div>
            );
        }
        return (
            <>
                <div style={Styles.FORM_ROW}>
                    <TextFormFieldComponent
                        name={'datasets[0].orgName'}
                        label={'Organization Name'}
                        style={twoAcrossStyle}
                        readonly={isReadOnly}
                    />
                    <TextFormFieldComponent
                        name={'datasets[0].orgCode'}
                        label={'Organization Code'}
                        style={twoAcrossStyle}
                        readonly={isReadOnly}
                    />
                </div>
            </>
        );
    };

    const orgCodeWatch = useWatch({ name: 'datasets[0].orgCode' });

    const handleRadioChange = (isChecked) => {
        if (!isChecked) {
            formApi.setValue('datasets[0].orgId', '');
            formApi.setValue('datasets[0].orgName', formApi.getValues().datasets[0].orgName);
            formApi.setValue('datasets[0].orgCode', formApi.getValues().datasets[0].orgCode);
        }
        setShowOrgDropdown(isChecked);
    };

    const handleEmail = () => {
        location.href = 'mailto:support@vivli.org';
    };

    useEffect(() => {
        if (isReadOnly) {
            setShowOrgDropdown(true);
        }
    }, [isReadOnly]);

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'TELL US ABOUT YOUR ORGANIZATION'} />
                {/* User View */}
                <div style={{ marginBottom: 15 }}>
                    <div style={Styles.FORM_ROW}>
                        <TextFormFieldComponent
                            name={'requester.institutionName'}
                            label={'Enter the full name of your organization'}
                            readonly={isReadOnly}
                            style={orgCodeWatch ? twoAcrossStyle : oneAcrossStyle}
                        />
                        {orgCodeWatch && (
                            <TextFormFieldComponent
                                name={'datasets[0].orgName'}
                                label={'Vivli Assigned Organization Name'}
                                readonly={true}
                                hint={vivliOrgHint}
                                style={twoAcrossStyle}
                            />
                        )}
                    </div>
                    <div style={Styles.FORM_ROW}>
                        <TextFormFieldComponent
                            name={'numberOfIntendedStudies'}
                            label={'How many studies do you expect to submit at this time'}
                            readonly={isReadOnly}
                            style={oneAcrossStyle}
                        />
                    </div>
                    <div>
                        <p>
                            If you have more than 2 studies that you want to share at this time, please contact Vivli by emailing{' '}
                            <a style={infoTextStyle} href="mailto:support@vivli.org">
                                support@vivli.org
                            </a>{' '}
                            as we have other ways to make this process more efficient for you.
                        </p>
                    </div>
                    <div style={rowStyle}>
                        <ButtonComponent style={emailButtonStyle} onClick={handleEmail}>
                            Contact Vivli
                        </ButtonComponent>
                        <ButtonComponent style={nextButtonStyle} onClick={() => handleNext(formApi, 'YourStudy')}>
                            Next Page
                        </ButtonComponent>
                    </div>
                </div>

                {/* Admin View */}
                {user.isVivliAdmin && (
                    <div style={adminDivStyle}>
                        <p>
                            For the Vivli Admin - click Edit to choose an existing organization, or provide a name and code to create a new
                            organization.
                        </p>
                        {!isReadOnly && (
                            <div style={Styles.FORM_ROW}>
                                <YesNoRadioComponent
                                    onChange={handleRadioChange}
                                    options={['Use existing organization', 'Create a new organization with the following:']}
                                    divStyle={yesNoStyle}
                                    defaultValue={true}
                                />
                            </div>
                        )}
                        {!isReadOnly && getOrganizationsDiv()}
                    </div>
                )}
            </div>
        </div>
    );
};
