﻿import React, {CSSProperties, useEffect, useState} from "react";
import {useActiveUser, useConfigService} from "@vivli/core/infrastructure/context";
import {IMetricsReport, IMetricsReportDetails} from "@vivli/features/dashboard/infrastructure/interface";
import {StatusDataPointEnum, TimeIntervalMetricsEnum} from "@vivli/features/dashboard/infrastructure/enum";
import {Size} from "@vivli/shared/theme";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {MetricsReportComponent} from "./metrics-report.component";
import {useMetricsReportFormHook} from "@vivli/features/dashboard/infrastructure/hook";
import {FormProvider} from 'react-hook-form';
import {
    ButtonComponent,
    DatePickerFormFieldComponent,
    DropdownFormFieldComponent,
    MultiEntryTextFieldComponent,
} from "@vivli/shared/components";
import {MetricsConstants} from "@vivli/features/dashboard/infrastructure/constants";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import moment from "moment/moment";
import {useMetricsService} from "@vivli/features/dashboard/infrastructure/context";
import {first} from "rxjs/operators";
import {useModalService} from "@vivli/shared/infrastructure/context";

const flexStyle: CSSProperties = {
    display: 'flex'
}
const flexOneStyle: CSSProperties = {
    flex: 1
}
const containerStyle: CSSProperties = {
    margin: `0 ${Size.INSET_SIZE}px`
}
const adminContainerStyle: CSSProperties = {
    ...flexStyle,
    position: 'relative'
}
const generateButtonStyle: CSSProperties = {
    ...flexStyle,
    ...flexOneStyle,
    alignItems: 'center'
}
const printButtonStyle: CSSProperties = {
    marginLeft: MetricsConstants.Sizing.SPACING
}
const dateStyle: CSSProperties = {
    ...flexOneStyle,
    marginRight: MetricsConstants.Sizing.SPACING
}
const orgDropdownStyle: CSSProperties = {
    ...flexOneStyle,
    maxWidth: `calc(33% - ${MetricsConstants.Sizing.FIELD_OFFSET}px)`,
    marginBottom: Size.FIELD_SEPARATION,
    marginRight: MetricsConstants.Sizing.SPACING,
    height: '3.75em'
}

export const MetricsDashboardComponent = () => {
    const user = useActiveUser();
    const allOption = {value: "all", title: 'All'} as IDropdownMenuItem;
    const currentOrg = user?.orgMemberships?.find(om => om.isOrgAdmin && !om.isDisabled) ?? null;
    const defaultOrgValue = user?.isVivliAdmin ? allOption : {value: currentOrg.orgId, title: currentOrg.orgName};
    const metricsService = useMetricsService();
    const modalService = useModalService();
    const formApi = useMetricsReportFormHook(defaultOrgValue);
    const {organizations} = useOrganizations();
    const {metricDecimalPlaces} = useConfigService();

    const alphabeticalSort = (a, b) => {
        return a.title < b.title ? -1 : 1;
    };

    const [decimalPlaces, setDecimalPlaces] = useState<number>(1);
    const [metricsReport, setMetricsReport] = useState<IMetricsReport>(null);
    const [isAggregateReport, setIsAggregateReport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataRequestIds, setDataRequestIds] = useState([]);
    const [validMaxForStart, setValidMaxForStart] = useState<Date>(new Date());
    const [validMinForEnd, setValidMinForEnd] = useState<Date>(null);

    const endDate = formApi.watch('endDate');
    const startDate = formApi.watch('startDate');
    const orgId = formApi.watch('orgId');
    const showOrganizationsDropdown = organizations?.length > 0;

    const organizationsOptions = organizations?.map(org => ({
        title: org.name,
        value: org.id
    }) as IDropdownMenuItem).sort(alphabeticalSort);

    const handleSetValidMaxForStart = () => {
        if (endDate) {
            setValidMaxForStart(new Date(endDate));
        } else {
            setValidMaxForStart(new Date());
        }
    }
    const handleSetValidMinForEnd = () => {
        if (startDate) {
            setValidMinForEnd(new Date(startDate));
        } else {
            setValidMinForEnd(null);
        }
    }
    const handleError = () => {
        modalService.error;
        setIsLoading(false);
        setMetricsReport(null);
    }

    const updateDecimalPlaces = () => {
        if (metricDecimalPlaces && metricDecimalPlaces !== `${decimalPlaces}`) {
            const decimalPlaces = parseInt(metricDecimalPlaces, 10);
            if (!isNaN(decimalPlaces)) {
                setDecimalPlaces(decimalPlaces);
            }
        }
    }
    const roundMetrics = (report: IMetricsReport) => {
        const reportCopy = report;
        Object.keys(TimeIntervalMetricsEnum).forEach(timeMetric => {
            Object.keys(StatusDataPointEnum).forEach(sdp => {
                reportCopy.singleContributorTimeMetrics[timeMetric][sdp] =
                    parseFloat(reportCopy.singleContributorTimeMetrics[timeMetric][sdp]?.toFixed(decimalPlaces));
                reportCopy.multipleContributorTimeMetrics[timeMetric][sdp] =
                    parseFloat(reportCopy.multipleContributorTimeMetrics[timeMetric][sdp]?.toFixed(decimalPlaces));
            });
        });
        return reportCopy;
    }
    const getQueryString = (metricsDetails: IMetricsReportDetails) => {
        let paramArray = [];
        if (metricsDetails.startDate) {
            paramArray.push(`startDate=${moment(metricsDetails.startDate).startOf('day').toISOString()}`);
        }
        if (metricsDetails.endDate) {
            paramArray.push(`endDate=${moment(metricsDetails.endDate).endOf('day').toISOString()}`);
        }
        if (dataRequestIds?.length) {
            paramArray = [
                ...paramArray,
                ...dataRequestIds.map(id => `dataRequestId=${id}`),
            ];
        }
        return paramArray.length ? paramArray.join('&') : null;
    }

    const printReport = () => {
        if (window.onbeforeprint) {
            window.onbeforeprint(null);
        }
        setTimeout(() => {
            window.print();
            if (window.onafterprint) {
                window.onafterprint(null);
            }
        });
    }

    const handleGenerateReport = (metricsDetails: IMetricsReportDetails) => {
        setIsLoading(true);
        setMetricsReport(null);
        const queryString = getQueryString(metricsDetails);
        const org = orgId === "all" ? null : orgId;
        if (user.isVivliAdmin && !org) {
            metricsService.getAggregateMetricsReport(queryString)
                .pipe(first())
                .subscribe(result => {
                    setMetricsReport(roundMetrics(result));
                    setIsAggregateReport(true);
                    setIsLoading(false);
                }, handleError);
        } else {
            metricsService.getMetricsReport(queryString, org)
                .pipe(first())
                .subscribe(result => {
                    setMetricsReport(roundMetrics(result));
                    setIsAggregateReport(false);
                    setIsLoading(false);
                }, handleError);
        }
    }

    useEffect(() => {
        if (startDate !== validMinForEnd) {
            handleSetValidMinForEnd();
        }
    }, [startDate])
    useEffect(() => {
        if (endDate !== validMaxForStart) {
            handleSetValidMaxForStart();
        }
    }, [endDate])

    useEffect(() => {
        updateDecimalPlaces();

    }, [metricDecimalPlaces])

    return (
        <div className={'metrics-dashboard'} style={flexOneStyle}>
            <FormProvider {...formApi}>
                <div style={containerStyle} className={'do-not-print'}>
                    {user.isVivliAdmin &&
                        <div style={adminContainerStyle}>
                            {showOrganizationsDropdown &&
                                <DropdownFormFieldComponent
                                    name={`orgId`}
                                    label={"Data Contributor Organization"}
                                    items={[allOption, ...organizationsOptions]}
                                    style={orgDropdownStyle}
                                />
                            }

                            <MultiEntryTextFieldComponent
                                label={'Data Request IDs'}
                                onChange={setDataRequestIds}
                                showClearAllButton={true}
                                clearButtonText={'Clear IDs'}
                            />
                        </div>
                    }
                    <div style={flexStyle}>
                        <DatePickerFormFieldComponent
                            name={'startDate'}
                            label={'From'}
                            style={dateStyle}
                            maxDate={validMaxForStart}
                            placeholderText={'MM/DD/YYYY'}
                            isClearable={true}
                        />
                        <DatePickerFormFieldComponent
                            name={'endDate'}
                            label={'To'}
                            style={dateStyle}
                            minDate={validMinForEnd}
                            placeholderText={'MM/DD/YYYY'}
                            isClearable={true}
                        />

                        <div style={generateButtonStyle}>
                            <ButtonComponent
                                isLoading={isLoading}
                                onClick={formApi.handleSubmit(handleGenerateReport)}
                                disabled={isLoading || !formApi.formState.isValid}
                            >
                                Generate Metrics Report
                            </ButtonComponent>
                            {metricsReport &&
                                <ButtonComponent onClick={printReport} style={printButtonStyle}>
                                    Print Report
                                </ButtonComponent>
                            }
                        </div>
                    </div>
                </div>
            </FormProvider>
            {metricsReport &&
                <MetricsReportComponent
                    metricsReport={metricsReport}
                    isAggregateReport={isAggregateReport}
                />
            }
        </div>
    )

}
