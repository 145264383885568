import {IFacet} from "@vivli/shared/infrastructure/interface";
import {AssignedAppTypeEnum} from "@vivli/shared/infrastructure/enum";
import {AmrFacetsConstant, ClinicalTrialsFacetsConstant} from "@vivli/shared/infrastructure/constants";

export class AzureSearchService {
    private _getFacetArg = (title: string, arg: any) => {
        return `,${title}:${arg}`;
    }

    private _buildFacetString = (facets: IFacet[]): string => {
        let result = '';

        facets.forEach(facet => {
            result += `&facet=${facet.name}`;

            if (!facet.arguments) { return; }

            if (facet.arguments.count) {
                result += this._getFacetArg('count', facet.arguments.count);
            }
            if (facet.arguments.sort) {
                result += ',sort:value';
            }

            if (facet.arguments.interval) {
                result += this._getFacetArg('interval', facet.arguments.interval);
            }
        })

        return result;
    }

    getFacetString = (assignedAppType: AssignedAppTypeEnum) => {
        switch (assignedAppType) {
            case AssignedAppTypeEnum.Amr:
                return this._buildFacetString(AmrFacetsConstant);
            default:
                return this._buildFacetString(ClinicalTrialsFacetsConstant);
        }
    }

    getAssignedAppTypeString = (assignedAppType: AssignedAppTypeEnum) => {
        return `assignedAppType eq '${assignedAppType}'`;
    }

    getAssignedAppTypeFilterString = (assignedAppType: AssignedAppTypeEnum) => {
        return `&$filter=${this.getAssignedAppTypeString(assignedAppType)}`;
    }
}
