import {useEffect, useState} from "react";
import {IStudy} from "@vivli/features/studies/infrastructure/interface";
import {useStudiesService} from "@vivli/features/studies/infrastructure/context";
import {first} from "rxjs/operators";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useDataPackageService} from "@vivli/shared/features/data-package/infrastructure/context";
import {useModalService} from "@vivli/shared/infrastructure/context";

export function useStudyWithUserCheck(studyId: string, fromDoi?: boolean, needUsedIn?: boolean) {
    const [study, setStudy] = useState<IStudy>();
    const [studyUsedIn, setStudyUsedIn] = useState<string>(null); //string listing data requests studies used in
    const [studyIpdDataPackageSubmitted, setStudyIpdDataPackageSubmitted] = useState<boolean>(false); //is IPD data package submitted

    const studiesService = useStudiesService();
    const dataPackageService = useDataPackageService();
    const user = useActiveUser();
    const modalService = useModalService();

    const handleError = (error: string) => {
        modalService.error(error);
    }

    useEffect(() => {
        if (!studyId) {
            return;
        }

        if (!fromDoi) {
            //normal case where ID used is study ID
            if (user) {
                studiesService.getStudy(studyId)
                    .pipe(first())
                    .subscribe(setStudy);
                //check to see if "usedIn" string is required along with study access
                const thisStudy = study;
                const isOwner = user.orgMemberships?.find(om => om.orgId === thisStudy?.orgId)?.isOrgAdmin;
                if (user.isVivliAdmin || isOwner || needUsedIn){
                    studiesService.getUsedIn(studyId)
                        .pipe(first())
                        .subscribe(setStudyUsedIn);
                }
            } else {
                //anonymous access allowed without logged in user
                studiesService.getStudyAnonymous(studyId)
                    .pipe(first())
                    .subscribe(setStudy);
            }
        } else {
            //ID in this case comes from the DOI, not the study id
            studiesService.getStudyFromDoiId(studyId)
                .pipe(first())
                .subscribe(setStudy, handleError);
        }
    }, [studyId])

    useEffect(() => {
        if (study === null || study === undefined) {
            return;
        }
        if (study.ipdDataPackageId) {
            dataPackageService.getDataPackageSubmitStatus(study.ipdDataPackageId)
                .pipe(first())
                .subscribe(setStudyIpdDataPackageSubmitted);
        }
        else
        {// for 8187, if studyIPD DataPackage does not exist, and an OrgId is in place, then create the missing IPD package document
            if (study.orgId) {
                studiesService.createIpdDataPackage(studyId)
                    .pipe(first())
                    .subscribe(setStudy);
            }
        }
    }, [study])



    return {
        study,
        setStudy,
        studyUsedIn,
        studyIpdDataPackageSubmitted,
        setStudyIpdDataPackageSubmitted,
    };
}
