import React, {CSSProperties} from 'react';
import {ButtonComponent, LoadIndicatorComponent} from "@vivli/shared/components";
import {Color} from "@vivli/shared/theme";
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const buttonWrapperStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingTop: '20px'
};

const cancelBtnStyle: CSSProperties = {marginLeft: '10px', backgroundColor: Color.VIVLI_RED}

interface TeamMemberModalButtonsComponentProps {
    onCancel: () => void,
    onSave: () => void,
    isLoading?: boolean,
    showSaveButton?: boolean;
}

export const TeamMemberModalButtonsComponent = (
    {
        onCancel,
        onSave,
        isLoading,
        showSaveButton = true
    }: TeamMemberModalButtonsComponentProps
) => {
    return(
        <div style={buttonWrapperStyle}>
            <ButtonComponent
                disabled={isLoading}
                onClick={() => {
                    onCancel && onCancel();
                }}
                style={cancelBtnStyle}
            >Cancel</ButtonComponent>

            {showSaveButton && <ButtonComponent onClick={onSave} dataId={DTICommonConst.OkButton}>
                {isLoading ? <LoadIndicatorComponent/> : 'OK'}
            </ButtonComponent>}
        </div>
    )
}
