﻿import React, {CSSProperties} from "react";
import {Color, Size} from "@vivli/shared/theme";

export const internalMenuButtonOuterContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center'
}

export const internalMenuButtonContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%',
    ':hover': {
        opacity: .7,
    },
    ':active': {
        top: 1,
    },
    transitionProperty: 'opacity',
    transitionDuration: '.3s',
    transition: `background-color ${Size.HOVER_TRANSITION_TIME}s`,
} as React.CSSProperties;

export const internalMenuTextStyle: CSSProperties = {
    paddingRight: Size.PADDING,
    color: Color.WHITE,
    fontSize: Size.FontSize.Medium,
}
