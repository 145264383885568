﻿import {Color, Size} from '@vivli/shared/theme';

const MetricsColors = {
    DARK_BLUE_TEXT: Color.VIVLI_DARK_BLUE,
    LIGHT_GRAY_TEXT: 'rgb(134,145,153)',
    SIMPLE_METRICS_PRIMARY: Color.TEXT_DARK_BLUE,
    SINGLE_DC_PRIMARY: Color.VIVLI_DARKER_GREEN,
    MULTIPLE_DC_PRIMARY: Color.VIVLI_RED,
    CHART_SECONDARY: 'rgb(220,228,229)',
    SUMMARY_HEADER_BLUE: 'rgb(16, 119, 179)',
};

const spacingBase = 20;
const MetricsSizing = {
    SPACING: spacingBase,
    DOUBLE_SPACING: 2 * spacingBase,
    METRICS_HEADER_PADDING: 12,
    CHART_PRINT_WIDTH: 1050,
    REPORT_HEADER_FONT_SIZE: 23,
    METRICS_HEADER_FONT_SIZE: Size.FontSize.Large,
    SECTION_HEADER_FONT_SIZE: Size.FontSize.Large * 1.2,
    STANDARD_CHART_HEIGHT: 300,
    AGGREGATE_CHART_HEIGHT: 200,
    LABEL_OFFSET: 16,
    FIELD_OFFSET: 8,
};

const GeneralMetricsHeaders = {
    totalDataRequests: 'Total data requests',
    totalSubmittedRequests: 'Total submitted requests',
    totalDraftRequests: 'Total draft requests',
};

const DetailedMetricsHeaders = {
    currentDraftDataRequests: 'Current draft data requests',
    draftDataRequestsDeleted: 'Draft data requests deleted',
    dataRequestsInProgress: 'Data requests in progress',
    currentDataRequestsInRevision: 'Current data requests (in revision)',
    dataRequestsFulfilled: 'Data requests fulfilled',
    dataRequestsNotApproved: 'Data requests not approved',
    withdrawn: 'Withdrawn',
    archived: 'Archived',
    analysisAndPublication: 'Analysis and publication',
    published: 'Published',
};

const SponsorMetricsHeaders = {
    numberOfMultiSponsorRequestsSubmitted: '# Multi sponsor requests (submitted)',
    numberOfSingleSponsorRequestsSubmitted: '# Single sponsor requests (submitted)',
};

const MetricsText = {
    SIMPLE_METRIC_CURRENT_DATA_TITLE: 'CURRENT data requests in progress',
    SIMPLE_METRIC_TOTAL_DATA_TITLE: 'TOTAL data request metrics to date',
    TIME_CHART_CONTAINER_CLASS: 'timeChartContainer',
    SECTION_HEADER_SINGLE_DC: (requestCount: number) => `Data Requests Submitted Involving Single Data Contributors (N = ${requestCount})`,
    SECTION_HEADER_MULTIPLE_DC: (requestCount: number) => `Data Requests Submitted Involving Multiple Data Contributors (N = ${requestCount})`,
    ADMIN_STAT_SUMMARY_HEADER_1: 'Overall metric',
    ADMIN_STAT_SUMMARY_HEADER_2: 'Value',
    CHART_TITLE: (orgName: string) => `How ${orgName} compares to the average for all data contributors`,
    CHART_TITLE_ALL: 'Average for all data contributors',
    CHART_ORG_LEGEND_LABEL: 'YOU',
    CHART_ALL_LEGEND_LABEL: 'ALL DATA CONTRIBUTORS',
    CHART_LEGEND_DESCRIPTION_LABEL: 'Average time to complete each step (days)',
    TIME_LIST_TITLE: 'Your average time to complete each step',
    TIME_LIST_TITLE_ALL: 'All data contributors\' average time to complete each step',
    TIME_LIST_COL_HEADER_1: 'STAGE',
    TIME_LIST_COL_HEADER_2: 'AVERAGE',
    TIME_LIST_COL_HEADER_3: 'MIN - MAX',
    TIME_LIST_OVERALL_PROCESS_TEXT: 'Overall Process to Publication',
    AVERAGE_SUMMARY_TITLE: 'Time in days from submission to publication',
    AVERAGE_SUMMARY_LABEL_1: 'Your average (days)',
    AVERAGE_SUMMARY_LABEL_2: 'Average for all data contributors (days)',
    AVERAGE_SUMMARY_LABEL_3: 'You compared to all data contributors',
    PRINT_FLAG: 'PrintFlag',
    PRINT_DIALOG_EVENT_COUNTER: 'PrintDialogEventCounter',
    OVERALL_TIME_TO_PUBLICATION_KEY: 'timeFromSubmissionToPublication',
    ADMIN_STAT_SUMMARY_GROUP_HEADERS : {
        generalMetrics: GeneralMetricsHeaders,
        detailedMetrics: DetailedMetricsHeaders,
        sponsorMetrics: SponsorMetricsHeaders,
    },
};

export const MetricsConstants = {
    Text: MetricsText,
    Colors: MetricsColors,
    Sizing: MetricsSizing,
};
