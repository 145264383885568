﻿import React, {CSSProperties} from "react";
import {
    CheckboxFormFieldComponent,
    DatePickerFormFieldComponent,
    FieldHeaderComponent,
    twoAcrossStyle
} from "@vivli/shared/components";
import {Size, Styles} from "@vivli/shared/theme";
import {embargoHint} from "@vivli/features/studies/infrastructure/constants";
import {DTICTFormFields} from "@vivli/shared/infrastructure/constants";

const checkboxTwoAcrossStyle: CSSProperties = {
    flex: '1 1 50%',
    maxWidth: '49%',
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
    backgroundColor: 'white',
    boxShadow: 'none'
}

export const PublishingAndContactingComponent = ()  => {
    return (
        <div style={{width: '100%'}}>
            <FieldHeaderComponent title={'PUBLISHING AND CONTACTING OWNER'}/>
            <div style={Styles.FORM_ROW}>
                {/*//both these components are editable for owners as well as vivli admins!*/}
                <DatePickerFormFieldComponent
                    name='embargoDate'
                    label='Embargo Date'
                    style={twoAcrossStyle}
                    readonly={false}
                    hint={embargoHint}
                    dataId={DTICTFormFields.StudyDetails('GoDate')}
                />
                <CheckboxFormFieldComponent
                    name={'allowRequestersToContactStudyOwner'}
                    label={'Allow Requesters to Contact Study Owner'}
                    style={checkboxTwoAcrossStyle}
                    truncateLabel={false}
                    readonly={false}
                    dataId={DTICTFormFields.FormCheckBox('AllowStudyOwner')}
                />
            </div>
        </div>
    )
}
