import React, { useEffect, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { AnonmyousApiService } from '@vivli/core/infrastructure/service';
import { ErrorComponent } from '@vivli/shared/components';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { environment } from '@vivli/core/environments';

export const ErrorBoundaryWrapper = ({ children }: IContextWrapper) => {
    const [reactPlugin, setReactPlugin] = useState<ReactPlugin>();
    const { getConfig } = new AnonmyousApiService({ baseUrl: '/' });

    const setupAppInsights = (instrumentationKey: string) => {
        // skip app insights for local development
        if (!instrumentationKey || environment.isLocal) {
            return;
        }

        const browserHistory = createBrowserHistory();
        const reactPlugin = new ReactPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                },
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                enableAjaxErrorStatusText: true,
                enableAjaxPerfTracking: true,
                enableAutoRouteTracking: true,
                enableUnhandledPromiseRejectionTracking: true,
                autoTrackPageVisitTime: true,
            },
        });

        appInsights.loadAppInsights();
        appInsights.trackPageView();

        setReactPlugin(reactPlugin);
    };

    useEffect(() => {
        const sub = getConfig().subscribe((config) => {
            if (config?.instrumentationKey) {
                setupAppInsights(config.instrumentationKey);
            }
        });

        return () => {
            sub.unsubscribe();
        };
    }, []);

    // skip app insights for local development
    if (environment.isLocal) {
        return children;
    }

    if (!reactPlugin) {
        return <div></div>;
    }

    return (
        reactPlugin && (
            <AppInsightsErrorBoundary onError={() => <ErrorComponent />} appInsights={reactPlugin}>
                <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
            </AppInsightsErrorBoundary>
        )
    );
};
