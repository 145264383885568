﻿import React, {CSSProperties, useEffect} from "react";
import {
    CheckboxListFormFieldComponent,
    FieldHeaderComponent,
    TextFormFieldComponent,
    twoAcrossStyle
} from "@vivli/shared/components";
import {useRefState} from "@vivli/shared/infrastructure/hook";
import {useFormContext, useWatch} from "react-hook-form";
import {OutcomeGroupNames, OutcomesOptions} from "@vivli/features/data-requests/infrastructure/constants";
import {ICheckboxMenuItem} from "@vivli/shared/infrastructure/interface";

const twoAcrossNoShadowStyle: CSSProperties = {
    ...twoAcrossStyle,
    boxShadow: 'none'
}
const formFieldStyle: CSSProperties = {
    marginBottom: '20px'
}

export const OutcomesComponent = () => {
    const formApi = useFormContext();
    const outcomesWatch = useWatch({name: 'researchProposal.outcomes'});
    const [showOtherDescFieldRef, showOtherDescriptionField, setShowOtherDescriptionField] = useRefState(false);

    const createCheckboxMenuItem = (outcomeOption): ICheckboxMenuItem => {
        const currentValues = formApi.getValues('researchProposal.outcomes') as string[];
        return {
            title: outcomeOption.label,
            value: outcomeOption.value,
            checked: currentValues?.some(x => x === outcomeOption.value),
            group: outcomeOption.groupName
        }
    }

    const getCheckboxMenuItems = () => {
        return OutcomesOptions.map(createCheckboxMenuItem);
    }

    useEffect(() => {
        if (!outcomesWatch) {
            return;
        }

        const otherDescFieldName = 'researchProposal.outcomesOtherDescription';
        const showDescField = outcomesWatch.some(p => p === "Other");

        if (showDescField !== showOtherDescFieldRef.current) {
            setShowOtherDescriptionField(showDescField);

            // clear the form value if other is no longer checked
            if (!showDescField) {
                formApi.setValue(otherDescFieldName, '', {shouldValidate: true});
            } else {
                formApi.trigger(otherDescFieldName, { shouldFocus: true });
            }
        }

    }, [outcomesWatch])


    return (
        <div style={twoAcrossNoShadowStyle}>
            <FieldHeaderComponent title='OUTCOME(S)' />

            <CheckboxListFormFieldComponent
                name={'researchProposal.outcomes'}
                checkedListItems={getCheckboxMenuItems()}
                checkboxGroupNames={OutcomeGroupNames}
                groupStyle={{paddingBottom: 10}}
                fieldStyle={{padding: 2}}
                truncateLabel={false}
            />

            {showOtherDescriptionField && <TextFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.outcomesOtherDescription'}
                label={'Please provide further details'}
            />}
        </div>
    )
}
