﻿import React, { CSSProperties } from 'react';
import tinycolor from 'tinycolor2';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { useNavigate } from 'react-router-dom';
import {DTICommonConst} from "@vivli/shared/infrastructure/constants";

const innerDivStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '35px 1fr',
    alignItems: 'center',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

interface NavPanelComponentProps {
    panelItem: { name?: string; title?: string; icon?: { active: any; inactive: any }; url?: string; defaultTab?: string };
    panelGap: number;
    actualAdminLeftPanelWidth: number;
}
export const NavPanelComponent = ({ panelItem, panelGap, actualAdminLeftPanelWidth }: NavPanelComponentProps) => {
    const navigate = useNavigate();
    const isActive = window.location.href.includes('/' + panelItem.url);

    //Calculated Styles
    const panelWidth = isActive ? actualAdminLeftPanelWidth : actualAdminLeftPanelWidth - panelGap;
    const backgroundColor = isActive ? Color.LIGHT_GRAY : Color.WHITE;
    const spanStyle: CSSProperties = {
        textAlign: 'left',
        color: isActive ? Color.TEXT_DARK_BLUE : tinycolor(Color.TEXT_DARK_BLUE).clone().lighten(20).toRgbString(),
        fontWeight: isActive ? Size.FontWeight.Bold : Size.FontWeight.Regular,
    };
    const panelStyle: CSSProperties = {
        ...Styles.NO_SELECT,
        width: panelWidth,
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0 5px 15px',
        fontSize: Size.FontSize.Large,
        backgroundColor: backgroundColor,
        cursor: panelItem.url ? 'pointer' : 'default',
        pointerEvents: 'inherit',
    };
    const panelClass = 'adminPanel_' + (panelItem.title ? panelItem.title.replace(/[^\w]/gi, '') : 'empty');

    const handleClick = () => {
        if (panelItem.url) {
            navigate(`/admin/${panelItem.url}/${panelItem.defaultTab}`);
        }
    };

    return (
        <div style={panelStyle} className={panelClass} data-test-id={DTICommonConst.NavigationPanel} onClick={handleClick}>
            <div style={innerDivStyle}>
                {panelItem.icon && (
                    <img src={isActive ? panelItem.icon.active : panelItem.icon.inactive} style={{ marginRight: Size.PADDING }} />
                )}
                <span style={spanStyle}>{panelItem.title}</span>
            </div>
        </div>
    );
};
