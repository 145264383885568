﻿import {BaseInputFieldComponent} from "./base-input-field.component";
import {FormFieldTypeEnum} from "@vivli/shared/infrastructure/enum";
import mergeRefs from "react-merge-refs";
import {MultiEntryTextFieldComponent} from "../multi-entry-text-field.component";
import {IFormField} from "@vivli/shared/infrastructure/interface";
import React, {CSSProperties, forwardRef, useRef} from 'react';

interface MultiEntryInputTextFieldComponentProps extends IFormField {
    title?: string;
    label?: string;
    onChange?(values: string[]);
    showClearAllButton?: boolean;
    clearButtonText?: string;
    textDivStyle? : CSSProperties
    value?: string[];
    defaultValue?: string[];
    readonly?: boolean;
    objectKey?: string,
}

export const MultiEntryInputTextFieldComponent =  forwardRef((props: MultiEntryInputTextFieldComponentProps, ref) => {
    const textRef = useRef<HTMLInputElement>();

    const {
        label,
        onChange,
        value,
        defaultValue,
        readonly,
    } = props;

    const handleOnChange = (values) => {
        onChange(values);
    }


    const buildInput = (props) => (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.TextArea}
            inputRef={textRef}
            ref={mergeRefs([textRef, ref])}
        >
            <MultiEntryTextFieldComponent
                label={label}
                useLabelFromParent={true}
                onChange={handleOnChange}
                value={value}
                showClearAllButton={false}
                clearButtonText={''}
                isReadOnly={readonly}
            />
        </BaseInputFieldComponent>
    )


    // prefer controlled value if it exists, then default
    const inputDisplay = (
        value !== undefined ? buildInput({value}) : buildInput({defaultValue})
    )

    const readonlyDisplay = (
        value !== undefined ? buildInput({value}) : buildInput({defaultValue})
    )

    return (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.TextArea}
            inputRef={textRef}
            label={label || ''}
        >
            <div ref={mergeRefs([textRef, ref])}>
                {!readonly ? inputDisplay : readonlyDisplay}
            </div>
        </BaseInputFieldComponent>
)
})
