﻿import React from "react";
import {Styles} from "@vivli/shared/theme";
import {FieldHeaderComponent, fieldHeaderStyle} from "@vivli/shared/components";

//Todo : come back in future and make this it's own component
const getInterventions = (arm) => {
    return (
        <ul>
            {arm.interventions.map((i, k) => (
                <li key={k}>
                    {i.pharmaDoseFrequencyDurationDescriptor}
                    {i.interventionRoute &&
                        <ul style={Styles.NON_BULLET_LIST_NO_TOP_PADDING}>
                            <li>{`Mode of Delivery: ${i.interventionRoute}`}</li>
                        </ul>
                    }
                    {i.isLastChild &&
                        <ul style={Styles.NON_BULLET_LIST}>
                            <li>OR</li>
                        </ul>
                    }
                </li>
            ))}
        </ul>
    )
}

export const PicoArmsComponent = ({arms}) => {
    return (
        <>
            <FieldHeaderComponent title={'ARMS'} style={fieldHeaderStyle} />
            <div>
                <table style={Styles.FULL_WIDTH_TABLE}>
                    <tbody>
                    <tr>
                        <th style={Styles.TABLE_HEADER}>{'Arm Name'}</th>
                        <th style={Styles.TABLE_HEADER}>{'Interventions'}</th>
                        <th style={Styles.TABLE_HEADER}>{'Number Enrolled'}</th>
                    </tr>
                    {arms.map((arm, i) => (
                        <tr key={i}>
                            <td>{arm?.armType ?? ''}</td>
                            <td>{getInterventions(arm)}</td>
                            <td>{arm?.actualEnrollmentByArm ?? ''}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
