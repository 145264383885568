export function useCopyToClipboardHook(){

    const copyClipBoard = (copyText) => {
        const textArea = document.createElement("textarea");
        textArea.value = copyText;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    const copyToClipboard = (copyText) => {
        return new Promise((resolve, reject) => {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(copyText).then(() => {
                    resolve();
                })
            }else{
                copyClipBoard(copyText);
                resolve();
            }
        });

    }

     return {
         copyToClipboard
    }
}
