import React, { CSSProperties } from 'react';
import { IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { ICellRendererParams } from 'ag-grid-community';
import { IUser } from '@vivli/shared/infrastructure/interface';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { DataRequestFormatService } from '@vivli/features/data-requests/infrastructure/service';
import { AssignedAppTypeEnum, TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { useTranslation } from 'react-i18next';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';

const titleStyle: CSSProperties = {
    fontSize: '12px',
    fontWeight: 600,
};

const idStyle: CSSProperties = {
    paddingRight: '15px',
    fontSize: '15px',
    fontWeight: 600,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-all',
    overflow: 'hidden',
    minHeight: '30px',
};

const detailsStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '5px',
    whiteSpace: 'nowrap',
};

const detailTitleStyle: CSSProperties = {
    fontSize: '13px',
    paddingRight: '4px',
    fontWeight: 'bold',
};

const containerStyle: CSSProperties = {
    height: 'calc(100% - 20px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: 1,
    marginTop: 10,
};

interface DataRequestTitleRendererComponentProps extends ICellRendererParams {
    data: IDataRequestSummary;
}

const canSeeCreator = (dataRequest: IDataRequestSummary, user: IUser) => {
    if (dataRequest.status !== DataRequestStatusEnum.Draft || user.isVivliAdmin || user.isOperationsAdmin) {
        return true;
    }

    // detect the data contributor case, who is not allowed to see creator when in draft
    const studyOrgIds = dataRequest.requestedStudies.map((x) => x.organizationId);
    const userOrgIds = user.orgMemberships.filter((x) => x.isDataProvider).map((x) => x.orgId);
    const dataProviderOrgIds = userOrgIds.filter((x) => studyOrgIds.some((s) => s === x));
    return dataProviderOrgIds.length === 0;
};

export const DataRequestTitleRendererComponent = ({ data }: DataRequestTitleRendererComponentProps) => {
    const leadResearcher = DataRequestFormatService.getLeadResearcher(data);
    const user = useActiveUser();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const { t } = useTranslation();
    const dataTestId = `dataRequestTitle_${data.requestTitle}`;

    return (
        <div style={containerStyle} data-test-id={dataTestId}>
            {data.dataRequestDoi && (
                <div style={idStyle}>
                    {`${t(TranslationKeyEnum.Vivli)} ID:`} {data.dataRequestDoi}{' '}
                </div>
            )}
            <div title={data.requestTitle} style={titleStyle}>
                {data.requestTitle} ({t(TranslationKeyEnum.studies)}: {data.requestedStudies?.length || 0})
            </div>
            {canSeeCreator(data, user) && (
                <div style={detailsStyle}>
                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 1 }}>
                        <span>
                            <span style={detailTitleStyle}>Requested By:</span>
                            {data.userName || 'Unknown'}
                        </span>
                        {!isAmr && (
                            <span>
                                <span style={detailTitleStyle}>Lead Researcher:</span>
                                {leadResearcher}
                            </span>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
