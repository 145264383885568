import React, { CSSProperties, useEffect, useState } from 'react';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { IOrganizationChannel } from '@vivli/features/chat/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { map } from 'rxjs/operators';
import {
    HintPopoverComponent,
    TabButtonComponent,
    TabButtonsComponent,
} from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';
import { ChannelDisplayFeature } from './channel-display.feature';
import { useToastService } from '@vivli/shared/infrastructure/context';
import { useCleanupHook, useRefState } from '@vivli/shared/infrastructure/hook';
import { ChatDescriptionComponent } from '@vivli/features/chat/components';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { ChatHintTextComponent } from '../../../components/src/lib/chat-hint-text.component';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

interface ChatRollupFeatureProps {
    channelId?: string;
    dataRequestId?: string;
}

const mapOrganizationChannelDisplayNames = (
    organizationChannels: IOrganizationChannel[]
) => {
    return organizationChannels.map((oc) => ({
        ...oc,
        displayName: oc.displayName || oc.organizationName,
        channelId: oc.channelId,
    }));
};

const hintIconStyle: CSSProperties = {
    width: 20,
    borderRadius: 7,
    overflow: 'hidden',
    marginTop: 5,
};

export const ChatRollupFeature = ({
    channelId,
    dataRequestId,
}: ChatRollupFeatureProps) => {
    const [organizationChannels, setOrganizationChannels] =
        useState<IOrganizationChannel[]>();
    const [activeChannelIdRef, activeChannelId, setActiveChannelId] =
        useRefState<string>();
    const [incomingMessageChannels, setIncomingMessageChannels] = useState<
        string[]
    >([]);
    const [activeChannelName, setActiveChannelName] = useState('Open Chat');
    const organizationsService = useOrganizationsService();
    const activeUser = useActiveUser();
    const toastService = useToastService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    useCleanupHook();

    useEffect(() => {
        setActiveChannelId(channelId);

        const orgChannelsSub = organizationsService
            .getOrganizationChannels(dataRequestId)
            .pipe(map(mapOrganizationChannelDisplayNames))
            .subscribe(setOrganizationChannels, () => {
                toastService.error(
                    'Unable to retrieve organization chat channels.'
                );
            });

        return () => {
            orgChannelsSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!organizationChannels || organizationChannels.length <= 0) {
            return;
        }

        const isIRP = activeUser.orgMemberships.some((om) => om.isIRPApprover);
        const isDPorOrgAdmin = activeUser.orgMemberships.some(
            (om) => om.isDataProvider || om.isOrgAdmin
        );

        if (isIRP && !isDPorOrgAdmin) {
            const orgChannel = organizationChannels.find(
                (oc) => oc.channelType === 'Organization'
            );
            if (orgChannel) {
                setActiveChannelId(orgChannel.channelId);
                setActiveChannelName(orgChannel.displayName);
            }
        }
    }, [organizationChannels]);

    useEffect(() => {
        if (!activeChannelIdRef.current) {
            return;
        }

        removeIncomingMessageChannel(activeChannelIdRef.current);
    }, [activeChannelId]);

    const removeIncomingMessageChannel = (incomingChannelId: string) => {
        setIncomingMessageChannels([
            ...incomingMessageChannels.filter((c) => c !== incomingChannelId),
        ]);
    };

    const addIncomingMessageChannel = (incomingChannelId: string) => {
        if (incomingChannelId === activeChannelIdRef.current) {
            return;
        }

        setIncomingMessageChannels([
            ...incomingMessageChannels,
            incomingChannelId,
        ]);
    };

    const tabDisplay = (displayName: string, tabChannelId: string) => {
        const hasIncomingMessage = incomingMessageChannels.some(
            (c) => c === tabChannelId
        );
        return (
            <TabButtonComponent
                key={tabChannelId}
                title={displayName}
                onClick={() => {
                    setActiveChannelId(tabChannelId);
                    setActiveChannelName(displayName);
                }}
                style={{
                    animation: hasIncomingMessage
                        ? 'example 1s infinite'
                        : null,
                    color: hasIncomingMessage ? 'white' : 'rgb(23, 150, 197)',
                }}
                isSelected={tabChannelId === activeChannelId}
            />
        );
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ marginLeft: 40, width: '100%', height: '100%' }}>
                <TabButtonsComponent>
                    {tabDisplay('Open Chat', channelId)}
                    {organizationChannels?.map((channel, i) =>
                        tabDisplay(channel.displayName, channel.channelId)
                    )}
                    <HintPopoverComponent
                        position={'top' as any}
                        hintText={<ChatHintTextComponent isAmr={isAmr} />}
                        maxWidth={Size.POPOVER_MAX_WIDTH}
                        popoverColor={Color.TEXT_LIGHT_BLUE}
                        popoverArrowColor={Color.TEXT_LIGHT_BLUE}
                        icon={AssetsConstant.INFO_ICON}
                        iconStyle={hintIconStyle}
                    />
                </TabButtonsComponent>
                <ChatDescriptionComponent
                    activeChannelName={activeChannelName}
                    activeChannelId={channelId}
                />
                <div style={{ position: 'relative', width: '100%' }}>
                    <ChannelDisplayFeature
                        channelId={channelId}
                        isActive={channelId === activeChannelId}
                        onMessageReceived={() =>
                            addIncomingMessageChannel(channelId)
                        }
                    />
                    {organizationChannels?.map((channel, i) => (
                        <ChannelDisplayFeature
                            key={i}
                            channelId={channel.channelId}
                            isActive={channel.channelId === activeChannelId}
                            onMessageReceived={() =>
                                addIncomingMessageChannel(channel.channelId)
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
