import React, {CSSProperties} from 'react';
import {Color, Size} from "@vivli/shared/theme";

interface IAdvancedSubHeaderComponent {
    style?: CSSProperties;
    title: string;
}

export const AdvancedSubHeaderComponent = ({style, title}: IAdvancedSubHeaderComponent) => {
    return <div
        style={{
            color: Color.DARK_GRAY,
            fontSize: 12,
            marginBottom: Size.HALF_PADDING,
            ...style
        }}
    >
        {title}
    </div>
}
