import { IAzureSearchApiService, IAzureSearchOptions } from '@vivli/features/search/infrastructure/interface';
import { AzureSearchApiService } from './azure-search-api.service';

export class AzureSearchBaseService {
    protected _searchClient: IAzureSearchApiService<any>;
    private _apiVersion = '2016-09-01';
    protected _defaultOptions: IAzureSearchOptions = {
        assignedAppType: null,
        searchText: '*',
        skip: 0,
        top: 10,
        filterSets: null,
        select: null,
        filter: null,
        orderBy: null,
    };

    constructor(apiKey: string, endpoint: string, indexName: string, public quoteSearchTerms = true) {
        const params = new URLSearchParams();
        params.append('api-key', apiKey);
        params.append('api-version', this._apiVersion);

        this._searchClient = new AzureSearchApiService({
            baseUrl: `${endpoint}/indexes/${indexName}/docs`,
            params,
        });
    }
}
